/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Popover, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '../../../StyledComponents/AccordianStyles/AccordionStyles'
import info from '../../../assets/images/info.svg'
import PreviewDocumentPdf from './PreviewDocumentPdf/PreviewDocumentPdf'
import SignatureSelectionReviewTable from './components/SignatureSelectionReviewTable'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetDocumentPreviewAndReviewUsersByIdQuery,
  useSubmitSignatureRequestMutation
} from '../../../store/services/signatureService'
import './ESignaturePage.css'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import { SIGNATUREPLACEMENTNOTES, SIGNATUREPLACEMENTNOTES2 } from '../../../constants/enum'
import CustomTooltip from '../../../components/Tooltip/CustomTooltip'
import { InfoOutlined } from '@mui/icons-material'
import palette from '../../../theme/palette'
import AlertModal from '../../../components/AlertModal/AlertModal'
import { ALERT_MESSAGE } from '../../../constants/message'

const ESignaturePage = () => {
  const signatureBoxStyle = {
    height: 80,
    width: 125
  }

  const { documentId } = useParams()

  const navigate = useNavigate()
  // Alert modal state
  const [openAlertModal, setOpenAlertModal] = useState(false)

  // Alert Modal description
  const alertModalState = {
    message: ALERT_MESSAGE.THANKYOUMESSAGE,
    cancelButtonText: '',
    submitButtonText: 'Continue'
  }
  // Accordian expanded state
  const [expanded, setExpanded] = useState(0)

  // Popover notes state
  const [anchorEl, setAnchorEl] = useState()

  // Popover  states
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const signaturePlacementPopRef = useRef()

  // Document container is clickable or not
  const [isDocumentContainerClickable, setisDocumentContainerClickable] = useState(false)

  // Selected  Review user at time of action clicked
  const [selectedParty, setSelectedParty] = useState({})

  const [signatureAddedList, setSignatureAddedList] = useState([])

  const [signatureBoxsList, setSignatureBoxsList] = useState([])

  const [backdropState, setBackdropState] = useState(false)

  // Detail of signator tooltip
  const [openDOSTooltip, setDOSTooltip] = useState(false)

  const handleDosTooltipOpen = () => setDOSTooltip(true)
  const handleDosTooltipClose = () => setDOSTooltip(false)

  // Get document Preview and Review section data
  const {
    data: documentDataResponse = {
      review: [],
      transactions: []
    }
  } = useGetDocumentPreviewAndReviewUsersByIdQuery(documentId, {
    skip: !documentId
  })

  const [submitSignatureRequestBackend] = useSubmitSignatureRequestMutation({})

  useEffect(() => {
    setAnchorEl(signaturePlacementPopRef.current)
  }, [])

  const handleClick = () => {
    setAnchorEl(signaturePlacementPopRef.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleSelectionActionButton = (reviewUser) => {
    console.log(reviewUser)
    setSelectedParty(reviewUser)
    setisDocumentContainerClickable(true)
    console.log(selectedParty)
    const objDiv = document.getElementById('pdfBox')
    objDiv.scroll({ top: objDiv.scrollHeight, behavior: 'smooth' })
    // objDiv.scrollTop = objDiv.scrollHeight
  }

  /**
     * Handle pdf click  to add signature box
     * @param {object} e: Event object
     * @param {pageIndex} page: Page Number
     */
  const handlePdfClick = (e, page, totalPdfPage) => {
    // Get the coordinates relative to the PDF container
    console.log('hi')

    if (Object.keys(selectedParty).length) {
      console.log(selectedParty)
      const containerRect = e.currentTarget.getBoundingClientRect()

      const x = e.clientX - containerRect.left
      const y = e.clientY - containerRect.top

      // Adjust y to start from the bottom and increase towards the top (standard web coordinates)
      // const yWebCoordinates = e.clientY - containerRect.top

      // However, for the signing library, y should start from the bottom and increase towards the top
      const ySigningLibraryCoordinates = containerRect.height - y

      const adjustedX = Math.min(
        Math.max(0, x),
        containerRect.width - signatureBoxStyle?.width
      )
      const adjustedY = Math.min(
        Math.max(0, y),
        containerRect.height - signatureBoxStyle?.height
      )

      const pdfWidth = 594 // Total width of the PDF
      const userSelectedX = adjustedX // User-selected x-coordinate
      const boxWidth = signatureBoxStyle?.width // Width of the signature box
      // Invert x-coordinate
      let invertedX = pdfWidth - userSelectedX
      if (invertedX - boxWidth < 0) {
        invertedX = 0 // Set to left boundary
      } else if (invertedX + boxWidth > pdfWidth) {
        invertedX = pdfWidth - boxWidth // Set to right boundary
      }
      const finalInvertedX = invertedX

      console.log(
        'y signing library',
        ySigningLibraryCoordinates,
        containerRect.height,
        y,
        adjustedY
      )
      const ysign = Math.min(
        Math.max(0, ySigningLibraryCoordinates),
        containerRect.height - signatureBoxStyle?.height
      )

      // const { pageX, pageY } = e;

      // console.log(page)
      // const pdfContainer = document.getElementById('pdf-container');
      // const containerRect = pdfContainer.getBoundingClientRect();
      // const x = pageX - containerRect.left;
      // const y = pageY - containerRect.top;

      // const xCenterPos = adjustedX + signatureBoxStyle?.width / 2

      // const yCenterPos = adjustedY + signatureBoxStyle?.height / 2

      const selectedSignature = {
        name: selectedParty?.party,
        userId: selectedParty?.userId,
        pageNumber: page,
        totalPages: totalPdfPage,
        xPos: adjustedX,
        yPos: y,
        width: signatureBoxStyle?.width,
        height: signatureBoxStyle?.height,
        xCenterPos: adjustedX,
        yCenterPos: ySigningLibraryCoordinates
      }
      console.log(selectedSignature)
      setisDocumentContainerClickable(false)
      setSignatureBoxsList([...signatureBoxsList, { ...selectedSignature }])
      setSignatureAddedList([...signatureAddedList, selectedSignature?.userId])
      setSelectedParty({})
    }
    // Log the coordinates
    console.log('PDF Coordinates (x, y):', signatureBoxsList, signatureAddedList)
  }

  /**
     * Handle delete the signature from the pdf and signature box
     * @param {object} selectedParty: Selected user
     */
  const handleRemoveUserSignature = (selectedParty) => {
    setSignatureBoxsList(
      signatureBoxsList.filter((item) => item?.userId !== selectedParty?.userId)
    )
    setSignatureAddedList(signatureAddedList.filter((item) => item !== selectedParty?.userId))
  }

  // Handle Submit signature Request
  const submitSignatureRequest = () => {
    setBackdropState(true)
    const dataToSend = {
      docId: documentId,
      positions: signatureBoxsList
    }

    submitSignatureRequestBackend({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        console.log('reponse', response)
        setBackdropState(false)
        setOpenAlertModal(true)

        // console.log('payment Status', paymentStatus)
      })
      .catch((execption) => {
        setBackdropState(false)

        console.log('Signature failed:Backend Failed', execption)
      })
  }

  const handleSubmitAlertModal = () => {
    setOpenAlertModal(false)
    navigate('/myDocument', {
      state: {
        HeaderTitle: 'My Document'
      }
    })
  }

  return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={3}>
                <Grid item lg={6} md={6}>
                    <Box sx={{ marginTop: '20px' }} mb="20px">
                        <Accordion expanded={expanded === 0} onChange={handleChange(0)}>
                            <div className="toggle">
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header">
                                    <Typography variant="darkValue">
                                        Details of Signatories{' '}
                                        <CustomTooltip
                                            message={SIGNATUREPLACEMENTNOTES2}
                                            openTooltip={openDOSTooltip}
                                            setOpenTooltip={setDOSTooltip}
                                            handleTooltipOpen={handleDosTooltipOpen}
                                            handleTooltipClose={handleDosTooltipClose}>
                                            <InfoOutlined
                                                sx={{
                                                  fontSize: 18,
                                                  color: palette.text.main,
                                                  cursor: 'pointer',
                                                  marginLeft: '5px'
                                                }}
                                            />
                                        </CustomTooltip>
                                    </Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <div className="reviewTable-box">
                                    <SignatureSelectionReviewTable
                                        selectedParty={selectedParty}
                                        isDocumentContainerClickable={isDocumentContainerClickable}
                                        tableData={documentDataResponse?.transactions}
                                        handleSelectionActionButton={handleSelectionActionButton}
                                        handleRemoveUserSignature={handleRemoveUserSignature}
                                        signatureAddedList={signatureAddedList}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Grid>
                <Grid lg={6} item>
                    {/* <div className="details_rightgrid-head">
                        <img
                            src={info}
                            alt="info"
                            style={{ marginRight: '15px' }}
                            id={id}
                            onClick={handleClick}
                            ref={signaturePlacementPopRef}
                        />
                        <Typography variant="darkValueLarge">Important notes</Typography>
                    </div> */}
                    {/* <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        // onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}>
                        <Box className="popover_box">
                            <div className="popover_box-head">
                                <Typography
                                    sx={{ color: '#003A51', fontSize: '16px', fontWeight: '600' }}>
                                    Important Notes
                                </Typography>
                            </div>
                            {SIGNATUREPLACEMENTNOTES &&
                                SIGNATUREPLACEMENTNOTES?.map((item, indexImpNotes) => (
                                    <div className="popover_box-details1" key={indexImpNotes}>
                                        <Typography
                                            variant="modalLabelSmall"
                                            sx={{
                                              color: '#555555',
                                              letterSpacing: '0.03em',
                                              lineHeight: '30px'
                                            }}>
                                            {item}
                                        </Typography>
                                    </div>
                                ))}
                            <Button variant="primary" onClick={handleClose}>
                                Ok
                            </Button>
                        </Box>
                    </Popover> */}
                    <Box
                        sx={{ marginTop: '20px' }}
                        mb="20px"
                        // className={isDocumentContainerClickable ? '' : 'previewEsign'}
                    >
                        <Grid
                            className="details_rightgrid-box"
                            id="pdfBox"
                            sx={{ maxHeight: 'calc(100vh - 18rem)' }}>
                            <PreviewDocumentPdf
                                file={documentDataResponse?.unsignedFile?.fileUri}
                                isDocumentContainerClickable={isDocumentContainerClickable}
                                handlePdfClick={handlePdfClick}
                                signatureBoxsList={signatureBoxsList}
                            />
                        </Grid>
                    </Box>
                </Grid>
                <Grid>
                    <Button
                        variant="primary"
                        onClick={submitSignatureRequest}
                        disabled={
                            signatureBoxsList.length < documentDataResponse?.transactions.length
                        }>
                        Submit Selection
                    </Button>
                </Grid>
            </Grid>
            {/* Alert confirmation modal */}
            {openAlertModal && (
                <AlertModal
                    openAlertModal={openAlertModal}
                    handleAlertModalClose={() => handleSubmitAlertModal()}
                    handleSubmitAlertModal={() => handleSubmitAlertModal()}
                    alertModalState={alertModalState}
                />
            )}
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default ESignaturePage
