import React, { useState } from 'react'
import verified from '../../assets/images/tickgreen.svg'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import palette from '../../theme/palette'
import { useSendDigiAuthCodeMutation } from '../../store/services/commonServices'
import BackdropLoader from '../../components/BackdropLoader/BackdropLoader'
import { ALERT_MESSAGE } from '../../constants/message'
import { toast } from 'react-toastify'
import { useUploadDocumentToDigiLockerMutation } from '../../store/services/documentServices'

const KycAcknowledgement = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [backdropState, setBackdropState] = useState(false)

  const [sendCode] = useSendDigiAuthCodeMutation({})

  // Upload document to Digi Locker
  const [uploadDocumentDigiLocker] = useUploadDocumentToDigiLockerMutation({})
  // Access URL search parameters (query string)
  const searchParams = new URLSearchParams(location.search)

  // Access specific query parameters
  const paramValue = searchParams.get('code')
  const state = searchParams.get('state')
  const error = searchParams.get('error')
  const errorDescription = searchParams.get('error_description')
  console.log(paramValue)
  console.log(state)
  const message = `${ALERT_MESSAGE?.DIGILOCKERACKFORCONSENT}. ${state !== 'kycVerification' ? ALERT_MESSAGE?.CHECKDOCUMENTSYNCINGSTATUS : ALERT_MESSAGE?.CHECKKYCSTATUS}`
  const handleClick = () => {
    setBackdropState(true)
    const dataToSend = {
      code: paramValue
    }
    console.log('Payload====>', dataToSend)

    if (error === null || error === undefined) {
      if (state === 'kycVerification') {
        sendCode({ payload: dataToSend })
          .unwrap()
          .then((response) => {
            toast.success(response?.message)
            setBackdropState(false)
            navigate('/home')
          })
          .catch((error) => {
            setBackdropState(false)
            navigate('/home')

            console.log(error)
          })
      } else {
        const documentId = state.split('-')[1]
        const dataToSend = {
          authCode: paramValue,
          documentId
        }
        uploadDocumentDigiLocker({ payload: dataToSend })
          .unwrap()
          .then((response) => {
            setBackdropState(false)
            // if (response?.data?.isNeedToGenerateNewToken) {
            // }
            toast.success(response?.data?.messageToShow)
            navigate('/myDocument', {
              state: {
                HeaderTitle: 'My Documents'
              }
            })
          })
          .catch((exception) => {
            setBackdropState(false)
          })
      }
    } else {
      navigate('/home')
    }
  }

  return (
        <Grid
            container
            className="landingBody"
            justifyContent="center"
            alignItems={'center'}
            sx={{
              background: palette.gradient.main
            }}>
            <Box p={1} mt={8} sx={{ background: 'white', padding: 8, borderRadius: 8 }}>
                <Grid display="flex" flexDirection={'column'} alignItems={'center'}>
                    {error === null || error === undefined ? (
                        <img
                            src={verified}
                            alt="done"
                            height="60px"
                            width="60px"
                            style={{ marginBottom: '10px' }}
                        />
                    ) : (
                        <></>
                    )}
                    <></>
                    <Typography
                        variant="labelLarge"
                        component="div"
                        sx={{ fontSize: '60px', marginBottom: '20px' }}>
                        {error !== null
                          ? ALERT_MESSAGE.DIGILOCKERAUTHORIZATIONFAILED
                          : ALERT_MESSAGE.DIGILOCKERAUTHORIZATIONSUCCESSFULL}
                    </Typography>
                </Grid>
                <Grid display="flex" flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="darkValue" sx={{ fontSize: '15px', lineHeight: '31px' }}>
                        {error !== null ? errorDescription : message}
                    </Typography>
                    <Button
                        variant="primary"
                        onClick={() => {
                          handleClick()
                        }}
                        sx={{ marginTop: 5 }}>
                        Go To Dashboard
                    </Button>
                </Grid>
                {backdropState && <BackdropLoader backdropState={backdropState} />}
            </Box>
        </Grid>
  )
}

export default KycAcknowledgement
