export const PLACEHOLDER = {
  FULLNAME: 'Enter name',
  EMAIL: 'Enter E-mail address',
  PHONE_NUMBER: 'Phone number',
  ADDRESS: 'Enter Address',
  PASSWORD: 'Enter Password',
  CONFIRM_PASSWORD: 'Confirm password',
  SEARCHDOCUMENT: 'Search by document id,article type ',
  SEARCHTICKET: 'Search by ticket number',
  SEARCHMANAGESTAFFUSERS: 'Search by name, email, phone',
  SEARCHWALLETANDTRANSACTION: 'Search by transaction id',
  SEARCHMYDOCUMENTINDIVIDUALTRANSACTION: 'Search by document id, article type',
  PANPICKEDNAME: 'Name will be picked from Aadhaar',
  AADHAARPICKEDNAME: 'Name will be picked from Aadhaar'
}
