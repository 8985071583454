import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Box,
  Popover,
  Button
} from '@mui/material'
import React, { useContext, useState } from 'react'

import { PLACEHOLDER } from '../../../constants/placeholder'
import {
  useGetAllTicketFilterStatusQuery,
  useGetAllTicketsQuery
} from '../../../store/services/helpServices'
import calendericon from '../../../assets/images/calendericon.svg'
import TicketsTable from './components/TicketsTable/TicketsTable'
import './Tickets.css'
import search from '../../../assets/images/searchicon.svg'
import DateRangeComponent from '../../../components/DateRange/DateRange'
import { ProfileContext } from '../../../contextapi/ProfileContext'
import { PAGINATIONINITIALS, ROLES } from '../../../constants/enum'
import moment from 'moment'
import { useGetFilterLocationQuery } from '../../../store/services/commonServices'
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles'
import { ClearIcon } from '@mui/x-date-pickers'

const Tickets = () => {
  const filterObj = {
    status: '',
    search: '',
    location: '',
    dateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        skip: true
      }
    ]
  }

  const intialPaginationFilterState = {
    page: PAGINATIONINITIALS?.PAGE,
    rowsPerPage: PAGINATIONINITIALS?.ROWSPERPAGE
  }

  // Profile context api
  const { profile } = useContext(ProfileContext)

  // Filter state
  const [filter, setFilter] = useState(filterObj)

  // Pagination Filter
  const [paginationFilter, setPaginationFilter] = useState(intialPaginationFilterState)

  // Popover state
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFormChange = (event) => {
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    resetPage()
  }

  // Get all ticket filter status query
  const { data: ticketStatusArray = [] } = useGetAllTicketFilterStatusQuery({})

  console.log(profile?.role)
  // Get staff user Location filter query
  const { data: locationFilterList = [] } = useGetFilterLocationQuery(profile?.role, {
    skip: profile?.role !== ROLES?.CORPORATE
  })

  // Get all Tickets according to the user role
  const { data: { list = [], totalCount = 0 } = {}, isLoading } = useGetAllTicketsQuery({
    page: paginationFilter?.page,
    size: paginationFilter?.rowsPerPage,
    status: filter?.status,
    search: filter?.search,
    location: filter?.location,
    fromDate: !filter?.dateRange[0]?.skip
      ? moment(filter?.dateRange[0]?.startDate).format('YYYY-MM-DD')
      : '',
    toDate: !filter?.dateRange[0]?.skip
      ? moment(filter?.dateRange[0]?.endDate).format('YYYY-MM-DD')
      : ''
  })

  /**
     * Handle filter date range change
     * @param {array} item: date range array
     */
  const dateRangeHandler = (item) => {
    const dateRangeValue = [
      {
        startDate: item[0]?.startDate,
        endDate: item[0]?.endDate,
        key: 'selection',
        skip: false
      }
    ]
    setFilter((prev) => ({
      ...prev,
      dateRange: dateRangeValue
    }))
    resetPage()
  }

  /**
     *  Handle Clear Date Range
     *
     */
  const handleClear = () => {
    const dateRangeValue = [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        skip: true
      }
    ]
    setFilter((prev) => ({
      ...prev,
      dateRange: dateRangeValue
    }))
    resetPage()
    handleClose() // Close the Popover after clearing
  }

  // Reset page to the initial page [initial Page=0]
  const resetPage = () => {
    setPaginationFilter((prev) => ({
      ...prev,
      page: PAGINATIONINITIALS?.PAGE
    }))
  }

  const handleFilterClear = () => {
    // clear all filters
    setFilter(filterObj)
  }

  return (
        <Box>
            <Grid container marginBottom={3} spacing={3} justifyContent="left" alignItems="center">
                <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Select
                        name="status"
                        value={filter?.status}
                        onChange={handleFormChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth>
                        <MenuItem value="" disabled>
                            <Typography>Select status</Typography>
                        </MenuItem>
                        {ticketStatusArray.length > 0 &&
                            ticketStatusArray.map((filter, index) => (
                                <MenuItem key={index} value={filter?.name}>
                                    {filter?.value}
                                </MenuItem>
                            ))}
                    </Select>
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                    <div className="date-range-input-container">
                        <TextField
                            aria-describedby={id}
                            onClick={handleClick}
                            type="text"
                            value={
                                !filter?.dateRange[0].skip
                                  ? `${filter?.dateRange[0].startDate.toLocaleDateString()} - ${filter?.dateRange[0].endDate.toLocaleDateString()}`
                                  : 'Date Range'
                            }
                            readOnly
                            fullWidth
                        />
                        <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                            className="calendar-icon">
                            {!filter?.dateRange[0].skip && (
                                <IconButton sx={{ marginRight: '30px' }} onClick={handleClear}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                            <img
                                src={calendericon}
                                alt="calender"
                                className="calendar-icon"
                                onClick={handleClick}
                            />
                        </div>{' '}
                    </div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}>
                        <DateRangeComponent
                            maxDate={new Date()}
                            dateRange={filter?.dateRange}
                            setDateRange={dateRangeHandler}
                        />
                    </Popover>
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12} display="flex" alignItems="center">
                    <TextField
                        name="search"
                        id="search"
                        placeholder={PLACEHOLDER.SEARCHTICKET}
                        onChange={handleFormChange}
                        InputLabelProps={{
                          shrink: false
                        }}
                        InputProps={{
                          style: {
                            borderRadius: '5px'
                          },
                          startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton aria-label="toggle password visibility">
                                        <img src={search} alt="search" />
                                    </IconButton>
                                </InputAdornment>
                          )
                        }}
                        value={filter?.search}
                        fullWidth
                    />
                </Grid>
                {profile?.role === ROLES?.CORPORATE && (
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Select
                            name="location"
                            value={filter?.location}
                            onChange={handleFormChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            fullWidth
                            MenuProps={MenuProps}>
                            <MenuItem value="">
                                <Typography>All Locations</Typography>
                            </MenuItem>
                            {locationFilterList?.length > 0 &&
                                locationFilterList?.map((item) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={item?.id}
                                        value={item?.id}>
                                        {item?.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                )}
                <Grid item lg={4} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                    <Button variant="primary" onClick={handleFilterClear}>
                        Clear
                    </Button>
                </Grid>
            </Grid>
            <TicketsTable
                ticketTableData={list}
                isLoading={isLoading}
                paginationFilter={paginationFilter}
                setPaginationFilter={setPaginationFilter}
                totalCount={totalCount}
            />
        </Box>
  )
}

export default Tickets
