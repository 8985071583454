// import { ROLES } from '../constants/enum'

import moment from 'moment'

/**
 * Get Details from localstorage
 * @param {string} key
 * @returns  Value of the key present in localstorage
 */
export const getLocal = (key) => {
  return localStorage.getItem(key)
}

/**
 * Set details in localstorage
 * @param {string} key
 * @param {string} value
 */
export const setLocal = (key, value) => {
  localStorage.setItem(key, value)
}

/**
 * Remove details in localstorage
 * @param {string} key
 */
export const removeLocal = (key) => {
  localStorage.removeItem(key)
}

/**
 * Get Details from SessionStorage
 * @param {string} key
 * @returns  Value of the key present in SessionStorage
 */
export const getSession = (key) => {
  return sessionStorage.getItem(key)
}

/**
 * Set details in SessionStorage
 * @param {string} key
 * @param {string} value
 */
export const setSession = (key, value) => {
  sessionStorage.setItem(key, value)
}

/**
 * Remove details in SessionStorage
 * @param {string} key
 */
export const removeSession = (key) => {
  sessionStorage.removeItem(key)
}

/**
 * Remove details in SessionStorage
 * @param {string} key
 */
export const clearSession = (key) => {
  sessionStorage.clear()
}

/**
 * Get random string of  desired length
 * @param {number} len
 * @returns  Random String of len
 */
export const randomstringCaptcha = (len) => {
  const alphabets = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz'
  let randomstring = ''

  // Loop to select a new character in each iteration
  for (let i = 0; i < len; i++) {
    const rnum = Math.floor(Math.random() * alphabets.length)
    randomstring += alphabets.substring(rnum, rnum + 1)
  }
  return randomstring
}

// export const isRestricted = (role) => {
//   if (role === ROLES?.INDIVIDUAL) {
//   } else if (role === ROLES?.CORPORATE) {
//   } else {
//   }
// }

export const formatDate = (timestamp) => {
  return moment(timestamp).format('DD-MM-YYYY, hh:mm a')
}

export function roundFloor (value) {
  if (value !== null && value !== undefined) {
    const factor = Math.pow(10, 2) // factor of 100 for 2 decimal places
    value = value * factor
    // Use Math.floor to always round down
    const tmp = Math.floor(value)
    return tmp / factor
  }
}

export const createDateString = (inputDate) => {
  const date = moment(inputDate, 'DD-MM-YYYY')
  const day = moment(date).format('D')

  const dayWithOrdinal =
        day +
        (day.endsWith('1') && !day.endsWith('11')
          ? 'st'
          : day.endsWith('2') && !day.endsWith('12')
            ? 'nd'
            : day.endsWith('3') && !day.endsWith('13')
              ? 'rd'
              : 'th')
  const formattedDate = `${dayWithOrdinal} day of ${moment(date).format('MMMM')}, ${moment(date).format('YYYY')}`
  return formattedDate
}
