import React from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import palette from '../../../../theme/palette'
import { useGetCodeChallengeDocumentUploadQuery } from '../../../../store/services/commonServices'
import { MESSAGE_CONFIRMATION } from '../../../../constants/message'
import cross from '../../../../assets/images/cross.svg'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark}, 0.06)`,
  borderRadius: '10px',
  p: 4
}

const DigilockerDocumentUpload = ({ openPanModal, handlePanModalClose }) => {
  const codeChallenge = useGetCodeChallengeDocumentUploadQuery({})

  console.log('Code Challenge======>', codeChallenge.data)
  return (
        <Modal
            open={openPanModal?.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid display="flex" justifyContent="flex-end">
                    <span onClick={handlePanModalClose} style={{ cursor: 'pointer' }}>
                        <img src={cross} alt="cross" height="21px" width="21px" />
                    </span>
                </Grid>
                <form
                    id="URL"
                    name="URL"
                    method="GET"
                    action="https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize">
                    <div style={{ marginTop: '20px' }}>
                        <Typography variant="darkValueLarge">
                            {MESSAGE_CONFIRMATION?.CONFIRMDIGILOCKERDRIVEPERMISSION}
                        </Typography>

                        <Button
                            variant="cancelButton"
                            sx={{ width: '120px', marginTop: '30px', marginRight: '10px' }}
                            onClick={handlePanModalClose}>
                            No
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            sx={{ width: '120px', marginTop: '30px' }}>
                            Okay
                        </Button>

                        <input type="hidden" name="response_type" value={'code'} />
                        <input
                            type="hidden"
                            name="client_id"
                            value={process.env.REACT_APP_DIGILOCKER_UAT_CLIENT_ID}
                        />
                        <input
                            type="hidden"
                            name="redirect_uri"
                            value={process.env.REACT_APP_REDIRECT_URL_UAT}
                        />
                        <input
                            type="hidden"
                            name="state"
                            value={`documentUpload- ${openPanModal?.documentId}`}
                        />
                        <input type="hidden" name="code_challenge" value={codeChallenge.data} />
                        <input type="hidden" name="code_challenge_method" value="S256" />
                        {/* <input type="hidden" name="req_doctype" value="PANCR,ADHAR" /> */}
                        <input type="hidden" name="acr" value="aadhaar" />
                    </div>
                </form>
            </Box>
        </Modal>
  )
}

export default DigilockerDocumentUpload

DigilockerDocumentUpload.propTypes = {
  openPanModal: PropTypes.bool,
  handlePanModalClose: PropTypes.func
}
