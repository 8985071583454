import React, { useState, useEffect, useRef, useContext } from 'react'
import {
  Button,
  Grid,
  Popover,
  Typography,
  Box,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { validator } from '../../../../util/validator'
import './DocumentDetails.css'
import info from '../../../../assets/images/info.svg'
import {
  CLVALIDATIONS,
  IMPORTANTNOTES,
  PARTYTYPES,
  PATTERN,
  ROLES
} from '../../../../constants/enum'
// import NormalTextField from '../../../../components/InputFields/NormalTextField'
// import { Stack } from '@mui/system'
// import DateTextField from '../../../../components/InputFields/DateTextField'
// import SelectDropdown from '../../../../components/InputFields/SelectDropdown'
// import SelectTextField from '../../../../components/InputFields/SelectTextField'
// import RadiowithTextfield from '../../../../components/InputFields/RadiowithTextfiled'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '../../../../StyledComponents/AccordianStyles/AccordionStyles'
import MenuProps from '../../../../StyledComponents/SelectStyles/SelectStyles'
import {
  useGetDocumentFieldsByIdQuery,
  useGetReviewPartyPriorityQuery,
  useSendDocumentForReviewMutation
} from '../../../../store/services/documentServices'
import flag from '../../../../assets/images/india-flag.svg'
import PartySignTable from '../components/PartySignTable/PartySignTable'
import { PLACEHOLDER } from '../../../../constants/placeholder'
import errormessages from '../../../../constants/errormessages'
import { toast } from 'react-toastify'
import PreviewDocument from '../PreviewDocument/PreviewDocument'
import SuccessModal from '../../CustomFlow/components/SuccessModal/SuccessModal'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
import { useGetuploadDocumentPreviewByIdQuery } from '../../../../store/services/uploadDocumentServices'
import palette from '../../../../theme/palette'
import {
  DOCUEMENTFILTERSTATUS,
  PARTYTYPEOPTIONS,
  SIGNATUREMODES
} from '../../../../constants/documentConstans'
import {
  BUSINESSCONFIGURATION,
  CORPORATECONFIGURATION,
  INDIVIDUALCONFIGURATION
} from '../../../../constants/configurationConstant'
import { ProfileContext } from '../../../../contextapi/ProfileContext'
import CommonMessageButtonModal from '../../../../components/commonMessageButtonModal/CommonMessageButtonModal'
import {
  ALERT_MESSAGE,
  MESSAGE_CONFIRMATION,
  PREFFEREDSIGNMODEMESSAGE,
  PRIORITYMESSAGE,
  TOAST_MESSAGE
} from '../../../../constants/message'
import CustomTooltip from '../../../../components/Tooltip/CustomTooltip'
import AlertModal from '../../../../components/AlertModal/AlertModal'

const DocumentDetails = (props) => {
  const navigate = useNavigate()
  const { documentId } = useParams()
  const tooltipInitialState = {
    priorityTooltip: false,
    signTooltip: false,
    prefferedSignTooltip: false
  }

  // state for accordian expand
  const [expanded, setExpanded] = useState(1)

  // Signature option
  const signatureptions = [SIGNATUREMODES?.AADHAAR, SIGNATUREMODES?.DSC]

  // Profile data from context api
  const { profile } = useContext(ProfileContext)

  // const [keys, setkeys] = useState('')
  const [anchorEl, setAnchorEl] = useState()

  const [stampDutyInformation, setStampDutyInformation] = useState({
    stampDutyPaid: '',
    stampDutyPurchased: ''
  })

  // Button Disable State for proceed to consent form
  const [isProceedConsentButtonDisabled, setIsProceedConsentButtonDisabled] = useState(true)

  const [backdropState, setBackdropState] = useState(false)

  // Handle Digital signature selection notes modal open
  const [digitalSignNotesModalState, setDigitalSignNotesModalState] = useState({
    open: false,
    title: ALERT_MESSAGE?.DIGITALSIGNERRORMODALTITLE,
    message: MESSAGE_CONFIRMATION?.DIGITALSIGNCONFIRMMESSAGE,
    cancelButtonText: 'Cancel',
    submitButtonText: 'Ok'
  })

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const spanRef = useRef()

  const importantNotes = IMPORTANTNOTES

  // Document configuration for corporate and business staff users
  const documentConfiguration =
        profile?.role === ROLES?.CORPORATE
          ? CORPORATECONFIGURATION[`${profile?.subRole}`]
          : profile?.role === ROLES?.INDIVIDUAL
            ? INDIVIDUALCONFIGURATION[`${profile?.subRole}`]
            : BUSINESSCONFIGURATION[`${profile?.subRole}`]

  //   const documentDataResponse = []
  // Get document sections and fields
  const { data: documentDataResponse = {}, isSuccess } = useGetDocumentFieldsByIdQuery(
    documentId,
    {
      skip: !documentId
    }
  )

  const { data: documentPreview = {} } = useGetuploadDocumentPreviewByIdQuery(documentId, {
    skip: !documentId
  })

  // const priority = [0, 1]
  const { data: { types: partyTypeArray = [], priority: priorityList = [] } = {} } =
        useGetReviewPartyPriorityQuery({ documentId })

  // Send document for review
  const [sendDocumentForReview] = useSendDocumentForReviewMutation({})

  // Save Document Preview as a draft
  // const [saveDocumentField] = useSaveDocumentMutation()

  // Review form state
  const [reviewForm, setReviewForm] = useState({
    partyType: '',
    priority: '',
    eSign: true,
    signMode: SIGNATUREMODES?.AADHAAR?.id,
    errors: {
      email: 'error'
    }
  })

  const [selectedPriority, setSelectedPriority] = useState([])
  const [editModePriority, setEditModePriority] = useState(null)

  // const [documentForm, setDocumentForm] = useState({
  //   form: {},
  //   errors: {}
  // })

  // All added reviewer State array
  const [documentReviewer, setDocumentReviewer] = useState([])

  // Success Modal state
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  // delete Alert modal state
  const [openDeleteAlertModal, setOpenDeleteAlertModal] = useState(false)

  // Delete  Alert Modal description
  const deleteAlertModalState = {
    title: 'Caution',
    message: ALERT_MESSAGE.DELETEREVIEW,
    cancelButtonText: 'No',
    submitButtonText: 'Yes'
  }

  const [currentSelectedReview, setCurrentSelectedReview] = useState({})

  const [tooltipState, setTooltipState] = useState({
    priorityTooltip: false,
    signTooltip: false,
    prefferedSignTooltip: false
  })

  // Preview section box dynamic height and set document Preview array
  useEffect(() => {
    if (documentDataResponse) {
      setDocumentReviewer(documentDataResponse?.review)
    }
  }, [isSuccess])

  const handleClick = () => {
    // setReviewForm('')
    setAnchorEl(spanRef.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setAnchorEl(spanRef.current)
  }, [])

  // Handle accordian expand state
  const handleExpandChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : 0)
  }

  // Check if there is missing priority or not
  const handleCheckMissingPriority = () => {
    const largestNumber = Math.max(...selectedPriority)
    console.log(largestNumber)
    for (let i = 1; i < largestNumber; i++) {
      if (!selectedPriority.includes(i)) {
        return i
      }
    }
    return false
  }

  const handleExpandStampDutyAccord = (panel) => (event, newExpanded) => {
    const checkMissingPriority = handleCheckMissingPriority()
    if (!isProceedConsentButtonDisabled) {
      if (checkMissingPriority) {
        toast.error(errormessages?.REVIEWPRIORITYMISSING)
      } else {
        setExpanded(newExpanded ? panel : 0)
      }
    } else {
      toast.error(errormessages?.DOCUMENTREVIEWRMINREQUIRED)
    }
  }

  /**
     * Check for missing priority validation and then open the stamp duty accordian
     * @param {number} index: Next accor expand id
     */
  const handleOpenStampDutyAccordValidation = (index) => {
    const checkMissingPriority = handleCheckMissingPriority()
    console.log(checkMissingPriority)
    if (checkMissingPriority) {
      toast.error(errormessages?.REVIEWPRIORITYMISSING)
    } else {
      setExpanded(index + 1)
    }
  }

  // Handle accordian Arrow expand state
  // const handleExpandArrow = (currentSection) => {
  //   console.log('currentSection', currentSection, expanded)
  //   if (currentSection < expanded) {
  //     setExpanded(currentSection)
  //   }
  // }

  // Expand Previous accordian
  // const handleExpandPrev = (currentSection) => {
  //   setExpanded(currentSection - 1)
  // }

  /**
     * Handle next button  accordian state and check the validations and submit document draft
     * @param {number} index :current accordian open index
     */
  const handleNextAccord = async (index) => {
    // const validArray = await validateDocumentSection(
    //   documentDataResponse.sections[index]?.fields,
    //   documentForm.form
    // )
    // if (validArray.length > 0) {
    //   const errobj = {}
    //   validArray.map((error) => (errobj[`${error.fieldId}`] = error.errorMessage))
    //   setDocumentForm((prev) => ({
    //     ...prev,
    //     errors: errobj
    //   }))
    // } else {
    //   setDocumentForm((prev) => ({
    //     ...prev,
    //     errors: {}
    //   }))
    //   console.log('in else', index + 1)
    setExpanded(index + 1)

    // const documentDraftData = []
    // for (const [key, value] of Object.entries(documentForm?.form)) {
    //   documentDraftData.push({
    //     fieldId: Number(key),
    //     fieldValue: value
    //   })
    // }
    // updateDocumentDraft(documentDraftData)
  }

  // Save document Fields and Document Preview as draft api
  // const updateDocumentDraft = (documentDraftData) => {
  //   saveDocumentField({ payload: documentDraftData, documentId })
  //     .unwrap()
  //     .then((response) => {})
  //     .catch((exception) => {
  //       console.log('EXCEPTION:DOCUMENT field SAVE DRAFT', exception)
  //     })
  // }

  /**
     * Handle StampDutyInformation form changes
     * @param {object} event :event object
     */
  const handleStampDutyInformation = (event) => {
    setStampDutyInformation((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    console.log(reviewForm)
  }

  /**
     * Handle Review form changes
     * @param {object} event :event object
     */
  const handlePartyReviewFormChange = (event) => {
    setReviewForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    console.log(reviewForm)
  }

  /**
     * Handle Review form Email changes
     * @param {object} event :event object
     */
  const handlePartyReviewFormEmailChange = (event) => {
    const err = validator(event.target.name, event.target.value)
    setReviewForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      errors: {
        email: err
      }
    }))
    console.log(reviewForm)
  }

  /**
     * Handle check that all the required parties are added in the reviwer list or not (Multiple party, Single Party)
     * @param {Array} documentReviewerList: All added document reviwer list
     */
  const handleCheckAllPartiesSubmitted = (documentReviewerList) => {
    if (documentDataResponse?.partyType?.id === PARTYTYPEOPTIONS?.MULTIPLEPARTY?.id) {
      const partiesToCheck = [PARTYTYPES?.FIRSTPARTY?.ID, PARTYTYPES?.SECONDPARTY?.ID]
      console.log(documentReviewerList, partiesToCheck, 'documentReviewer partiesToCheck')
      const areIdsPresent = partiesToCheck.every((idToCheck) =>
        documentReviewerList.some((obj) => obj?.partyType?.id === idToCheck)
      )
      console.log('areIdsPresent', areIdsPresent)
      setIsProceedConsentButtonDisabled(!areIdsPresent)
    } else if (documentDataResponse?.partyType?.id === PARTYTYPEOPTIONS?.SINGLEPARTY?.id) {
      const partiesToCheck = [PARTYTYPES?.FIRSTPARTY?.ID, PARTYTYPES?.SECONDPARTY?.ID]
      const isIdPresent = documentReviewerList.some((obj) =>
        partiesToCheck.includes(obj?.partyType?.id)
      )
      setIsProceedConsentButtonDisabled(!isIdPresent)
    }
  }

  // Add reviewer in document Reviewer Array
  const handleAddReviewer = () => {
    const alreadyExist = documentReviewer.find(
      (item) =>
        item.email.toLowerCase() === reviewForm?.email.toLowerCase() &&
                item?.id !== reviewForm?.id
    )
    if (alreadyExist) {
      toast.error(errormessages?.EMAILPRESENT)
    } else {
      if (reviewForm?.errors?.email === 'noerror') {
        const reviewUserDetails = {
          id: reviewForm?.id || new Date().getTime(),
          partyType: reviewForm?.partyType,
          name: reviewForm?.name,
          address: reviewForm?.address,
          priority: reviewForm?.priority,
          consent: reviewForm?.consent,
          eSign: reviewForm?.eSign,
          email: reviewForm?.email,
          phone: reviewForm?.phone,
          signMode: Number(reviewForm?.signMode)
        }
        // check if we editing an existing reviewer
        const reviewerIndex = documentReviewer?.findIndex(
          (item) => item?.id === reviewForm?.id
        )
        let updatedReviewers

        if (reviewerIndex >= 0) {
          // Edit the existing reviewer
          updatedReviewers = documentReviewer.map((item, index) =>
            index === reviewerIndex ? { ...reviewUserDetails } : item
          )
        } else {
          updatedReviewers = [...documentReviewer, { ...reviewUserDetails }]
        }
        setDocumentReviewer(updatedReviewers)
        setReviewForm({
          name: '',
          address: '',
          partyType: '',
          priority: '',
          consent: false,
          eSign: true,
          signMode: 2,
          phone: '',
          email: '',
          errors: {
            email: 'error'
          }
        })
        setSelectedPriority([...selectedPriority, reviewForm?.priority])
        setEditModePriority(null)
        handleCheckAllPartiesSubmitted(updatedReviewers)
      }
    }
    console.log(documentReviewer, selectedPriority)
  }

  // Handle Close Digital sign notes modal and reset the sign model to e-sign
  const handleCancelDigitalSignNotesModal = () => {
    setReviewForm((prev) => ({
      ...prev,
      signMode: String(SIGNATUREMODES?.AADHAAR?.id)
    }))
    setDigitalSignNotesModalState((prev) => ({
      ...prev,
      open: false
    }))
  }

  /**
     * Handle Review form Sign Mode changes
     * @param {object} event :event object
     */
  const handleReviewFormSignModeChange = (event) => {
    setReviewForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    console.log(event.target.value, SIGNATUREMODES?.DSC?.id)
    if (Number(event.target.value) === SIGNATUREMODES?.DSC?.id) {
      setDigitalSignNotesModalState((prev) => ({
        ...prev,
        open: true
      }))
    }
    console.log(reviewForm)
  }

  /**
     * Handle Delete Reviewer
     * @param {object} reviewer:selected data
     */
  const handleDeleteReviewer = (reviewer) => {
    console.log(reviewer)
    const updatedReviwerList = documentReviewer.filter((item) => item.email !== reviewer.email)
    setDocumentReviewer(updatedReviwerList)
    setSelectedPriority(selectedPriority.filter((item) => item !== reviewer.priority))
    handleCheckAllPartiesSubmitted(updatedReviwerList)

    setOpenDeleteAlertModal(false)
    toast.success(TOAST_MESSAGE.REVIEW_USER_DELETED)
  }

  /**
     * Handle Delete Reviewer
     * @param {object} reviewer:selected data
     */
  const handleEditReviewer = (reviewer) => {
    console.log(reviewer)
    // Handle case where priority is not assigned (undefined or null)
    const hasPriority =
            reviewer?.priority !== undefined &&
            reviewer?.priority !== null &&
            reviewer?.priority !== ''
    // Reset the priority of the previously edited reviewer if any
    if (editModePriority !== null) {
      console.log('I AM HERE===>>>', editModePriority)
      setSelectedPriority((prev) => {
        return [...prev, editModePriority]
      })
    }

    setReviewForm({
      id: reviewer?.id,
      name: reviewer?.name,
      address: reviewer?.address,
      partyType: reviewer?.partyType,
      priority: reviewer?.priority,
      consent: false,
      eSign: reviewer?.eSign,
      signMode: reviewer?.signMode,
      email: reviewer?.email,
      phone: reviewer?.phone,
      errors: {
        email: 'noerror'
      }
    })

    // If priority is assigned, remove it from the used priorities
    if (hasPriority) {
      console.log('REMOVING PRIORITY===>>', selectedPriority)
      setSelectedPriority((prev) => {
        return prev.filter((item) => item !== reviewer.priority)
      })
    }
    setEditModePriority(reviewer.priority)
  }

  // Handle submit document for Review and consent
  const handleSubmitdocumentConsent = () => {
    const checkMissingPriority = handleCheckMissingPriority()
    console.log(checkMissingPriority)
    if (checkMissingPriority) {
      toast.error(errormessages?.REVIEWPRIORITYMISSING)
    } else {
      setBackdropState(true)
      const dataToSend = {
        reviewerList: documentReviewer,
        stampDutyPaidBy: stampDutyInformation?.stampDutyPaid
          ? stampDutyInformation?.stampDutyPaid
          : null,
        stampDutyPurchasedBy: stampDutyInformation?.stampDutyPurchased
          ? stampDutyInformation?.stampDutyPurchased
          : null
      }
      sendDocumentForReview({ payload: dataToSend, documentId })
        .unwrap()
        .then((response) => {
          setBackdropState(false)
          navigate(`/paymentSummary/${documentId}`, {
            state: {
              HeaderTitle: 'Create Document'
            }
          })
          // setOpenSuccessModal(true)
          // console.log('payment Status', paymentStatus)
        })
        .catch((execption) => {
          setBackdropState(false)
          console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
        })
    }
  }

  /**
     * Handle form changes , if value is empty replace span with dash(_____)
     * @param {object} event :event object
     */
  // const handleDocumentFormChange = (event, fieldData) => {
  //   setDocumentForm((prev) => ({
  //     ...prev,
  //     form: {
  //       ...prev.form,
  //       [fieldData?.id]: event.target.value
  //     }
  //   }))
  // }

  // const handleDocumentFocusChange = () => {
  //   console.log('in focus')
  // }

  const handleOpenTooltip = (tooltipkey) => {
    setTooltipState({
      ...tooltipInitialState,
      [tooltipkey]: true
    })
  }

  const handleCloseTooltip = (tooltipkey) => {
    setTooltipState({
      ...tooltipInitialState,
      [tooltipkey]: false
    })
  }

  return (
        <div className="createdocument_container">
            <Grid container spacing={3}>
                <Grid item lg={6}>
                    <Box sx={{ marginTop: '20px' }}>
                        <Accordion expanded={expanded === 1} onChange={handleExpandChange(1)}>
                            <div className="toggle">
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header">
                                    <Typography variant="darkValue">Article Information</Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <div className="reviewTable-box">
                                    {/* {documentDataResponse?.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.INDRAFT?.ID && ( */}
                                    <div className="reviewTable-FieldInputBox">
                                        {/* <form
                                            onSubmit={(event) => {
                                              event.preventDefault()
                                              handleAddReviewer()
                                            }}> */}
                                        <div className="FieldBox">
                                            <div className="FieldBoxLabel">
                                                <Typography variant="darkValue">State</Typography>
                                            </div>
                                            <div className="FieldBoxInput">
                                                <TextField
                                                    name="state"
                                                    type="text"
                                                    fullWidth
                                                    required
                                                    value={
                                                        documentDataResponse.purchaserDetails
                                                          ?.stateName || ''
                                                    }
                                                    id="state"
                                                    disabled
                                                    // onChange={handlePartyReviewFormChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="FieldBox">
                                            <div className="FieldBoxLabel">
                                                <Typography variant="darkValue">
                                                    Article Type
                                                </Typography>
                                            </div>
                                            <div className="FieldBoxInput">
                                                <TextField
                                                    name="articleType"
                                                    type="text"
                                                    fullWidth
                                                    required
                                                    value={
                                                        documentDataResponse.purchaserDetails
                                                          ?.articleType || ''
                                                    }
                                                    id="articleType"
                                                    disabled
                                                    // placeholder={PLACEHOLDER?.ADDRESS}
                                                    // onChange={handlePartyReviewFormChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="reviewTable-FieldInputButton">
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                onClick={() => handleNextAccord(1)}>
                                                Next
                                            </Button>
                                        </div>
                                        {/* </form> */}
                                    </div>
                                    {/* )} */}
                                    {/* <PartySignTable
                                        documentStatus={documentDataResponse?.status}
                                        documentReviewer={documentReviewer}
                                        handleDeleteReviewer={handleDeleteReviewer}
                                        handleEditReviewer={handleEditReviewer}
                                        // documentId={documentId}
                                    /> */}
                                    {/* {documentDataResponse?.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.INDRAFT?.ID && ( */}
                                    {/* <div className="reviewTable-button">
                                        <Button
                                            disabled={documentReviewer.length < 2}
                                            variant="primary"
                                            onClick={handleSubmitdocumentConsent}>
                                            Send Info
                                        </Button>
                                    </div> */}
                                    {/* )} */}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    {/* <Box>
                        {documentDataResponse?.sections?.map((section, sectionIndex) => (
                            <Accordion
                                key={sectionIndex}
                                expanded={expanded === sectionIndex}
                                onChange={() => handleExpandArrow(sectionIndex)}
                                sx={{ marginBottom: '20px' }}>
                                <div className="toggle">
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header">
                                        <Typography
                                            variant={
                                                expanded === sectionIndex
                                                  ? 'darkValueLarge'
                                                  : 'darkValue'
                                            }>
                                            {section?.titleText}
                                        </Typography>
                                    </AccordionSummary>

                                    {sectionIndex !== 0 ? (
                                        <div className="toggle-right">
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography variant="modalLabel">No</Typography>

                                                <Typography variant="modalLabel">Yes</Typography>
                                            </Stack>
                                        </div>
                                    ) : (
                                        <div className="toggle-right">
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Link
                                                    to="/createDocument"
                                                    className="text-link-back">
                                                    Back
                                                </Link>
                                            </Stack>
                                        </div>
                                    )}
                                </div>

                                <AccordionDetails>
                                    <Typography>
                                        <div style={{ padding: '30px' }}>
                                            {documentDataResponse?.sections[expanded]?.fields.map(
                                              (field, fieldindex) => (
                                                    <>
                                                        {field?.componentName === 'text' && (
                                                            <NormalTextField
                                                                key={field?.subFields[0]?.id}
                                                                textFieldData={field?.subFields[0]}
                                                                fieldLabel={field?.titleText}
                                                                onchange={handleDocumentFormChange}
                                                                onfocus={handleDocumentFocusChange}
                                                                documentForm={documentForm}
                                                            />
                                                        )}
                                                        {field?.componentName === 'date' && (
                                                            <DateTextField
                                                                textFieldData={field?.subFields[0]}
                                                                key={field?.subFields[0]?.id}
                                                                fieldLabel={field?.titleText}
                                                                // onchange={handleDocumentFormChange}
                                                                // onfocus={addHighlightClass}
                                                                // documentForm={documentForm}
                                                            />
                                                        )}
                                                        {field?.componentName === 'select' && (
                                                            <SelectDropdown
                                                                key={field?.subFields[0]?.id}
                                                                selectData={field?.subFields[0]}
                                                                fieldLabel={field?.titleText}
                                                                // onchange={handleDocumentFormChange}
                                                                // onfocus={addHighlightClass}
                                                                // documentForm={documentForm}
                                                            />
                                                        )}
                                                        {field?.componentName === 'select-text' && (
                                                            <SelectTextField
                                                                key={field?.subFields[0]?.id}
                                                                fieldLabel={field?.titleText}
                                                                selectData={field?.subFields.find(
                                                                  (field) =>
                                                                    field.type === 'dropdown'
                                                                )}
                                                                textFieldData={field?.subFields.find(
                                                                  (field) =>
                                                                    field.type === 'text' ||
                                                                        field.type === 'number'
                                                                )}
                                                                // onchange={handleDocumentFormChange}
                                                                // onfocus={addHighlightClass}
                                                                // documentForm={documentForm}
                                                            />
                                                        )}
                                                        {field?.componentName === 'radio-text' && (
                                                            <RadiowithTextfield
                                                                key={field?.subFields[0]?.id}
                                                                radioData={field?.subFields.find(
                                                                  (field) =>
                                                                    field.type ===
                                                                        'radio_button'
                                                                )}
                                                                textFieldData={field?.subFields.find(
                                                                  (field) =>
                                                                    field.type === 'text' ||
                                                                        field.type === 'number'
                                                                )}
                                                                fieldLabel={field?.titleText}
                                                                // documentForm={documentForm}
                                                                // onchange={handleDocumentFormChange}
                                                                // onfocus={addHighlightClass}
                                                            />
                                                        )}
                                                    </>
                                              )
                                            )}

                                            <div className="toggle-nextButton">
                                                {sectionIndex !== 0 && (
                                                    <Button
                                                        variant="primary"
                                                        onClick={() =>
                                                          handleExpandPrev(sectionIndex)
                                                        }>
                                                        Previous
                                                    </Button>
                                                )}
                                                <Button
                                                    variant="primary"
                                                    onClick={() => handleNextAccord(sectionIndex)}>
                                                    Next
                                                </Button>
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box> */}

                    {/* {keys &&
                        keys?.map((item, value) => (
                            <div className="details_box" key={value}>
                                <div className="details_box-head">
                                    <div className="details_box-content">
                                        <div className="details_box-content_head">
                                            <Typography variant="darkValueLarge">
                                                {item.replaceAll('_', ' ')}
                                            </Typography>
                                        </div>
                                        {value === 0 && (
                                            <div className="details_box-content_link">
                                                <Link
                                                    to="/createDocument"
                                                    className="text-link-back">
                                                    Back
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div style={{ padding: '30px' }}>
                                    {documentData[`${item}`]?.map((item1, index) => (
                                        <NormalTextField
                                            key={index}
                                            fieldLabel={item1?.label}
                                            name={item1?.name}
                                            type={item1?.type}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))} */}

                    {/* Consent table */}
                    <Box sx={{ marginTop: '20px' }} mb="20px">
                        <Accordion expanded={expanded === 2} onChange={handleExpandChange(2)}>
                            <div className="toggle">
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header">
                                    <Typography variant="darkValue">Step 2</Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <div className="reviewTable-box">
                                    {/* {documentDataResponse?.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.INDRAFT?.ID && ( */}
                                    <div className="reviewTable-FieldInputBox">
                                        <form
                                            onSubmit={(event) => {
                                              event.preventDefault()
                                              handleAddReviewer()
                                            }}>
                                            <div className="FieldBox">
                                                <div className="FieldBoxLabel">
                                                    <Typography variant="darkValue">
                                                        Select party type
                                                    </Typography>
                                                </div>
                                                <div className="FieldBoxInput">
                                                    <Select
                                                        required
                                                        name="partyType"
                                                        value={reviewForm?.partyType}
                                                        onChange={handlePartyReviewFormChange}
                                                        displayEmpty
                                                        inputProps={{
                                                          'aria-label': 'Without label'
                                                        }}
                                                        sx={{
                                                          fontSize: '16px'
                                                        }}
                                                        MenuProps={MenuProps}
                                                        fullWidth>
                                                        <MenuItem value="" disabled>
                                                            <Typography>
                                                                Select party type
                                                            </Typography>
                                                        </MenuItem>

                                                        {partyTypeArray?.length > 0 &&
                                                            partyTypeArray?.map((item) => (
                                                                <MenuItem
                                                                    sx={{
                                                                      fontSize: '16px'
                                                                    }}
                                                                    key={item.id}
                                                                    value={item}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="FieldBox">
                                                <div className="FieldBoxLabel">
                                                    <Typography variant="darkValue">
                                                        Enter name
                                                    </Typography>
                                                </div>
                                                <div className="FieldBoxInput">
                                                    <TextField
                                                        name="name"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{
                                                          type: 'text',
                                                          maxlength:
                                                                CLVALIDATIONS?.NAME?.MAXLENGTH,
                                                          minlength:
                                                                CLVALIDATIONS?.NAME?.MINLENGTH
                                                        }}
                                                        required
                                                        value={reviewForm?.name || ''}
                                                        id="name"
                                                        placeholder={PLACEHOLDER?.FULLNAME}
                                                        onChange={(event) => {
                                                          if (
                                                            event.target.value === '' ||
                                                                CLVALIDATIONS?.NAME?.PATTERN.test(
                                                                  event?.target?.value
                                                                )
                                                          ) {
                                                            handlePartyReviewFormChange(event)
                                                          }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="FieldBox">
                                                <div className="FieldBoxLabel">
                                                    <Typography variant="darkValue">
                                                        Enter address
                                                    </Typography>
                                                </div>
                                                <div className="FieldBoxInput">
                                                    <TextField
                                                        name="address"
                                                        type="text"
                                                        inputProps={{
                                                          type: 'text',
                                                          maxlength:
                                                                CLVALIDATIONS?.ADDRESS?.MAXLENGTH,
                                                          minlength:
                                                                CLVALIDATIONS?.ADDRESS?.MINLENGTH
                                                        }}
                                                        fullWidth
                                                        required
                                                        value={reviewForm?.address || ''}
                                                        id="address"
                                                        placeholder={PLACEHOLDER?.ADDRESS}
                                                        onChange={(event) => {
                                                          if (
                                                            event.target.value === '' ||
                                                                CLVALIDATIONS?.ADDRESS?.PATTERN.test(
                                                                  event?.target?.value
                                                                )
                                                          ) {
                                                            handlePartyReviewFormChange(event)
                                                          }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="FieldBox">
                                                <div className="FieldBoxLabel">
                                                    <Typography variant="darkValue">
                                                        Enter email
                                                    </Typography>
                                                </div>
                                                <div className="FieldBoxInput">
                                                    <TextField
                                                        error={
                                                            !(
                                                              reviewForm?.errors?.email ===
                                                                    'error' ||
                                                                reviewForm?.errors?.email ===
                                                                    'noerror'
                                                            )
                                                        }
                                                        helperText={
                                                            reviewForm?.errors?.email === 'error' ||
                                                            reviewForm?.errors?.email === 'noerror'
                                                              ? ''
                                                              : reviewForm?.errors?.email
                                                        }
                                                        name="email"
                                                        type="email"
                                                        fullWidth
                                                        required
                                                        value={reviewForm.email || ''}
                                                        id="email"
                                                        placeholder={PLACEHOLDER?.EMAIL}
                                                        onChange={handlePartyReviewFormEmailChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="FieldBox">
                                                <div className="FieldBoxLabel">
                                                    <Typography variant="darkValue">
                                                        Enter phone number
                                                    </Typography>
                                                </div>
                                                <div className="FieldBoxInput">
                                                    <TextField
                                                        value={reviewForm?.phone || ''}
                                                        fullWidth
                                                        required
                                                        type="text"
                                                        inputProps={{
                                                          type: 'text',
                                                          // inputMode: 'numeric',
                                                          pattern: '[0-9]*',
                                                          maxlength: 10,
                                                          minlength: 10
                                                        }}
                                                        id="phone"
                                                        name="phone"
                                                        InputProps={{
                                                          startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={flag} alt="flag" />
                                                                    &nbsp;+91 |
                                                                </InputAdornment>
                                                          )
                                                        }}
                                                        onChange={(e) => {
                                                          if (
                                                            e.target.value === '' ||
                                                                PATTERN?.NUMBERONLY.test(
                                                                  e?.target?.value
                                                                )
                                                          ) {
                                                            handlePartyReviewFormChange(e)
                                                          }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="FieldBox">
                                                <div className="FieldBoxLabel">
                                                    <Typography variant="darkValue">
                                                        Select priority
                                                        <CustomTooltip
                                                            message={PRIORITYMESSAGE}
                                                            openTooltip={
                                                                tooltipState?.priorityTooltip
                                                            }
                                                            setOpenTooltip={setTooltipState}
                                                            handleTooltipOpen={() =>
                                                              handleOpenTooltip('priorityTooltip')
                                                            }
                                                            handleTooltipClose={() =>
                                                              handleCloseTooltip(
                                                                'priorityTooltip'
                                                              )
                                                            }
                                                        />
                                                    </Typography>
                                                </div>
                                                <div className="FieldBoxInput">
                                                    <Select
                                                        required
                                                        name="priority"
                                                        value={reviewForm?.priority || ''}
                                                        onChange={handlePartyReviewFormChange}
                                                        displayEmpty
                                                        inputProps={{
                                                          'aria-label': 'Without label'
                                                        }}
                                                        sx={{
                                                          fontSize: '16px'
                                                        }}
                                                        MenuProps={MenuProps}
                                                        fullWidth>
                                                        <MenuItem value="" disabled>
                                                            <Typography>Select priority</Typography>
                                                        </MenuItem>
                                                        {priorityList?.length > 0 &&
                                                            priorityList?.map((item) => (
                                                                <MenuItem
                                                                    disabled={selectedPriority.includes(
                                                                      item
                                                                    )}
                                                                    sx={{
                                                                      fontSize: '16px'
                                                                    }}
                                                                    key={item}
                                                                    value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="FieldBox">
                                                <div className="FieldBoxLabel">
                                                    <Typography variant="darkValue">
                                                        Preferred mode of signature
                                                        <CustomTooltip
                                                            message={PREFFEREDSIGNMODEMESSAGE}
                                                            openTooltip={
                                                                tooltipState?.prefferedSignTooltip
                                                            }
                                                            setOpenTooltip={setTooltipState}
                                                            handleTooltipOpen={() =>
                                                              handleOpenTooltip(
                                                                'prefferedSignTooltip'
                                                              )
                                                            }
                                                            handleTooltipClose={() =>
                                                              handleCloseTooltip(
                                                                'prefferedSignTooltip'
                                                              )
                                                            }
                                                        />
                                                    </Typography>
                                                </div>
                                                <div className="FieldBoxInput">
                                                    <FormControl>
                                                        <RadioGroup
                                                            required
                                                            name="signMode"
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            value={reviewForm?.signMode || ''}
                                                            onChange={
                                                                handleReviewFormSignModeChange
                                                            }>
                                                            {signatureptions.length > 0 &&
                                                                signatureptions.map(
                                                                  (signmode, index) => (
                                                                        <FormControlLabel
                                                                            key={signmode?.id}
                                                                            value={signmode?.id}
                                                                            control={
                                                                                <Radio
                                                                                    sx={{
                                                                                      color: 'var(--g2)',
                                                                                      '&.Mui-checked':
                                                                                            {
                                                                                              color: palette
                                                                                                .success
                                                                                                .dark
                                                                                            }
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography
                                                                                    variant="labelSmall"
                                                                                    sx={{
                                                                                      color: 'var(--g6)',
                                                                                      fontSize:
                                                                                            '14px'
                                                                                    }}>
                                                                                    {
                                                                                        signmode?.title
                                                                                    }
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                  )
                                                                )}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className="reviewTable-FieldInputButton">
                                                <Button type="submit" variant="primary">
                                                    Add Info
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* )} */}
                                    <PartySignTable
                                        documentStatus={documentDataResponse?.status}
                                        documentReviewer={documentReviewer}
                                        handleDeleteReviewer={(item) => {
                                          setCurrentSelectedReview(item)
                                          setOpenDeleteAlertModal(true)
                                        }}
                                        handleEditReviewer={handleEditReviewer}
                                        // documentId={documentId}
                                    />
                                    {/* {documentDataResponse?.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.INDRAFT?.ID && ( */}
                                    {documentConfiguration?.customDocument?.stampdutyAccord && (
                                        <div className="reviewTable-button">
                                            <Button
                                                disabled={isProceedConsentButtonDisabled}
                                                variant="primary"
                                                onClick={() =>
                                                  handleOpenStampDutyAccordValidation(2)
                                                }>
                                                Next
                                            </Button>
                                        </div>
                                    )}
                                    {/* )} */}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    {/* Stamp Duty Information table */}
                    {documentConfiguration?.customDocument?.stampdutyAccord &&
                        documentDataResponse?.status?.id === DOCUEMENTFILTERSTATUS?.INDRAFT?.ID && (
                            <Box sx={{ marginTop: '20px' }} mb="20px">
                                <Accordion
                                    expanded={expanded === 3}
                                    onChange={handleExpandStampDutyAccord(3)}>
                                    <div className="toggle">
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header">
                                            <Typography variant="darkValue">
                                                Stamp Duty Information
                                            </Typography>
                                        </AccordionSummary>
                                    </div>
                                    <AccordionDetails>
                                        <div className="reviewTable-box">
                                            {/* {documentDataResponse?.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.INDRAFT?.ID && ( */}
                                            <div className="reviewTable-FieldInputBox">
                                                <form
                                                    onSubmit={(event) => {
                                                      event.preventDefault()
                                                    }}>
                                                    <>
                                                        <div className="FieldBox">
                                                            <div className="FieldBoxLabel">
                                                                <Typography variant="darkValue">
                                                                    Select Stamp Duty Paid By
                                                                </Typography>
                                                            </div>
                                                            <div className="FieldBoxInput">
                                                                <Select
                                                                    required
                                                                    name="stampDutyPaid"
                                                                    value={
                                                                        stampDutyInformation?.stampDutyPaid
                                                                    }
                                                                    onChange={
                                                                        handleStampDutyInformation
                                                                    }
                                                                    displayEmpty
                                                                    inputProps={{
                                                                      'aria-label':
                                                                            'Without label'
                                                                    }}
                                                                    sx={{
                                                                      fontSize: '16px'
                                                                    }}
                                                                    MenuProps={MenuProps}
                                                                    fullWidth>
                                                                    <MenuItem value="" disabled>
                                                                        <Typography>
                                                                            Select Stamp Duty Paid
                                                                        </Typography>
                                                                    </MenuItem>

                                                                    {documentReviewer?.length > 0 &&
                                                                        documentReviewer
                                                                          ?.filter(
                                                                            (item) =>
                                                                              item?.partyType
                                                                                ?.id !==
                                                                                    PARTYTYPES
                                                                                      ?.WITNESS
                                                                                      ?.ID
                                                                          )
                                                                          ?.map((item) => (
                                                                                <MenuItem
                                                                                    sx={{
                                                                                      fontSize:
                                                                                            '16px'
                                                                                    }}
                                                                                    key={item.id}
                                                                                    value={item}>
                                                                                    {` ${item?.partyType?.name}(${item?.email})`}
                                                                                </MenuItem>
                                                                          ))}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        {/* <div className="FieldBox">
                                                            <div className="FieldBoxLabel">
                                                                <Typography variant="darkValue">
                                                                    Select Stamp Duty Purchased By
                                                                </Typography>
                                                            </div>
                                                            <div className="FieldBoxInput">
                                                                <Select
                                                                    required
                                                                    name="stampDutyPurchased"
                                                                    value={
                                                                        stampDutyInformation?.stampDutyPurchased
                                                                    }
                                                                    onChange={
                                                                        handleStampDutyInformation
                                                                    }
                                                                    displayEmpty
                                                                    inputProps={{
                                                                      'aria-label':
                                                                            'Without label'
                                                                    }}
                                                                    sx={{
                                                                      fontSize: '16px'
                                                                    }}
                                                                    MenuProps={MenuProps}
                                                                    fullWidth>
                                                                    <MenuItem value="" disabled>
                                                                        <Typography>
                                                                            Select party type
                                                                        </Typography>
                                                                    </MenuItem>

                                                                    {documentReviewer?.length > 0 &&
                                                                        documentReviewer?.map(
                                                                          (item) => (
                                                                                <MenuItem
                                                                                    sx={{
                                                                                      fontSize:
                                                                                            '16px'
                                                                                    }}
                                                                                    key={item.id}
                                                                                    value={item}>
                                                                                    {` ${item?.partyType?.name}(${item?.email})`}
                                                                                </MenuItem>
                                                                          )
                                                                        )}
                                                                </Select>
                                                            </div>
                                                        </div> */}
                                                    </>
                                                    {/* {documentConfiguration?.customDocument
                                                  ?.stampdutyInformationPrefiled && (
                                                    <>
                                                        <div className="FieldBox">
                                                            <div className="FieldBoxLabel">
                                                                <Typography variant="darkValue">
                                                                    Stamp Duty Paid By
                                                                </Typography>
                                                            </div>
                                                            <div className="FieldBoxInput">
                                                                <TextField
                                                                    name="stampDutyPaidBy"
                                                                    type="stampDutyPaidBy"
                                                                    fullWidth
                                                                    required
                                                                    value={
                                                                        documentDataResponse
                                                                          .purchaserDetails
                                                                          ?.stampDutyPaidBy || ''
                                                                    }
                                                                    id="stampDutyPaidBy"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="FieldBox">
                                                            <div className="FieldBoxLabel">
                                                                <Typography variant="darkValue">
                                                                    Stamp Duty Purchased By
                                                                </Typography>
                                                            </div>
                                                            <div className="FieldBoxInput">
                                                                <TextField
                                                                    name="stampDutyPurchasedBy"
                                                                    type="stampDutyPurchasedBy"
                                                                    fullWidth
                                                                    required
                                                                    value={
                                                                        documentDataResponse
                                                                          .purchaserDetails
                                                                          ?.stampDutyPurchasedBy ||
                                                                        ''
                                                                    }
                                                                    id="stampDutyPurchasedBy"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )} */}
                                                    {/* <div className="reviewTable-FieldInputButton">
                                                <Button type="submit" variant="primary">
                                                    Add Info
                                                </Button>
                                            </div> */}
                                                </form>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                    )}

                    {documentDataResponse?.isOwner && (
                        <Button
                            variant="primary"
                            onClick={handleSubmitdocumentConsent}
                            disabled={
                                documentConfiguration?.customDocument?.stampdutyAccord
                                  ? !stampDutyInformation?.stampDutyPaid
                                  : isProceedConsentButtonDisabled
                            }>
                            Proceed to payment
                        </Button>
                    )}
                </Grid>

                <Grid lg={6} item>
                    <div className="details_rightgrid">
                        <div className="details_rightgrid-head">
                            <img
                                src={info}
                                alt="info"
                                style={{ marginRight: '15px' }}
                                id={id}
                                onClick={handleClick}
                                ref={spanRef}
                            />
                            <Typography variant="darkValueLarge">Important notes</Typography>
                        </div>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            // onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}>
                            <Box className="popover_box">
                                <div className="popover_box-head">
                                    <Typography variant="darkValueLarge" sx={{ color: '#003A51' }}>
                                        Important Notes
                                    </Typography>
                                </div>
                                {importantNotes &&
                                    importantNotes?.map((item, indexImpNotes) => (
                                        <div className="popover_box-details1" key={indexImpNotes}>
                                            <Typography
                                                variant="modalLabel"
                                                sx={{
                                                  color: '#555555',
                                                  letterSpacing: '0.03em',
                                                  lineHeight: '30px'
                                                }}>
                                                {item}
                                            </Typography>
                                        </div>
                                    ))}
                                <Button variant="primary" onClick={handleClose}>
                                    Ok
                                </Button>
                            </Box>
                        </Popover>
                        <Grid
                            className="details_rightgrid-box"
                            sx={{ maxHeight: 'calc(100vh - 14rem)' }}>
                            <PreviewDocument
                                documentId={documentId}
                                documentPreview={documentPreview}
                            />
                        </Grid>
                        <div></div>
                    </div>
                </Grid>
            </Grid>
            {/* Alert confirmation modal */}
            {openDeleteAlertModal && (
                <AlertModal
                    openAlertModal={openDeleteAlertModal}
                    handleAlertModalClose={() => setOpenDeleteAlertModal(false)}
                    handleSubmitAlertModal={() => handleDeleteReviewer(currentSelectedReview)}
                    alertModalState={deleteAlertModalState}
                />
            )}
            {/* Success Form send for review to other parties modal  */}
            <SuccessModal
                openSuccessModal={openSuccessModal}
                handleSuccessModalClose={() => setOpenSuccessModal(false)}
            />
            {digitalSignNotesModalState?.open && (
                <CommonMessageButtonModal
                    handleOkButtonCommonModal={() => {
                      setDigitalSignNotesModalState((prev) => ({
                        ...prev,
                        open: false
                      }))
                    }}
                    handleCancelButton={handleCancelDigitalSignNotesModal}
                    CommonMessageModalState={digitalSignNotesModalState}
                />
            )}
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </div>
  )
}

export default DocumentDetails
