import {
  Button,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  Checkbox
} from '@mui/material'
import React, { useState } from 'react'
import { CLVALIDATIONS, EXCEPTTHISSYMBOLS, PATTERN } from '../../../../../constants/enum'
import MenuProps from '../../../../../StyledComponents/SelectStyles/SelectStyles'
import { useGetCityQuery } from '../../../../../store/services/userServices'
import PropTypes from 'prop-types'
import { useGetProfileQuery } from '../../../../../store/services/profleServices'
import { toast } from 'react-toastify'
import errormessages from '../../../../../constants/errormessages'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  background: '#FFFFFF',
  boxShadow: '6px 11px 60px 12px rgba(0, 0, 0, 0.06)',
  borderRadius: '5px',
  p: 6
}
const AddressInformationModal = ({
  openAddressModal,
  handleSubmitAddressModal,
  handleCancelAddressModal,
  handleCheckPinCodeService,
  stateArray,
  editableAddress,
  pinCodeStatus
}) => {
  const [addressDetails, setAddressDetails] = useState(editableAddress || {})
  // Use profile address or not checkbox
  const [useProfileAddress, setUseProfileAddress] = useState(false)

  // Get profile data
  const { data: profileData = {}, isSuccess: isSuccessProfile } = useGetProfileQuery({})
  // Except symbol on number field
  const exceptThisSymbols = EXCEPTTHISSYMBOLS

  // Get cities according to the state selected
  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: addressDetails?.stateId },
    {
      skip: !addressDetails.stateId
    }
  )

  const handleUseProfileAddress = (event) => {
    if (event.target.checked && isSuccessProfile) {
      setAddressDetails((prev) => ({
        id: editableAddress?.id || new Date().getTime(),
        name: profileData?.name,
        address: profileData?.address,
        addressLineTwo: profileData?.addressLineTwo,
        stateId: profileData?.state?.id,
        cityId: profileData?.city?.id,
        pinCode: profileData?.pinCode,
        phoneNumber: profileData?.phone,
        stateName: stateArray.find((state) => state.id === profileData?.state)?.name,
        cityName: cityArray.find((city) => city.id === addressDetails?.city)?.name
      }))
      handleCheckPinCodeService(profileData?.pinCode)
    } else {
      setAddressDetails((prev) => ({
        id: '',
        name: '',
        address: '',
        addressLineTwo: '',
        stateId: '',
        cityId: '',
        pinCode: '',
        phoneNumber: '',
        stateName: '',
        cityName: ''
      }))
    }
    setUseProfileAddress(event.target.checked)
  }

  const handleAddAddressClick = () => {
    if (!pinCodeStatus) {
      toast.error(errormessages?.ENTERVALIDPINCODE)
      return
    }

    const address = {
      id: addressDetails?.id === '' ? new Date().getTime() : addressDetails?.id,
      name: addressDetails?.name,
      address: addressDetails?.address,
      addressLineTwo: addressDetails?.addressLineTwo,
      stateId: addressDetails?.stateId,
      cityId: addressDetails?.cityId,
      pinCode: addressDetails?.pinCode,
      phoneNumber: addressDetails?.phoneNumber,
      stateName: stateArray.find((state) => state.id === addressDetails?.stateId)?.name,
      cityName: cityArray.find((city) => city.id === addressDetails?.cityId)?.name
    }
    handleSubmitAddressModal(address)
  }

  /**
     * Handle form change in payment summary form
     * @param {object} event:event object
     */
  const handlePaymentFormPinCodeChange = (event) => {
    console.log(event.target.value)
    setAddressDetails((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
    if (event.target.value?.length === 6) {
      handleCheckPinCodeService(event.target.value)
    }
  }

  /**
     * Handle form change in payment summar form
     * @param {object} event:event object
     */
  const handleAddressFormChange = (event) => {
    setAddressDetails((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
  }
  return (
        <Modal
            open={openAddressModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className="details_box" style={style}>
                <div className="shippingAddress-head">
                    <div className="details_box-content">
                        <div className="details_box-content_head">
                            <Typography variant="darkValueLarge">Shipping address</Typography>
                        </div>

                        <div className="details_box-content_link">
                            <FormControlLabel
                                sx={{ mt: '-10px', mr: '0px' }}
                                control={
                                    <Checkbox
                                        sx={{
                                          color: 'var(--g2)',
                                          '&.Mui-checked': {
                                            color: '#33AFB9'
                                          }
                                        }}
                                        checked={useProfileAddress}
                                        name="useProfileAddress"
                                        id="useProfileAddress"
                                        onChange={(event) => {
                                          handleUseProfileAddress(event)
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                          color: 'var(--g7)',
                                          fontWeight: 600,
                                          fontSize: '15px'
                                        }}>
                                        Use my profile address
                                    </Typography>
                                }
                            />
                        </div>
                    </div>
                </div>
                <div style={{ padding: '30px' }}>
                    <form
                        onSubmit={(e) => {
                          e.preventDefault()
                          handleAddAddressClick()
                        }}>
                        <div className="FieldBox">
                            <div className="shippingAddress_container-title">
                                <Typography variant="darkValue">Name</Typography>
                            </div>
                            <div className="shippingAddress_container-field">
                                <TextField
                                    value={addressDetails?.name || ''}
                                    required
                                    id="name"
                                    name="name"
                                    type="text"
                                    inputProps={{
                                      type: 'text',
                                      maxlength: CLVALIDATIONS?.DELIVERYNAME?.MAXLENGTH,
                                      minlength: CLVALIDATIONS?.DELIVERYNAME?.MINLENGTH
                                    }}
                                    fullWidth
                                    onChange={(event) => {
                                      if (
                                        event.target.value === '' ||
                                            CLVALIDATIONS?.DELIVERYNAME?.PATTERN.test(
                                              event?.target?.value
                                            )
                                      ) {
                                        handleAddressFormChange(event)
                                      }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="FieldBox">
                            <div className="shippingAddress_container-title">
                                <Typography variant="darkValue">Address Line 1</Typography>
                            </div>
                            <div className="shippingAddress_container-field">
                                <TextField
                                    value={addressDetails?.address || ''}
                                    required
                                    inputProps={{
                                      type: 'text',
                                      maxlength: CLVALIDATIONS?.ADDRESS?.MAXLENGTH,
                                      minlength: CLVALIDATIONS?.ADDRESS?.MINLENGTH
                                    }}
                                    id="address"
                                    name="address"
                                    type="text"
                                    fullWidth
                                    onChange={(event) => {
                                      if (
                                        event.target.value === '' ||
                                            CLVALIDATIONS?.ADDRESS?.PATTERN.test(
                                              event?.target?.value
                                            )
                                      ) {
                                        handleAddressFormChange(event)
                                      }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="FieldBox">
                            <div className="shippingAddress_container-title">
                                <Typography variant="darkValue">Address Line 2</Typography>
                            </div>
                            <div className="shippingAddress_container-field">
                                <TextField
                                    value={addressDetails?.addressLineTwo || ''}
                                    required
                                    inputProps={{
                                      type: 'text',
                                      maxlength: CLVALIDATIONS?.ADDRESS?.MAXLENGTH,
                                      minlength: CLVALIDATIONS?.ADDRESS?.MINLENGTH
                                    }}
                                    id="address"
                                    name="addressLineTwo"
                                    type="text"
                                    fullWidth
                                    onChange={(event) => {
                                      if (
                                        event.target.value === '' ||
                                            CLVALIDATIONS?.ADDRESS?.PATTERN.test(
                                              event?.target?.value
                                            )
                                      ) {
                                        handleAddressFormChange(event)
                                      }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="FieldBox">
                            <div className="shippingAddress_container-title">
                                <Typography variant="darkValue">State</Typography>
                            </div>
                            <div className="shippingAddress_container-field">
                                <Select
                                    name="stateId"
                                    value={addressDetails?.stateId || ''}
                                    onChange={(event) => {
                                      console.log('State EVENT', event)
                                      setAddressDetails((prev) => ({
                                        ...prev,
                                        [event.target.name]: event.target.value,
                                        cityId: ''
                                      }))
                                    }}
                                    required
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{ fontSize: '16px' }}
                                    MenuProps={MenuProps}
                                    fullWidth>
                                    <MenuItem value="" disabled>
                                        <Typography>Select State</Typography>
                                    </MenuItem>

                                    {stateArray &&
                                        stateArray.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item.id}
                                                value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </div>
                        </div>
                        <div className="FieldBox">
                            <div className="shippingAddress_container-title">
                                <Typography variant="darkValue">City</Typography>
                            </div>
                            <div className="shippingAddress_container-field">
                                <Select
                                    name="cityId"
                                    value={addressDetails?.cityId || ''}
                                    onChange={(event) => {
                                      console.log('CITY EVENT', event)
                                      setAddressDetails((prev) => ({
                                        ...prev,
                                        [event.target.name]: event.target.value
                                      }))
                                    }}
                                    required
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{ fontSize: '16px' }}
                                    MenuProps={MenuProps}
                                    fullWidth>
                                    <MenuItem value="" disabled>
                                        <Typography>Select City</Typography>
                                    </MenuItem>
                                    {cityArray &&
                                        cityArray.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item.id}
                                                value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </div>
                        </div>
                        <div className="FieldBox">
                            <div className="shippingAddress_grid-title">
                                <Typography variant="darkValue">Pincode</Typography>
                            </div>
                            <div className="shippingAddress_grid-field">
                                <TextField
                                    value={addressDetails?.pinCode || ''}
                                    required
                                    id="pinCode"
                                    name="pinCode"
                                    type="text"
                                    inputProps={{
                                      type: 'text',
                                      // inputMode: 'numeric',
                                      pattern: '[0-9]*',
                                      maxlength: 6,
                                      minlength: 6
                                    }}
                                    onKeyDown={(e) =>
                                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                                    }
                                    fullWidth
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                            PATTERN?.NUMBERONLY.test(e?.target?.value)
                                      ) {
                                        handlePaymentFormPinCodeChange(e)
                                      }
                                    }}
                                />
                            </div>
                            <div
                                className="shippingAddress_grid-title"
                                style={{ justifyContent: 'center' }}>
                                <Typography variant="darkValue">Phone</Typography>
                            </div>
                            <div className="shippingAddress_grid-field">
                                <TextField
                                    value={addressDetails?.phoneNumber || ''}
                                    required
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    type="text"
                                    inputProps={{
                                      type: 'text',
                                      // inputMode: 'numeric',
                                      pattern: '[0-9]*',
                                      maxlength: 10,
                                      minlength: 10
                                    }}
                                    // onKeyDown={(e) =>
                                    //   exceptThisSymbols.includes(e.key) &&
                                    //     e.preventDefault()
                                    // }
                                    // onInput={(e) => {
                                    //   e.target.value = Math.max(
                                    //     0,
                                    //     parseInt(e.target.value)
                                    //   )
                                    //     .toString()
                                    //     .slice(0, 10)
                                    // }}
                                    fullWidth
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                            PATTERN?.NUMBERONLY.test(e?.target?.value)
                                      ) {
                                        handleAddressFormChange(e)
                                      }
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginTop: '30px'
                            }}>
                            <Button variant="cancelButton" onClick={handleCancelAddressModal}>
                                Cancel
                            </Button>

                            <Button variant="primary" type="submit">
                                Add
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
  )
}

export default AddressInformationModal
AddressInformationModal.propTypes = {
  openAddressModal: PropTypes.bool,
  handleSubmitAddressModal: PropTypes.func,
  handleCancelAddressModal: PropTypes.func,
  handleCheckPinCodeService: PropTypes.func,
  handleUserProfileAddress: PropTypes.func,
  userProfileAddress: PropTypes.object,
  stateArray: PropTypes.array,
  editableAddress: PropTypes.object,
  pinCodeStatus: PropTypes.string
}
