/* eslint-disable */
import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary
} from '../../../../StyledComponents/AccordianStyles/AccordionStyles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PreviewScreen from './PreviewScreen/PreviewScreen';
import {
    useGetDocumentPreviewAndReviewUsersByIdQuery,
    useLazyGetEstampCertificateDetailsQuery
} from '../../../../store/services/signatureService';
import CommonTableReview from '../../../../components/CommonTableReview/CommonTableReview';
import html2pdf from 'html2pdf.js';
import { generateStampPaper } from '../../../../util/stampPaperHelper';
import { useLinkClPdfWithDocumentChildMutation } from '../../../../store/services/documentServices';
import { DOCUMENTMESSAGE } from '../../../../constants/message';
import { toast } from 'react-toastify';
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader';
import { useEffect } from 'react';

const SignatureRequestedPreview = () => {
    const { documentId } = useParams();

    const navigate = useNavigate();
    // Backdrop loader state
    const [backdropState, setBackdropState] = useState(false);

    // Accordian expanded state
    const [expanded, setExpanded] = useState(0);

    // Get document Preview and Review section data
    const {
        data: documentDataResponse = {
            review: [],
            transactions: []
        },
        refetch
    } = useGetDocumentPreviewAndReviewUsersByIdQuery(documentId, {
        skip: !documentId
    });

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Grid container justifyContent={'flex-end'}>
                <Grid item>
                    <Button variant="cancelButton" onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={6}>
                    <Box sx={{ marginTop: '20px' }} mb="20px">
                        <Accordion expanded={expanded === 0} onChange={handleChange(0)}>
                            <div className="toggle">
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header">
                                    <Typography variant="darkValue">Details</Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <div className="reviewTable-box">
                                    <CommonTableReview
                                        tableData={documentDataResponse?.transactions}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Grid>
                <Grid lg={6} item>
                    <Box sx={{ marginTop: '20px' }} mb="20px">
                        <Grid
                            className="details_rightgrid-box"
                            sx={{ maxHeight: 'calc(100vh - 16rem)' }}>
                            <PreviewScreen file={documentDataResponse?.unsignedFile?.fileUri} />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
    );
};

export default SignatureRequestedPreview;
