import React from 'react'
import { FormControlLabel, Typography, Checkbox } from '@mui/material'
import PropTypes from 'prop-types'

const CheckboxWithText = ({ checkBoxData, fieldData, documentForm, onchange }) => {
  console.log('INSIDE CHECKBOX FIELD====>', checkBoxData, documentForm)
  // const radioValue =
  //       !documentForm?.form[`${radioData?.id}`] && documentForm?.form[`${radioData?.id}`] !== ''
  //         ? radioData?.props?.prefilled
  //         : documentForm?.form[`${radioData?.id}`]
  return (
        <div className="FieldBox">
            <FormControlLabel
                // sx={{ mt: '-10px', mr: '0px' }}
                control={
                    <Checkbox
                        sx={{
                          color: 'var(--g2)',
                          '&.Mui-checked': {
                            color: '#33AFB9'
                          },
                          marginRight: 2
                        }}
                        checked={
                            documentForm?.form[`${checkBoxData?.id}`] ??
                            checkBoxData?.props?.prefilled === 'true'
                        }
                        onChange={(event) => {
                          onchange(event, checkBoxData)
                        }}
                    />
                }
                label={
                    <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '15px'
                        }}>
                        {checkBoxData?.description}
                    </Typography>
                }
            />
        </div>
  )
}

export default CheckboxWithText

CheckboxWithText.propTypes = {
  checkBoxData: PropTypes.object,
  fieldData: PropTypes.object,
  onchange: PropTypes.func,
  onfocus: PropTypes.func,
  documentForm: PropTypes.object
}
