/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    Box,
    Button,
    Checkbox,
    InputAdornment,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    styled,
    TextField,
    Typography,
    FormControl,
    RadioGroup,
    Radio,
    Popover
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { validator } from '../../../../util/validator';
import html2pdf from 'html2pdf.js';
import './CustomDocument.css';
import MuiAccordion from '@mui/material/Accordion';
import flag from '../../../../assets/images/india-flag.svg';
import info from '../../../../assets/images/info.svg';
// import commentView from '../../../../assets/images/commentViewicon.svg'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import CustomSwitch from '../../../../components/CustomSwitch/CustomSwitch'
import DateTextField from '../../../../components/InputFields/DateTextField';
import ReviewTable from '../components/ReviewConsentTable/ReviewTable';
import SuccessModal from '../components/SuccessModal/SuccessModal';
import rightarrow from '../../../../assets/images/rightarrow.svg';
import NormalTextField from '../../../../components/InputFields/NormalTextField';
// import RadioButton from '../../../../components/InputFields/RadioButton'
import PreviewDocument from '../PreviewDocument/PreviewDocument';
import {
    documentApi,
    useAddEditPartyDetailsMutation,
    useGenerateDocumentPaymentIdMutation,
    useGetDocumentFieldsByIdQuery,
    useGetDocumentPreviewByIdQuery,
    useGetPartyReviewersListQuery,
    useGetReviewPartyPriorityQuery,
    useInitateDocumentPaymentCorpBaMutation,
    useLazyDeletePartyDetailsQuery,
    useLazyGetDropdonwPartyDetailsQuery,
    useLazyGetTempReviewAndConsentQuery,
    useSaveDocumentMutation,
    useSaveDocumentPreviewMutation,
    useSendDocumentForReviewMutation,
    useUpdateDocumentPaymentStausMutation,
    useUploadDocumentHtmlTopdfMutation
} from '../../../../store/services/documentServices';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import SelectDropdown from '../../../../components/InputFields/SelectDropdown';
import {
    ALERT_MESSAGE,
    CONSENTMESSAGE,
    MESSAGE_CONFIRMATION,
    PREFFEREDSIGNMODEMESSAGE,
    PRIORITYMESSAGE,
    TOAST_MESSAGE
} from '../../../../constants/message';
import SelectTextField from '../../../../components/InputFields/SelectTextField';
import RadiowithTextfield from '../../../../components/InputFields/RadiowithTextfiled';
import PaymentAccord from '../components/Payment/PaymentAccord';
import {
    provideReviewerEntriesNoIndex,
    provideReviewersAccordingToTemplateFields,
    provideReviewersList,
    validateDocumentSection
} from '../../../../util/documentValidation';
import MenuProps from '../../../../StyledComponents/SelectStyles/SelectStyles';
// import { EXCEPTTHISSYMBOLS, ORDER_TYPE } from '../../../../constants/enum'
import { PLACEHOLDER } from '../../../../constants/placeholder';
import { toast } from 'react-toastify';
import { paymentInitialized } from '../../../../util/razorpayPayment';
import errormessages, { NOERROR } from '../../../../constants/errormessages';
import {
    DOCUEMENTFILTERSTATUS,
    PARTYTYPEOPTIONS,
    SIGNATUREMODES
} from '../../../../constants/documentConstans';
import {
    CLVALIDATIONS,
    DOCTYPE,
    IMPORTANTNOTES,
    ORDER_TYPE,
    PARTYTYPES,
    PATTERN,
    PAYMENT_TYPE,
    REVIEWCONSENTNOTES,
    ROLES
} from '../../../../constants/enum';
import numberToWords from 'number-to-words';
import { ToWords } from 'to-words';
import CurrencyTextField from '../../../../components/InputFields/CurrencyTextField';
import moment from 'moment/moment';
import { ProfileContext } from '../../../../contextapi/ProfileContext';
import SignatureModal from '../../../../components/SignatureModal/SignatureModal';
import { generateDocument } from '../../../../util/generateClDocument';
import palette from '../../../../theme/palette';
import { ADDUSEROPTIONSARRAYCOOWNER } from '../../../../constants/manageStaffUserConstants';
import {
    BUSINESSCONFIGURATION,
    CORPORATECONFIGURATION,
    INDIVIDUALCONFIGURATION
} from '../../../../constants/configurationConstant';
import NumberTextField from '../../../../components/InputFields/NumberTextField';
import CommonMessageModal from '../../../../components/commonMessageModal/CommonMessageModal';
import CommonMessageButtonModal from '../../../../components/commonMessageButtonModal/CommonMessageButtonModal';
import CustomTooltip from '../../../../components/Tooltip/CustomTooltip';
import TextAreafield from '../../../../components/InputFields/TextAreafield';
import PreviewDocumentModal from '../components/PreviewDocumentModal/PreviewDocumentModal';
import { generateStampPaper } from '../../../../util/stampPaperHelper';
import { STATE } from '../../../../constants/stateConstant';
import Scrollbars from 'react-custom-scrollbars-2';
import CheckboxWithText from '../../../../components/InputFields/CheckboxWithText';
import HowerTooltip from '../../../../components/HowerTooltip/HowerTooltip';
import DecimalTextField from '../../../../components/InputFields/DecimalTextField';
import TimePickerField from '../../../../components/InputFields/TimePickerField';
import { createDateString } from '../../../../util/helper';
import DropdownField from '../../../../components/InputFields/DropdownField';
import SelectDecimalField from '../../../../components/InputFields/SelectDecimalField';
import SelectNumberField from '../../../../components/InputFields/SelectNumberField';

// Accordian styles MUI
export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`
}));

// AccordionSummary styles MUI
export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<img src={rightarrow} alt="rightArrow" />} {...props} />
))(({ theme }) => ({
    // backgroundColor: '#F5F7F9',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

// AccordionDetails styles MUI
export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    background: 'white',
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

const CustomDocument = (props) => {
    const tooltipInitialState = {
        priorityTooltip: false,
        signTooltip: false,
        prefferedSignTooltip: false
    };

    // Profile data from context api
    const { profile } = useContext(ProfileContext);

    const signatureModalMessageState = {
        title: MESSAGE_CONFIRMATION?.SIGNMODALMESSAGE
    };

    // Signature option
    const signatureptions = [SIGNATUREMODES?.AADHAAR, SIGNATUREMODES?.DSC];

    // Load razorpay script
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const navigate = useNavigate();

    // state for accordian expand
    const [expanded, setExpanded] = useState(0);

    const [nextCount, setNextCount] = useState(0);

    const [currentSpan, setCurrentSpan] = useState(null);

    // Review and consent information info icon state
    const [anchorEl, setAnchorEl] = useState();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const reviewPopoverRef = useRef();

    const [witnessMandatory, setWitnessMandatory] = useState(false);
    const [currentSelectedReview, setCurrentSelectedReview] = useState({});
    const [witnessFields, setWitnessFields] = useState([]);
    const [partyRelatedSectionIndex, setPartyRelatedSectionIndex] = useState([]);
    const [totalTemplateReviewerObject, setTotalTemplateReviewerObject] = useState({});
    // Payment form
    const [paymentForm, setPaymentForm] = useState({
        documentCopies: 1,
        stampDuty: '',
        orderType: '',
        considerationPrice: '',
        storageType: 1,
        errors: {
            stampDuty: 'error',
            panCard: 'noerror'
        }
    });

    const [formheight, setFormHeight] = useState('1000px');

    const [selectedPriority, setSelectedPriority] = useState([]);
    const [editModePriority, setEditModePriority] = useState(null);

    const [addressList, setAddressList] = useState([]);
    const [sameAddressForAll, setSameAddressForAll] = useState(false);

    const [tooltipState, setTooltipState] = useState({
        priorityTooltip: false,
        signTooltip: false,
        prefferedSignTooltip: false
    });

    const [stampDutyInformation, setStampDutyInformation] = useState({
        stampDutyPaid: ''
    });

    // Handle pincode notes modal state
    const [notesModalState, setNotesModalState] = useState({
        open: false,
        title: ALERT_MESSAGE?.CLERRORTITLE,
        message: '',
        buttonText: 'Continue'
    });

    // Handle Digital signature selection notes modal open
    const [digitalSignNotesModalState, setDigitalSignNotesModalState] = useState({
        open: false,
        title: ALERT_MESSAGE?.DIGITALSIGNERRORMODALTITLE,
        message: MESSAGE_CONFIRMATION?.DIGITALSIGNCONFIRMMESSAGE,
        cancelButtonText: 'Cancel',
        submitButtonText: 'Ok'
    });

    // Signature modal data state
    const [signatureModalDataState, setSignatureModalDataState] = useState({});

    // Button Disable State for proceed to consent form
    const [isProceedConsentButtonDisabled, setIsProceedConsentButtonDisabled] = useState(true);

    const [previewDocumentModalState, setPreviewDocumentModalState] = useState({
        open: false
    });

    const [backdropState, setBackdropState] = useState(false);

    const [signatureModalState, setSignatureModalState] = useState(false);

    // Document Id by params
    const { documentId } = useParams();

    const { data: { types: partyTypeArray = [], priority: priorityList = [] } = {} } =
        useGetReviewPartyPriorityQuery({ documentId });

    // Get document sections and fields
    const { data: documentDataResponse, isSuccess } = useGetDocumentFieldsByIdQuery(documentId, {
        skip: !documentId
    });

    const [disableNameField, setDisableNameField] = useState(false);

    // Get Temporary Reviewers if the document is copied
    // Get temporary review and consent entries
    const [getTempReviewAndConsent] = useLazyGetTempReviewAndConsentQuery();

    // Get html content by document
    const { data: documentPreview = { content: '<div>Loading</div>' } } =
        useGetDocumentPreviewByIdQuery(documentId, {
            skip: !documentId
        });

    // Save Document as a draft
    const [saveDocumentField] = useSaveDocumentMutation();

    // Save Document Preview as a draft
    const [saveDocumentPreview] = useSaveDocumentPreviewMutation();

    // Generate razorpay orderId
    const [generateDocumentRazPaymentID] = useGenerateDocumentPaymentIdMutation({});

    // Send document for review
    const [sendDocumentForReview] = useSendDocumentForReviewMutation({});

    // Update document payment status
    const [updateDocumentPaymentStatus] = useUpdateDocumentPaymentStausMutation({});

    // Update document payment status  for BA or Corporation
    const [intializeDocumentPaymentCorpBa] = useInitateDocumentPaymentCorpBaMutation({});

    // Upload document pdf on successfull payment
    const [uploadDocumentPdf] = useUploadDocumentHtmlTopdfMutation();

    // Add/ Edit Reviewers
    const [addEditReviewers] = useAddEditPartyDetailsMutation();

    // api method to call when party type chnages
    const [partyTypeDetails] = useLazyGetDropdonwPartyDetailsQuery();

    // Delete method to delete partyDetails
    const [deletePartyDetails] = useLazyDeletePartyDetailsQuery();

    // All added reviewer State array
    const [documentReviewer, setDocumentReviewer] = useState([]);

    // Get party Reviewers list
    const {
        data: partyReviewersList,
        isSuccess: isProcessingCompleted,
        refetch
    } = useGetPartyReviewersListQuery(
        { documentId },
        {
            skip: !documentId
        }
    );

    /**
     * Function to add reviewers to the reviewer list according to template fields
     */
    function addReviewerAccordigToTemplateFields(updatedTotalTemplateReviewerObject, index) {
        if (
            updatedTotalTemplateReviewerObject &&
            Object.keys(updatedTotalTemplateReviewerObject).length > 0
        ) {
            let reviewerEntries = [];
            if (index !== undefined) {
                console.log('I am here INDEX====>', index);
                // call a util dunction to return a list of document reviewwer list according to fields in the template and add them to reviewer list with error
                reviewerEntries = provideReviewersList(
                    updatedTotalTemplateReviewerObject,
                    partyTypeArray,
                    documentReviewer,
                    index
                );
            } else {
                console.log('I am here INDEX====>', index);
                reviewerEntries = provideReviewerEntriesNoIndex(
                    updatedTotalTemplateReviewerObject,
                    partyTypeArray,
                    documentReviewer
                );
            }
            console.log('REVIEWER ENTRIES====>>>>>>', reviewerEntries);
            if (documentDataResponse?.articleTemplateName.includes('Affidavit')) {
                // Check for first party reviewer
                const firstPartyReviewer = reviewerEntries?.find(
                    (reviewer) => reviewer.partyType.name === 'First Party'
                );
                if (firstPartyReviewer) {
                    firstPartyReviewer.priority = 1; // Update priority directly
                }
            }
            setDocumentReviewer(reviewerEntries);
            handleCheckAllPartiesSubmitted(reviewerEntries);
        }
    }

    // Preview section box dynamic height and set document Preview array
    useEffect(() => {
        if (isSuccess) {
            const el = document.getElementById('formArea');
            let elHeight = el.offsetHeight;
            elHeight += parseInt(window.getComputedStyle(el).getPropertyValue('margin-top'));
            elHeight += parseInt(window.getComputedStyle(el).getPropertyValue('margin-bottom'));
            setFormHeight(elHeight);
            setDocumentReviewer(documentDataResponse?.review);

            if (documentDataResponse?.status?.id === DOCUEMENTFILTERSTATUS?.INDRAFT?.ID) {
                setExpanded(documentDataResponse?.index);
            }
            if (documentDataResponse?.status?.id === DOCUEMENTFILTERSTATUS?.WAITINGFORPAYMENT?.ID) {
                setExpanded(documentDataResponse?.sections?.length + 2);
            }
            if (documentDataResponse?.isCopy === true) {
                getTempReviewerCopyDocument();
            }
            if (documentDataResponse?.witnessFilled) {
                setWitnessMandatory(true);
            }

            if (documentDataResponse?.tempReviewData) {
                console.log(
                    'totalTemplateReviewerObject>>>>>>>',
                    totalTemplateReviewerObject,
                    documentDataResponse?.tempReviewData
                );
                setTotalTemplateReviewerObject(JSON.parse(documentDataResponse?.tempReviewData));
                // addReviewerAccordigToTemplateFields(
                //     JSON.parse(documentDataResponse?.tempReviewData)
                // );
            }
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccess && documentDataResponse?.witnessFilled) {
            documentDataResponse?.witnessIds?.map((id) => {
                setWitnessFields([...witnessFields, parseInt(id)]);
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isProcessingCompleted && partyReviewersList) {
            console.log('ADDING ITEMS TO LIST===>');
            setDocumentReviewer(partyReviewersList);
            const priorityArray = [];
            partyReviewersList?.map((item) => priorityArray.push(item.priority));
            setSelectedPriority(priorityArray);
            handleCheckAllPartiesSubmitted(partyReviewersList, witnessFields);
        }
    }, [partyReviewersList, isProcessingCompleted, documentReviewer]);

    // Except symbol on number field
    // const exceptThisSymbols = EXCEPTTHISSYMBOLS

    // Success Modal state
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    const [documentForm, setDocumentForm] = useState({
        form: {},
        errors: {}
    });

    // Extra required Document fields
    const [documentFormExtraField, setDocumentFormExtraField] = useState({});

    // Alert modal state
    const [openAlertModal, setOpenAlertModal] = useState(false);

    // delete Alert modal state
    const [openDeleteAlertModal, setOpenDeleteAlertModal] = useState(false);

    // Review form state
    const [reviewForm, setReviewForm] = useState({
        partyType: '',
        name: '',
        address: '',
        priority: '',
        consent: false,
        eSign: false,
        signMode: 2,
        errors: {
            email: 'error'
        }
    });

    // Document configuration for corporate and business staff users
    const documentConfiguration =
        profile?.role === ROLES?.CORPORATE
            ? CORPORATECONFIGURATION[`${profile?.subRole}`]
            : profile?.role === ROLES?.INDIVIDUAL
              ? INDIVIDUALCONFIGURATION[`${profile?.subRole}`]
              : BUSINESSCONFIGURATION[`${profile?.subRole}`];

    // Alert Modal description
    const alertModalState = {
        title: 'Caution',
        message: ALERT_MESSAGE.CREATEDOCUMENT,
        cancelButtonText: 'No i want to recheck',
        submitButtonText: 'I agree'
    };

    //Delete  Alert Modal description
    const deleteAlertModalState = {
        title: 'Caution',
        message: ALERT_MESSAGE.DELETEREVIEW,
        cancelButtonText: 'No',
        submitButtonText: 'Yes'
    };

    /**
     * Handle open review consent info icon popover
     * @param {object} event: Event object
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Handle close info icon popoover
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Receiving reviewers in copy of document, setPriority and add data to reviewer function
    const getTempReviewerCopyDocument = () => {
        setBackdropState(true);
        getTempReviewAndConsent(documentId)
            .unwrap()
            .then((response) => {
                setBackdropState(false);
                setDocumentReviewer(response?.data);
                const priorityArray = [];
                response?.data?.map((item) => priorityArray.push(item.priority));
                setSelectedPriority(priorityArray);
                handleCheckAllPartiesSubmitted(response?.data);
            })
            .catch((execption) => {
                setBackdropState(false);

                console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption);
            });
    };

    // Check conditions based on Party type [Single or multiple] to determine Proceed button disabled state
    // if (documentDataResponse?.partyType?.id === PARTYTYPEOPTIONS?.MULTIPLEPARTY?.id) {
    //   const partiesToCheck = [PARTYTYPES?.FIRSTPARTY, PARTYTYPES?.SECONDPARTY]
    //   const areIdsPresent = partiesToCheck.every((idToCheck) =>
    //     documentReviewer.some((obj) => obj.id === idToCheck)
    //   )
    //   setIsProceedConsentButtonDisabled(!areIdsPresent)
    // } else if (documentDataResponse?.partyType?.id === PARTYTYPEOPTIONS?.SINGLEPARTY?.id) {
    //   const partiesToCheck = [PARTYTYPES?.FIRSTPARTY, PARTYTYPES?.SECONDPARTY]
    //   const isId1Present = documentReviewer.some((obj) => obj.id === 1 || 2)
    //   setIsProceedConsentButtonDisabled(!isId1Present)
    // }

    /**
     * Handle form changes , if value is empty replace span with dash(_____)
     * @param {object} event :event object
     */
    const handleDocumentFormChange = (event, fieldData, sectionIndex) => {
        if (fieldData?.type === 'currency') {
            return handleDocumentFormCurrencyChange(event, fieldData);
        }
        setDocumentForm((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                [fieldData?.id]: event.target.value.replace(/\s/gy, '')
            }
        }));

        // Add Extra fields value required if docField type is present
        if (fieldData?.docField) {
            setDocumentFormExtraField((prev) => ({
                ...prev,
                [fieldData?.docField.toLowerCase()]: event.target.value.replace(/\s/gy, '')
            }));
        }

        if (fieldData?.props?.multiple) {
            const docPreviewSpanEle = document.querySelectorAll(`[id=${event.target.name}]`);
            docPreviewSpanEle.forEach((element) => {
                if (element && !event.target.value) element.textContent = '__________';
                else if (element && event.target.value) {
                    if (fieldData?.type === 'date_picker') {
                        const date = moment(event.target.value).format('DD-MM-YYYY');
                        element.textContent = date;
                    } else if (fieldData?.type === 'string_date') {
                        console.log(
                            'I AM HERE=====> Setting Date value',
                            fieldData?.props?.dependent
                        );
                        const date = moment(event.target.value).format('DD-MM-YYYY');
                        // Need to show date (19-11-2024) as 19th day of November,2024
                        const stringDate = createDateString(date);
                        element.textContent = stringDate;
                        // Check dependent contains data or not , this is the case where multiple spans need same date
                        // value but in different format so we check dependent and use the id inside it to fill the date value to it
                        if (fieldData?.props?.dependent) {
                            let dependent = JSON.parse(fieldData?.props?.dependent);
                            const element = document.querySelectorAll(`[id=span${dependent?.id}]`);
                            element.forEach((dependentElements) => {
                                dependentElements.textContent = date;
                            });
                        }
                    } else {
                        element.textContent = event.target.value;
                    }
                }
            });
        } else {
            const docPreviewSpanEle = document.getElementById(event.target.name);
            if (docPreviewSpanEle && !event.target.value) docPreviewSpanEle.textContent = '_____';
            else if (docPreviewSpanEle && event.target.value) {
                if (fieldData?.type === 'date_picker') {
                    const date = moment(event.target.value).format('DD-MM-YYYY');
                    docPreviewSpanEle.textContent = date;
                } else if (fieldData?.type === 'string_date') {
                    const date = moment(event.target.value).format('DD-MM-YYYY');
                    // Need to show date (19-11-2024) as 19th day of November,2024
                    const stringDate = createDateString(date);
                    docPreviewSpanEle.textContent = stringDate;
                } else {
                    docPreviewSpanEle.textContent = event.target.value;
                }
            }
        }

        // FOr multiple span elements in preview
        // const docPreviewSpanEle = document.querySelectorAll(`[id=${event.target.name}]`)
        // docPreviewSpanEle.forEach((element) => {
        //   if (!event.target.value) element.textContent = '__________'
        //   else element.textContent = event.target.value
        // })
        if (fieldData?.witnessField && event?.target?.value == '') {
            const updatedFields = witnessFields.filter((id) => id !== fieldData?.id);
            console.log('UpdateFields===>>>>', updatedFields);
            setWitnessFields(updatedFields);
            handleCheckAllPartiesSubmitted(documentReviewer, updatedFields);
        } else if (fieldData?.witnessField && event.target.value !== '') {
            // Add the id only if it's not already in the array to avoid duplicates
            // Add fieldData.id to witnessFields if it's not already there
            if (!witnessFields.includes(fieldData?.id)) {
                const updatedWitnessFields = [...witnessFields, fieldData?.id];
                setWitnessFields(updatedWitnessFields);
                handleCheckAllPartiesSubmitted(documentReviewer, updatedWitnessFields);
            } else {
                handleCheckAllPartiesSubmitted(documentReviewer, witnessFields);
            }
        }
        //  This code is for creating reviewer entries if the fields are related to the party types in the review consent party dropdown
        if (fieldData?.fieldType !== null) {
            // Keeping note of all the section indexes for which values are added in the object
            setPartyRelatedSectionIndex([...partyRelatedSectionIndex, sectionIndex]);
            // we check fieldType to know that this section fields are related to review consent parties and are to be included below
            setTotalTemplateReviewerObject((prev) => ({
                ...prev,
                [fieldData.sectionType]: {
                    ...prev[fieldData.sectionType],
                    [fieldData?.fieldType]: event.target.value,
                    id: prev[fieldData.sectionType]?.id || new Date().getTime(),
                    index: sectionIndex
                }
            }));
        }
    };

    const handleDocumentFormCheckboxChange = (event, fieldData) => {
        hideContentFromTemplate(event, fieldData);
        setDocumentForm((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                [fieldData?.id]: event.target.checked
            }
        }));
    };

    const handleDropdownChange = (fieldData, selectedOption) => {
        // hide the span received in the selected option id
        /*
            1.) Find the dependent fields from the particular section in the selected option dependent parameter
            2.) Change the disabled state of the subFields according to the option selected from the dropdown
        */
        const fieldsToEnable = documentDataResponse?.sections[expanded].fields
            ?.flatMap((field) => field?.subFields)
            .filter((subField) => selectedOption?.dependent?.includes(subField?.id));

        const fieldsToDisable = documentDataResponse?.sections[expanded].fields
            ?.flatMap((field) => field?.subFields)
            .filter((subField) => !selectedOption?.dependent?.includes(subField?.id));

        console.log('FIELDS TO DISABLE===>', fieldsToDisable);
        // updating
        setDocumentForm((prev) => {
            const updatedDisableState = {};
            const updatedForm = { ...prev.form };

            fieldsToDisable.forEach((field) => {
                updatedForm[field?.id] = '';
                clearElement(field?.id);
            });

            fieldsToEnable.forEach((field) => {
                updatedDisableState[field?.id] = false;
            });
            updatedForm[fieldData?.id] = selectedOption?.id;

            return {
                ...prev,
                form: updatedForm,
                disabledState: updatedDisableState
            };
        });

        hideContentFromTemplateUsingDropdownSelection(selectedOption);
    };

    const clearElement = (fieldId) => {
        const targetElements = document?.querySelectorAll(`[id=span${fieldId}]`);

        if (targetElements) {
            // targetElements.textContent = '_______';
            targetElements?.forEach((element) => {
                element.textContent = '________';
            });
        }
    };

    const handleDocumentTimePickerChange = (event, fieldData, sectionIndex) => {
        // Check if event is null or event?.$d is undefined, which means the time field is cleared
        const time12 = event ? moment(event.$d).format('hh:mm A') : '';
        setDocumentForm((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                [fieldData?.id]: time12
            }
        }));

        // Update the preview element
        const docPreviewSpanEle = document.getElementById(`span${fieldData?.id}`);
        if (docPreviewSpanEle) {
            docPreviewSpanEle.textContent = time12 || '_____';
        }
    };

    /**
     * Add Class on focus to input field(highlight)
     * @param {objects} e :Event object
     * @param {string} name : Optional paramater (used for select dropdown)
     */
    const addHighlightClass = (event, fieldData = '') => {
        // const parentDiv = document.getElementById('previewDocument')
        const targetSpanElement = document.getElementById(`span${fieldData?.id}`);
        if (targetSpanElement && !targetSpanElement.classList.contains('highlightField')) {
            targetSpanElement.className += 'highlightField';
        }
        if (targetSpanElement) {
            targetSpanElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
                inline: 'nearest'
            });
        }

        // const targetSpanElements = parentDiv.querySelectorAll(`[id=${e.target.name}]`)
        // targetSpanElements.forEach((element) => {
        //   if (element && !element.classList.contains('highlightField')) {
        //     element.className += 'highlightField'
        //   }
        // })

        // if (targetSpanElements.length) {
        //   targetSpanElements[0].scrollIntoView({
        //     block: 'nearest',
        //     behavior: 'smooth',
        //     inline: 'nearest'
        //   })
        // }
    };

    const hideContentFromTemplate = (event, fieldData) => {
        console.log('im focus', event.target.checked, fieldData);
        // const parentDiv = document.getElementById('previewDocument')
        const targetSpanElement = document.getElementById(`span${fieldData?.id}`);
        if (targetSpanElement) {
            targetSpanElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
                inline: 'nearest'
            });
        }

        if (!event.target.checked) {
            if (targetSpanElement && !targetSpanElement.classList.contains('hidden')) {
                targetSpanElement.className += 'hidden';
            }
        } else {
            if (targetSpanElement && !targetSpanElement.classList.contains('hidden')) {
                targetSpanElement.className += 'hidden';
            } else {
                targetSpanElement.classList.remove('hidden');
            }
        }
    };

    const hideContentFromTemplateUsingDropdownSelection = (selectedOption) => {
        const targetSpanElement = document.getElementById(`span${selectedOption?.id}`);
        // check current span when changing dropdown options
        if (currentSpan && currentSpan !== targetSpanElement) {
            currentSpan?.setAttribute('hidden', 'hidden');
        }
        if (targetSpanElement) {
            targetSpanElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
                inline: 'nearest'
            });
        }
        targetSpanElement?.removeAttribute('hidden');
        setCurrentSpan(targetSpanElement);
    };

    /**
     * Handle form currency field changes , if value is empty replace span with dash(_____)
     * @param {object} event :event object
     */
    const handleDocumentFormCurrencyChange = (event, fieldData) => {
        const numberValue = event.target.value.replaceAll(',', '');
        const currency = Number(numberValue).toLocaleString('en-In');
        setDocumentForm((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                [fieldData?.id]: currency === '0' ? '' : currency
            }
        }));

        const docPreviewSpanEle = document.querySelectorAll(`[id=${event.target.name}]`);
        console.log('SPAN ID====>', document.querySelectorAll(`[id=${event.target.name}]`));
        docPreviewSpanEle.forEach((element) => {
            if (element && !event.target.value) element.textContent = '_____';
            else if (element && event.target.value) {
                const toWords = new ToWords({
                    converterOptions: {
                        doNotAddOnly: false
                    }
                });
                console.log('Number', event.target.value);
                const numberInWords = toWords.convert(Number(numberValue), { currency: true });
                const combinedCurrency = `${currency}, (${numberInWords})`;
                element.textContent = combinedCurrency;
            }
        });

        console.log('DOCUMENTFORM43', documentForm);
    };

    /**
     * Handle accordian expand state
     * @param {*} panel
     * documentDataResponse?.sections?.length == Review consent accordian Index
     * documentDataResponse?.sections?.length + 1 == Stamp duty accordian Index
     * documentDataResponse?.sections?.length + 2 == Payment accordian Index
     */
    const handleExpandChange = (panel) => (event, newExpanded) => {
        console.log('ON REV CONSENT ACCORD===>', panel);
        if (nextCount === documentDataResponse?.sections?.length) {
            // const prevExpandAcord = expanded
            if (panel === documentDataResponse?.sections?.length + 2) {
                if (
                    documentDataResponse?.status?.id ===
                    DOCUEMENTFILTERSTATUS?.WAITINGFORPAYMENT?.ID
                ) {
                    setExpanded(newExpanded ? panel : panel);
                }
            } else {
                setExpanded(newExpanded ? panel : 0);
                // Open review consent popover
                if (panel === documentDataResponse?.sections?.length && newExpanded) {
                    setTimeout(() => {
                        setAnchorEl(reviewPopoverRef.current);
                    }, 1000);
                }
            }
        }
    };

    const handleExpandStampDutyAccord = (panel) => (event, newExpanded) => {
        const checkMissingPriority = handleCheckMissingPriority();
        if (!isProceedConsentButtonDisabled) {
            if (checkMissingPriority) {
                toast.error(errormessages?.REVIEWPRIORITYMISSING);
            } else {
                setExpanded(newExpanded ? panel : 0);
            }
        } else {
            toast.error(errormessages?.DOCUMENTREVIEWRMINREQUIRED);
        }
    };

    // Handle accordian Arrow expand state
    const handleExpandArrow = (currentSection) => {
        console.log('currentSection', currentSection, expanded, witnessFields);
        if (currentSection < expanded) {
            setExpanded(currentSection);
        }
    };

    // Expand Previous accordian
    const handleExpandPrev = (currentSection) => {
        setExpanded(currentSection - 1);
    };

    // Expand Previous accordian
    const handleExpandAccorNext = (index) => {
        setExpanded(index);
    };

    /**
     * Handle next button  accordian state and check the validations and submit document draft
     * @param {number} index :current accordian open index
     */
    const handleNextAccord = async (index) => {
        const validArray = await validateDocumentSection(
            documentDataResponse.sections[index]?.fields,
            documentForm.form
        );
        if (validArray?.length > 0) {
            const errobj = {};
            validArray.map((error) => (errobj[`${error.fieldId}`] = error.errorMessage));
            setDocumentForm((prev) => ({
                ...prev,
                errors: errobj
            }));
        } else {
            setDocumentForm((prev) => ({
                ...prev,
                errors: {}
            }));
            setExpanded(index + 1);
            setNextCount(nextCount + 1);

            const documentDraftData = [];
            for (const [key, value] of Object.entries(documentForm?.form)) {
                documentDraftData.push({
                    fieldId: Number(key),
                    fieldValue: value
                });
            }
            updateDocumentDraftNew(documentDraftData, index);
            // Open Popover if next is review consent accordian
            if (index + 1 === documentDataResponse?.sections?.length) {
                setAnchorEl(reviewPopoverRef.current);
            }
        }
    };

    const updateDocumentDraftNew = (documentDraftData, index) => {
        const preview = document.getElementById('previewDocument');

        const dataToSendPreview = {
            preview: preview.innerHTML
        };
        let autoFillDataRequest = [];

        Object.keys(totalTemplateReviewerObject).forEach((partyType) => {
            const partyDetails = totalTemplateReviewerObject[partyType];

            autoFillDataRequest.push({
                email: partyDetails?.EMAIL || '',
                name: partyDetails?.NAME || '',
                esign: false,
                consent: false,
                phone: '',
                address: partyDetails?.ADDRESS || '',
                partyType: partyType.replace(/_/g, ' ').toLowerCase(),
                signMode: partyDetails.signMode || 2,
                documentId: documentId,
                frontendId: partyDetails?.id || '',
                entryType: 'ADD'
            });
        });
        // add accordian index here
        const dataToSendField = {
            fields: documentDraftData,
            extraFields: documentFormExtraField,
            index: index + 1,
            witnessFilled: witnessFields?.length > 0,
            witnessIds: witnessFields,
            reviewData: JSON.stringify(totalTemplateReviewerObject),
            autofillDetailsRequest: autoFillDataRequest
        };
        saveDocumentField({ payload: dataToSendField, documentId })
            .unwrap()
            .then((response) => {
                // Solving  the issue in cases  the  updated html template is not saved
                saveDocumentPreview({ payload: dataToSendPreview, documentId })
                    .unwrap()
                    .then((response) => {})
                    .catch((exception) => {
                        console.log('EXCEPTION:DOCUMENT Preview DRAFT', exception);
                    });
            })
            .catch((exception) => {
                console.log('EXCEPTION:DOCUMENT field SAVE DRAFT', exception);
            });
    };

    /**
     * Handle Review form changes
     * @param {object} event :event object
     */
    const handleReviewForm = (event) => {
        // need to create a new record in document reviewer if party type is selected, therefore clearing the form when witness is selected in case of affidavit
        // if (
        //     event.target.name === 'partyType' &&
        //     documentDataResponse?.articleTemplateName?.includes('Affidavit') &&
        //     event.target.value.name === 'Witness'
        // ) {
        //     setDisableNameField(false);
        //     setReviewForm({
        //         id: '',
        //         name: '',
        //         address: '',
        //         partyType: event.target.value,
        //         priority: documentReviewer?.length + 1,
        //         consent: false,
        //         eSign: false,
        //         signMode: 2,
        //         phone: '',
        //         email: '',
        //         errors: {
        //             email: 'error'
        //         }
        //     });
        //     return;
        // }
        // // if party type and template name === affidavit and review contains the party type fill those values in  review form
        // if (
        //     event.target.name === 'partyType' &&
        //     documentDataResponse?.articleTemplateName?.includes('Affidavit') &&
        //     event.target.value.name === 'First Party'
        // ) {
        //     // Check for first party reviewer
        //     const firstPartyReviewer = documentReviewer?.find(
        //         (reviewer) => reviewer.partyType.name === 'First Party'
        //     );
        //     if (firstPartyReviewer) {
        //         setReviewForm({
        //             id: firstPartyReviewer?.id,
        //             name: firstPartyReviewer?.name,
        //             address: firstPartyReviewer?.address,
        //             partyType: firstPartyReviewer?.partyType,
        //             priority: firstPartyReviewer?.priority || 1,
        //             consent: firstPartyReviewer?.consent,
        //             eSign: firstPartyReviewer?.eSign,
        //             signMode: firstPartyReviewer?.signMode,
        //             email: firstPartyReviewer?.email,
        //             phone: firstPartyReviewer?.phone,
        //             errors: {
        //                 email: 'noerror'
        //             }
        //         });
        //         setDisableNameField(true);
        //     } else {
        //         // This case arises whenever from the review consent table the first party record is deleted and first party is again selected from dropdown
        //         setDisableNameField(false);
        //         setReviewForm((prev) => ({
        //             ...prev,
        //             [event.target.name]: event.target.value,
        //             priority: 1
        //         }));
        //     }
        //     return;
        // }

        setReviewForm((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    };

    const handlePartyTypeChange = (event) => {
        // Reset the priority of the previously edited reviewer if any
        if (editModePriority !== null) {
            setSelectedPriority((prev) => {
                // Prevent duplicates in the selected priority array
                if (!prev.includes(editModePriority)) {
                    return [...prev, editModePriority];
                }
                return prev;
            });
            setEditModePriority(null);
        }

        setBackdropState(true);
        const dataToSend = {
            documentId,
            partyId: event.target.value?.id
        };
        partyTypeDetails({ params: dataToSend, error: 'NOERROR' })
            .unwrap()
            .then((response) => {
                setBackdropState(false);

                // update the review form now
                const updateReviewFormDetails = {
                    partyType: event.target.value,
                    email: response?.email || '',
                    name: response?.name,
                    address: response?.address,
                    priority: response?.priority || '',
                    phone: response?.phone,
                    signMode: response?.signMode,
                    eSign: response?.esign,
                    id: response?.frontendId,
                    errors: {
                        email: 'noerror'
                    }
                };
                if (response?.name !== '' && event.target.value?.name === 'First Party') {
                    handleNameField();
                } else {
                    setDisableNameField(false);
                }
                setReviewForm(updateReviewFormDetails);
            })
            .catch((execption) => {
                setBackdropState(false);
            });
    };

    const handleNameField = () => {
        if (documentDataResponse?.articleTemplateName?.includes('Affidavit')) {
            setDisableNameField(true);
        }
    };

    /**
     * Handle Review form Email changes
     * @param {object} event :event object
     */
    const handleReviewFormEmail = (event) => {
        const err = validator(event.target.name, event.target.value);
        setReviewForm((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
            errors: {
                email: err
            }
        }));
        console.log(reviewForm);
    };

    /**
     * Handle Review form Sign Mode changes
     * @param {object} event :event object
     */
    const handleReviewFormSignModeChange = (event) => {
        setReviewForm((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
        console.log(event.target.value, SIGNATUREMODES?.DSC?.id);
        if (Number(event.target.value) === SIGNATUREMODES?.DSC?.id) {
            setDigitalSignNotesModalState((prev) => ({
                ...prev,
                open: true
            }));
        }
        console.log(reviewForm);
    };

    /**
     * Handle StampDutyInformation form changes
     * @param {object} event :event object
     */
    const handleStampDutyInformation = (event) => {
        setStampDutyInformation((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
        console.log(reviewForm);
    };

    /**
     * Handle Review form checkbox changes
     * @param {object} event :event object
     */
    const handleReviewFormCheckbox = (event) => {
        if (event.target.name === 'consent' && event.target.checked) {
            setReviewForm((prev) => ({
                ...prev,
                [event.target.name]: event.target.checked,
                eSign: true
            }));
        } else {
            setReviewForm((prev) => ({
                ...prev,
                [event.target.name]: event.target.checked
            }));
        }
    };

    // Add reviewer in document Reviewer Array
    const handleAddReviewer = () => {
        // if (documentDataResponse?.partyType?.id === PARTYTYPEOPTIONS?.MULTIPLEPARTY?.id) {
        //   console.log('in')
        // }
        const alreadyExist = documentReviewer.find(
            (item) =>
                item.email.toLowerCase() === reviewForm?.email.toLowerCase() &&
                item?.id !== reviewForm?.id
        );
        if (alreadyExist) {
            toast.error(errormessages?.EMAILPRESENT);
        } else if (!(reviewForm?.consent || reviewForm?.eSign)) {
            toast.error(errormessages?.CONSENTREQUIRED);
        } else {
            console.log('reviewFormreviewForm', reviewForm);
            if (reviewForm?.errors?.email === 'noerror') {
                const reviewUserDetails = {
                    id: reviewForm?.id || new Date().getTime(),
                    partyType: reviewForm?.partyType,
                    name: reviewForm?.name,
                    address: reviewForm?.address,
                    priority: reviewForm?.priority,
                    consent: reviewForm?.consent,
                    eSign: reviewForm?.eSign,
                    email: reviewForm?.email,
                    phone: reviewForm?.phone,
                    signMode: Number(reviewForm?.signMode),
                    isError: false
                };
                // check if we editing an existing reviewer
                const reviewerIndex = documentReviewer?.findIndex(
                    (item) => item?.id === reviewForm?.id
                );
                let updatedReviewers;

                if (reviewerIndex >= 0) {
                    // Edit the existing reviewer
                    updatedReviewers = documentReviewer.map((item, index) =>
                        index === reviewerIndex ? { ...reviewUserDetails } : item
                    );
                } else {
                    updatedReviewers = [...documentReviewer, { ...reviewUserDetails }];
                }
                setDocumentReviewer(updatedReviewers);

                if (
                    documentDataResponse?.articleTemplateName.includes('Affidavit') &&
                    reviewUserDetails?.partyType?.name === 'First Party'
                ) {
                    setReviewForm({
                        name: '',
                        address: '',
                        partyType: '',
                        priority: 2,
                        consent: false,
                        eSign: false,
                        signMode: 2,
                        phone: '',
                        email: '',
                        errors: {
                            email: 'error'
                        }
                    });
                } else {
                    setReviewForm({
                        name: '',
                        address: '',
                        partyType: '',
                        priority: '',
                        consent: false,
                        eSign: false,
                        signMode: 2,
                        phone: '',
                        email: '',
                        errors: {
                            email: 'error'
                        }
                    });
                }
                setSelectedPriority([...selectedPriority, reviewForm?.priority]);
                // Reset edit mode priority
                setEditModePriority(null);
                // Check conditions based on Party type [Single or multiple] to determine Proceed button disabled state
                handleCheckAllPartiesSubmitted(updatedReviewers, witnessFields);
            }
            // setDigitalSignNotesModalState((prev) => ({
            //   ...prev,
            //   open: true
            // }))
        }
    };

    const handleAddReviewerNew = () => {
        const alreadyExist = documentReviewer.find(
            (item) =>
                item.email.toLowerCase() === reviewForm?.email.toLowerCase() &&
                item?.frontendId !== reviewForm?.id
        );

        if (alreadyExist) {
            toast.error(errormessages?.EMAILPRESENT);
        } else if (!(reviewForm?.consent || reviewForm?.eSign)) {
            toast.error(errormessages?.CONSENTREQUIRED);
        } else if (reviewForm?.errors?.email === 'noerror') {
            const reviewUserDetails = {
                frontendId: reviewForm?.id || new Date().getTime(),
                email: reviewForm?.email,
                name: reviewForm?.name,
                priority: reviewForm?.priority,
                eSign: reviewForm?.eSign,
                consent: reviewForm?.consent || false,
                phone: reviewForm?.phone,
                address: reviewForm?.address,
                partyType: reviewForm?.partyType?.name.toLowerCase(),
                partyTypeId: reviewForm?.partyType?.id,
                signMode: Number(reviewForm?.signMode),
                documentId: documentId
            };
            setBackdropState(true);
            addEditReviewers({ payload: reviewUserDetails })
                .unwrap()
                .then((response) => {
                    setReviewForm({
                        id: '',
                        name: '',
                        address: '',
                        partyType: '',
                        priority: '',
                        consent: false,
                        eSign: false,
                        signMode: 2,
                        phone: '',
                        email: '',
                        errors: {
                            email: 'error'
                        }
                    });
                    setBackdropState(false);
                    setSelectedPriority([...selectedPriority, reviewForm?.priority]);
                    // Reset edit mode priority
                    setEditModePriority(null);
                    refetch();
                })
                .catch((exception) => {
                    setBackdropState(false);
                    console.log('EXCEPTION:DOCUMENT field SAVE DRAFT', exception);
                });
        }
    };

    /**
     * Handle check that all the required parties are added in the reviewer list or not (Multiple party, Single Party)
     * @param {Array} documentReviewerList: All added document reviwer list
     */
    const handleCheckAllPartiesSubmitted = (documentReviewerList, currentWitnessFields) => {
        if (documentDataResponse?.partyType?.id === PARTYTYPEOPTIONS?.MULTIPLEPARTY?.id) {
            const isWitnessMandatory = currentWitnessFields?.length > 0;
            const partiesToCheck = [
                PARTYTYPES?.FIRSTPARTY?.ID,
                PARTYTYPES?.SECONDPARTY?.ID,
                ...(isWitnessMandatory ? [PARTYTYPES?.WITNESS?.ID] : [])
            ];
            const areIdsPresent = partiesToCheck.every((idToCheck) =>
                documentReviewerList?.some((obj) => obj?.partyType?.id === idToCheck)
            );

            setIsProceedConsentButtonDisabled(!areIdsPresent);
        } else if (documentDataResponse?.partyType?.id === PARTYTYPEOPTIONS?.SINGLEPARTY?.id) {
            const isWitnessMandatory = currentWitnessFields?.length > 0;
            const partiesToCheck = [
                PARTYTYPES?.FIRSTPARTY?.ID,
                PARTYTYPES?.SECONDPARTY?.ID,
                ...(isWitnessMandatory ? [PARTYTYPES?.WITNESS?.ID] : [])
            ];
            const isIdPresent = documentReviewerList.some((obj) =>
                partiesToCheck.includes(obj?.partyType?.id)
            );
            const isWitnessPresent = documentReviewerList.some(
                (obj) => obj?.partyType?.id === PARTYTYPES?.WITNESS?.ID
            );
            const hasErrors = documentReviewerList.some((obj) => obj.isError === true);
            setIsProceedConsentButtonDisabled(
                !isIdPresent || (isWitnessMandatory && !isWitnessPresent)
            );
        }
    };

    // Handle Close Digital sign notes modal and reset the sign model to e-sign
    const handleCancelDigitalSignNotesModal = () => {
        setReviewForm((prev) => ({
            ...prev,
            signMode: String(SIGNATUREMODES?.AADHAAR?.id)
        }));
        setDigitalSignNotesModalState((prev) => ({
            ...prev,
            open: false
        }));
    };

    /**
     * Handle Delete Reviewer and check for the proceed stamp duty button disabled
     * @param {object} reviewer:selected data
     */
    const handleDeleteReviewer = (reviewer) => {
        console.log('here is reviewer', reviewer);
        const dataToSend = {
            frontendId: reviewer?.frontendId
        };
        setBackdropState(true);
        deletePartyDetails({ params: dataToSend })
            .unwrap()
            .then((response) => {
                setBackdropState(false);
                refetch();
            })
            .catch((execption) => {
                setBackdropState(false);
            });
        setSelectedPriority(selectedPriority.filter((item) => item !== reviewer.priority));

        setOpenDeleteAlertModal(false);
        toast.success(TOAST_MESSAGE.REVIEW_USER_DELETED);
    };

    /**
     * Handle Edit Reviewer details
     * @param {object} reviewer:selected data
     */
    const handleEditReviewer = (reviewer) => {
        // Handle case where priority is not assigned (undefined or null)
        const hasPriority =
            reviewer?.priority !== undefined &&
            reviewer?.priority !== null &&
            reviewer?.priority !== '';
        // Reset the priority of the previously edited reviewer if any
        if (editModePriority !== null) {
            setSelectedPriority((prev) => {
                return [...prev, editModePriority];
            });
        }

        setReviewForm({
            id: reviewer?.frontendId,
            name: reviewer?.name,
            address: reviewer?.address,
            partyType: partyTypeArray?.find((item) => item.id === reviewer?.partyType?.id),
            priority: reviewer?.priority,
            consent: reviewer?.consent,
            eSign: reviewer?.eSign,
            signMode: reviewer?.signMode,
            email: reviewer?.email,
            phone: reviewer?.phone,
            errors: {
                email: 'noerror'
            }
        });
        // If priority is assigned, remove it from the used priorities
        if (hasPriority) {
            setSelectedPriority((prev) => {
                return prev.filter((item) => item !== reviewer.priority);
            });
        }

        setEditModePriority(reviewer.priority);
    };

    // Check if there is missing priority or not
    const handleCheckMissingPriority = () => {
        const largestNumber = Math.max(...selectedPriority);
        console.log(largestNumber);
        for (let i = 1; i < largestNumber; i++) {
            if (!selectedPriority.includes(i)) {
                return i;
            }
        }
        return false;
    };

    /**
     * Check for missing priority validation and then open the stamp duty accordian
     * @param {number} index: Next accor expand id
     */
    const handleOpenStampDutyAccordValidation = (index) => {
        const checkMissingPriority = handleCheckMissingPriority();
        console.log(checkMissingPriority);
        if (checkMissingPriority) {
            toast.error(errormessages?.REVIEWPRIORITYMISSING);
        } else {
            setExpanded(index);
        }
    };

    // Handle submit document for Review and consent
    const handleSubmitdocumentConsent = () => {
        const checkMissingPriority = handleCheckMissingPriority();
        if (checkMissingPriority) {
            toast.error(errormessages?.REVIEWPRIORITYMISSING);
        } else {
            const dataToSend = {
                reviewerList: documentReviewer,
                stampDutyPaidBy: stampDutyInformation?.stampDutyPaid
                    ? stampDutyInformation?.stampDutyPaid
                    : null,
                stampDutyPurchasedBy: stampDutyInformation?.stampDutyPurchased
                    ? stampDutyInformation?.stampDutyPurchased
                    : null
            };
            setBackdropState(true);
            sendDocumentForReview({ payload: dataToSend, documentId })
                .unwrap()
                .then((response) => {
                    setBackdropState(false);
                    setOpenSuccessModal(true);
                    // console.log('payment Status', paymentStatus)
                })
                .catch((execption) => {
                    setBackdropState(false);
                    console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption);
                });
        }
    };

    // Handle Payment , open alert modal
    const handlePaymentInitialization = (addressList, sameAddress) => {
        if (!paymentForm?.stampDuty) {
            return toast.error(errormessages?.STAMPDUTYREQUIRED);
        }
        if (!paymentForm?.paymentTerms) {
            return toast.error(errormessages?.TERMSCHECKREQUIRED);
        }
        setOpenAlertModal(true);
        setAddressList(addressList);
        setSameAddressForAll(sameAddress);
    };

    /**
     * Start Payment intialization process, razorpay window open
     * Payment success or failure update payment status on backend
     */
    const handleSubmitAlertModal = async () => {
        setOpenAlertModal(false);
        setBackdropState(true);
        const razorpayScript = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!razorpayScript) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }
        console.log('form submit', paymentForm);

        const dataToSend = {
            stampDuty: Number(paymentForm?.stampDuty),
            docType: documentDataResponse?.docType?.id,
            considerationAmount: paymentForm?.considerationAmount,
            storageType: paymentForm?.storageType,
            documentId,
            address: {},
            addressList: addressList,
            addressSameForAll: sameAddressForAll,
            transactionType: PAYMENT_TYPE?.SINGLEDOCUMENT
        };

        generateDocumentRazPaymentID({ payload: dataToSend })
            .unwrap()
            .then((response) => {
                console.log('reponse', response);
                setBackdropState(false);
                paymentInitialized(response)
                    .then((paymentStatusResponse) => {
                        updatePaymentIndividual(paymentStatusResponse);
                    })
                    .catch((paymentStatusexception) => {
                        updatePaymentIndividual(paymentStatusexception);
                    });
            })
            .catch((execption) => {
                setBackdropState(false);

                console.log('Order failed:Backend Failed', execption);
            });
    };

    /**
     * Update payment status
     * @param {Object} razorpayPaymentResponse:Razorpay Payment success or failure object
     */
    const updatePaymentIndividual = (razorpayPaymentResponse) => {
        setBackdropState(true);
        const dataToSend = {
            stampDuty: Number(paymentForm?.stampDuty),
            paymentGateway: razorpayPaymentResponse,
            address: {},
            addressList: addressList,
            addressSameForAll: sameAddressForAll,
            documentId,
            considerationAmount: paymentForm?.considerationAmount,
            storageType: paymentForm?.storageType,
            transactionType: PAYMENT_TYPE?.SINGLEDOCUMENT
        };
        updateDocumentPaymentStatus({ payload: dataToSend })
            .unwrap()
            .then((response) => {
                console.log(response, 'response from cl');
                // navigate('/thankYou', {
                //   state: {
                //     HeaderTitle: 'Create Document',
                //     documentId
                //   }
                // })
                setBackdropState(false);
                setOpenAlertModal(false);
                if (response?.error) {
                    // toast.error(response?.errorMessage)
                    setNotesModalState({
                        open: true,
                        title: ALERT_MESSAGE?.PINCODETITLE,
                        message: response?.errorMessage,
                        buttonText: 'Continue'
                    });
                } else {
                    const extraFieldResponse = {
                        stateHeading: response?.stampPaperStateHeading,
                        belowHeadingTitle:
                            response?.stampPaperStateSecondHeading +
                            response?.estampDataResponse.certificateNo
                    };
                    const stateId = response?.stateId;
                    handleSendContentPdf(stateId, response?.estampDataResponse, extraFieldResponse);
                }
                // console.log('payment Status', paymentStatus)
            })
            .catch((execption) => {
                setBackdropState(false);
                console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption);
            });
    };

    // Hanlde payment for business and corporate user
    const handlePaymentStatusCorpBa = () => {
        setOpenAlertModal(false);
        setBackdropState(true);
        const dataToSend = {
            documentId,
            stampDuty: Number(paymentForm?.stampDuty),
            docType: documentDataResponse?.docType?.id,
            considerationAmount: paymentForm?.considerationAmount,
            storageType: paymentForm?.storageType,
            address: {
                name: paymentForm?.name,
                address: paymentForm?.address,
                addressLineTwo: paymentForm?.addressLineTwo,
                stateId: paymentForm?.stateId,
                cityId: paymentForm?.cityId,
                pinCode: paymentForm?.pinCode,
                phoneNumber: paymentForm?.phone
            },

            transactionType: PAYMENT_TYPE?.SINGLEDOCUMENT
        };
        intializeDocumentPaymentCorpBa({ payload: dataToSend })
            .unwrap()
            .then((response) => {
                setBackdropState(false);
                if (response?.error) {
                    setNotesModalState({
                        open: true,
                        title: ALERT_MESSAGE?.PINCODETITLE,
                        message: response?.errorMessage,
                        buttonText: 'Continue'
                    });
                } else {
                    const extraFieldResponse = {
                        stateHeading: response?.stampPaperStateHeading,
                        belowHeadingTitle:
                            response?.stampPaperStateSecondHeading +
                            response?.estampDataResponse.certificateNo
                    };
                    const stateId = response?.stateId;
                    handleSendContentPdf(stateId, response?.estampDataResponse, extraFieldResponse);
                }
                // console.log('payment Status', paymentStatus)
            })
            .catch((execption) => {
                setBackdropState(false);
                console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption);
            });
    };

    /**
     * Handle make new pdf with cl response object and link with document
     * @param {object} clResponse: Cl repose object
     * @param {object} extraFieldResponse: extra Fields to be shown
     * @param { stateId} stateId: Id of the state
     */
    const handleSendContentPdf = async (stateId, clResponse, extraFieldResponse) => {
        setBackdropState(true);
        const pdf = await html2pdf()
            .from(documentPreview?.content)
            .set({
                filename: 'output23061999111.pdf',
                margin: [15, 10, 40, 10],
                image: { type: 'jpeg', quality: 1 },
                pageBreak: { mode: ['avoid-all', 'css', 'legacy', 'whiteline'] }
            })
            .outputPdf('blob');
        // .save()

        // Add Cl Details
        // const newPdfBlob = await generateDocument(pdf, 'system', clResponse)

        const newPdfBlob = await generateStampPaper(
            stateId,
            pdf,
            'system',
            clResponse,
            extraFieldResponse
        );

        // const pdfBlob = new Blob([pdf], { type: 'application/pdf' })
        // console.log(pdfBlob, 'pdfBlob')

        // const formData = new FormData()
        // formData.append('file', pdfBlob, 'output23061999111.pdf')

        // console.log(formData, 'formDataformDataformData')

        const dataToSend = {
            file: newPdfBlob
        };

        uploadDocumentPdf({ payload: dataToSend, documentId })
            .unwrap()
            .then((response) => {
                setBackdropState(false);
                setSignatureModalDataState({
                    estampId: clResponse?.certificateNo,
                    urn: clResponse?.urn
                });
                setSignatureModalState(true);
            })
            .catch((execption) => {
                setBackdropState(false);
                console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption);
            });
    };

    const handlePdf = async () => {
        // setBackdropState(true)
        const pdf = await html2pdf()
            .from(documentPreview?.content)
            .set({
                filename: 'generated_new.pdf',
                margin: [20, 10, 40, 10],
                image: { type: 'jpeg', quality: 1 },
                pageBreak: { mode: ['avoid-all', 'css', 'legacy'] }
            })
            .outputPdf('blob');

        const extraFieldResponse = {
            stateHeading: 'THE ADMINISTRATION OF UNION TERRITORY OF LADAKH',
            belowHeadingTitle:
                'THE ADMINISTRATION OF UNION TERRITORY OF LADAKH' + 'IN-DL67320629067983U'
        };

        const newPdfBlob = await generateStampPaper(
            STATE?.LADAKH?.ID,
            pdf,
            'system',
            {
                certificateIssuedDate: '22-Oct-2023 06:09 PM',
                firstParty: 'Rohan Sharma',
                stampDutyAmount: '50',
                certificateNo: 'IN-DL67320629067983U',
                baseCertificateNo: 'IN-DL67320629067983U',
                ddoCode: '06201345 3530 JT SRO GUNTUR (R.O)',
                accountReference: 'SHCIL (FI)/ dl-shcil/ CMA-DLH',
                uniqueDocReference: 'SUBIN-DLDL-SHCIL09123456789',
                purchaseBy: 'MUBEEN P',
                urn: 'DL1024DIGID2024008156178DNHR',
                descriptionOfDocument: 'Article 12 Award Article',
                propertyDescription: 'THISISTESTING',
                considerationPrice: '1,00,000',
                secondParty: 'SHCIL Testing',
                stampDutyPaidBy: 'MUBEEN P',
                cowTax: '',
                manMadeCalamities: '',
                infraDevelopment: ''
            },
            extraFieldResponse
        );
    };

    const handleOpenTooltip = (tooltipkey) => {
        setTooltipState({
            ...tooltipInitialState,
            [tooltipkey]: true
        });
    };

    const handleCloseTooltip = (tooltipkey) => {
        setTooltipState({
            ...tooltipInitialState,
            [tooltipkey]: false
        });
    };

    return (
        <div className="createdocument_container">
            <Grid lg={12} container spacing={2}>
                <Grid item lg={6}>
                    <Scrollbars autoHeight autoHeightMax={'calc(100vh - 10rem)'}>
                        <Box id="formArea">
                            {!isSuccess && (
                                <Box>
                                    <Skeleton></Skeleton>
                                    <Skeleton></Skeleton>
                                    <Skeleton></Skeleton>
                                    <Skeleton></Skeleton>
                                    <Skeleton></Skeleton>
                                </Box>
                            )}

                            {/* Field maaping (all fields accordion) */}
                            <Box>
                                {documentDataResponse?.sections?.map((section, sectionIndex) => (
                                    <Accordion
                                        disabled={
                                            documentDataResponse?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.WAITINGFORPAYMENT?.ID
                                        }
                                        key={sectionIndex}
                                        expanded={expanded === sectionIndex}
                                        onChange={() => handleExpandArrow(sectionIndex)}
                                        sx={{ marginBottom: '20px' }}>
                                        <div className="toggle">
                                            <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header">
                                                <Typography
                                                    variant={
                                                        expanded === sectionIndex
                                                            ? 'darkValueLarge'
                                                            : 'darkValue'
                                                    }>
                                                    {section?.titleText}
                                                    {/* Tooltip after text */}
                                                    {section?.fieldInfo && (
                                                        <span style={{ marginLeft: '8px' }}>
                                                            <HowerTooltip
                                                                message={[section?.fieldInfo]}
                                                            />
                                                        </span>
                                                    )}
                                                </Typography>
                                            </AccordionSummary>

                                            {/* Condition for back button(on 0 index) */}
                                            {sectionIndex === 0 && (
                                                <div
                                                    style={{
                                                        marginTop: '20px',
                                                        marginRight: '20px'
                                                    }}>
                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                        alignItems="center">
                                                        <Link
                                                            to="/createDocument"
                                                            className="text-link-back">
                                                            Back
                                                        </Link>
                                                    </Stack>
                                                </div>
                                            )}
                                        </div>

                                        <AccordionDetails>
                                            <Typography>
                                                <div style={{ padding: '30px' }}>
                                                    {documentDataResponse?.sections[
                                                        expanded
                                                    ]?.fields.map((field, fieldindex) => (
                                                        <>
                                                            {field?.componentName === 'text' && (
                                                                <NormalTextField
                                                                    key={field?.subFields[0]?.id}
                                                                    textFieldData={
                                                                        field?.subFields[0]
                                                                    }
                                                                    fieldLabel={field?.titleText}
                                                                    onchange={
                                                                        handleDocumentFormChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName ===
                                                                'textareaClause' && (
                                                                <TextAreafield
                                                                    key={field?.subFields[0]?.id}
                                                                    textFieldData={
                                                                        field?.subFields[0]
                                                                    }
                                                                    fieldLabel={field?.titleText}
                                                                    onchange={
                                                                        handleDocumentFormChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                                // <NormalTextField
                                                                //     key={field?.subFields[0]?.id}
                                                                //     textFieldData={field?.subFields[0]}
                                                                //     fieldLabel={field?.titleText}
                                                                //     onchange={handleDocumentFormChange}
                                                                //     onfocus={addHighlightClass}
                                                                //     documentForm={documentForm}
                                                                // />
                                                            )}
                                                            {field?.componentName ===
                                                                'numberfield' && (
                                                                <NumberTextField
                                                                    key={field?.subFields[0]?.id}
                                                                    textFieldData={
                                                                        field?.subFields[0]
                                                                    }
                                                                    fieldLabel={field?.titleText}
                                                                    onchange={
                                                                        handleDocumentFormChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName ===
                                                                'decimalfield' && (
                                                                <DecimalTextField
                                                                    key={field?.subFields[0]?.id}
                                                                    textFieldData={
                                                                        field?.subFields[0]
                                                                    }
                                                                    fieldLabel={field?.titleText}
                                                                    onchange={
                                                                        handleDocumentFormChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName ===
                                                                'currency' && (
                                                                <CurrencyTextField
                                                                    key={field?.subFields[0]?.id}
                                                                    textFieldData={
                                                                        field?.subFields[0]
                                                                    }
                                                                    fieldLabel={field?.titleText}
                                                                    onchange={
                                                                        handleDocumentFormCurrencyChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName === 'date' && (
                                                                <DateTextField
                                                                    textFieldData={
                                                                        field?.subFields[0]
                                                                    }
                                                                    key={field?.subFields[0]?.id}
                                                                    fieldLabel={field?.titleText}
                                                                    onchange={
                                                                        handleDocumentFormChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName === 'select' && (
                                                                <SelectDropdown
                                                                    key={field?.subFields[0]?.id}
                                                                    selectData={field?.subFields[0]}
                                                                    fieldLabel={field?.titleText}
                                                                    onchange={
                                                                        handleDocumentFormChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName ===
                                                                'select-text' && (
                                                                <SelectTextField
                                                                    key={field?.subFields[0]?.id}
                                                                    fieldLabel={field?.titleText}
                                                                    selectData={field?.subFields.find(
                                                                        (field) =>
                                                                            field.type ===
                                                                            'dropdown'
                                                                    )}
                                                                    textFieldData={field?.subFields.find(
                                                                        (field) =>
                                                                            field.type === 'text' ||
                                                                            field.type ===
                                                                                'number' ||
                                                                            field.type ===
                                                                                'currency'
                                                                    )}
                                                                    onchange={
                                                                        handleDocumentFormChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName ===
                                                                'radio-text' && (
                                                                <RadiowithTextfield
                                                                    key={field?.subFields[0]?.id}
                                                                    radioData={field?.subFields.find(
                                                                        (field) =>
                                                                            field.type ===
                                                                            'radio_button'
                                                                    )}
                                                                    textFieldData={field?.subFields.find(
                                                                        (field) =>
                                                                            field.type === 'text' ||
                                                                            field.type ===
                                                                                'number' ||
                                                                            field.type ===
                                                                                'currency'
                                                                    )}
                                                                    fieldLabel={field?.titleText}
                                                                    documentForm={documentForm}
                                                                    onchange={
                                                                        handleDocumentFormChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName ===
                                                                'checkbox' && (
                                                                <CheckboxWithText
                                                                    checkBoxData={
                                                                        field?.subFields[0]
                                                                    }
                                                                    fieldData={field}
                                                                    onchange={
                                                                        handleDocumentFormCheckboxChange
                                                                    }
                                                                    documentForm={documentForm}
                                                                />
                                                            )}
                                                            {field?.componentName ===
                                                                'timepicker' && (
                                                                <TimePickerField
                                                                    key={field?.subFields[0]?.id}
                                                                    textFieldData={
                                                                        field?.subFields[0]
                                                                    }
                                                                    fieldLabel={field?.titleText}
                                                                    onchange={
                                                                        handleDocumentTimePickerChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName ===
                                                                'dropdown' && (
                                                                <DropdownField
                                                                    key={field?.subFields[0]?.id}
                                                                    selectData={field?.subFields[0]}
                                                                    fieldLabel={field?.titleText}
                                                                    onchange={handleDropdownChange}
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName ===
                                                                'select-decimalfield' && (
                                                                <SelectDecimalField
                                                                    key={field?.subFields[0]?.id}
                                                                    fieldLabel={field?.titleText}
                                                                    selectData={field?.subFields.find(
                                                                        (field) =>
                                                                            field.type ===
                                                                            'dropdown'
                                                                    )}
                                                                    textFieldData={field?.subFields.find(
                                                                        (field) =>
                                                                            field.type === 'text' ||
                                                                            field.type ===
                                                                                'number' ||
                                                                            field.type ===
                                                                                'currency'
                                                                    )}
                                                                    onchange={
                                                                        handleDocumentFormChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                            {field?.componentName ===
                                                                'select-numberfield' && (
                                                                <SelectNumberField
                                                                    key={field?.subFields[0]?.id}
                                                                    fieldLabel={field?.titleText}
                                                                    selectData={field?.subFields.find(
                                                                        (field) =>
                                                                            field.type ===
                                                                            'dropdown'
                                                                    )}
                                                                    textFieldData={field?.subFields.find(
                                                                        (field) =>
                                                                            field.type === 'text' ||
                                                                            field.type ===
                                                                                'number' ||
                                                                            field.type ===
                                                                                'currency'
                                                                    )}
                                                                    onchange={
                                                                        handleDocumentFormChange
                                                                    }
                                                                    onfocus={addHighlightClass}
                                                                    documentForm={documentForm}
                                                                    sectionIndex={sectionIndex}
                                                                />
                                                            )}
                                                        </>
                                                    ))}

                                                    <div className="toggle-nextButton">
                                                        {sectionIndex !== 0 && (
                                                            <Button
                                                                variant="primary"
                                                                onClick={() =>
                                                                    handleExpandPrev(sectionIndex)
                                                                }>
                                                                Previous
                                                            </Button>
                                                        )}
                                                        <Button
                                                            variant="primary"
                                                            onClick={() =>
                                                                handleNextAccord(sectionIndex)
                                                            }>
                                                            Next
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </Box>

                            {isSuccess && (
                                <>
                                    {/* Consent table */}
                                    <Box sx={{ marginTop: '20px' }}>
                                        <Accordion
                                            disabled={
                                                documentDataResponse?.status?.id ===
                                                DOCUEMENTFILTERSTATUS?.WAITINGFORPAYMENT?.ID
                                            }
                                            expanded={
                                                expanded === documentDataResponse?.sections?.length
                                            }
                                            onChange={handleExpandChange(
                                                documentDataResponse?.sections?.length
                                            )}>
                                            <div className="toggle">
                                                <AccordionSummary
                                                    aria-controls="panel1d-content"
                                                    id="panel1d-header">
                                                    <Typography variant="darkValue">
                                                        Review Consent
                                                    </Typography>
                                                </AccordionSummary>

                                                {/* <div className="toggle-right">
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center">
                                                    <img
                                                        src={info}
                                                        alt="info"
                                                        onClick={handleClick}
                                                        ref={reviewPopoverRef}
                                                    />
                                                </Stack>
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'left'
                                                    }}
                                                    transformOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'right'
                                                    }}>
                                                    <Box className="popover_box">
                                                        <div className="popover_box-head">
                                                            <Typography
                                                                variant="darkValueLarge"
                                                                sx={{ color: '#003A51' }}>
                                                                Important Notes
                                                            </Typography>
                                                        </div>
                                                        {REVIEWCONSENTNOTES &&
                                                            REVIEWCONSENTNOTES?.map(
                                                              (item, indexImpNotes) => (
                                                                    <div
                                                                        className="popover_box-details1"
                                                                        key={indexImpNotes}>
                                                                        <Typography
                                                                            variant="modalLabel"
                                                                            sx={{
                                                                              color: '#555555',
                                                                              letterSpacing:
                                                                                    '0.03em',
                                                                              lineHeight: '30px'
                                                                            }}>
                                                                            {item}
                                                                        </Typography>
                                                                    </div>
                                                              )
                                                            )}
                                                        <Button
                                                            variant="primary"
                                                            onClick={handleClose}>
                                                            Ok
                                                        </Button>
                                                    </Box>
                                                </Popover>
                                            </div> */}
                                            </div>
                                            <AccordionDetails>
                                                <div className="reviewTable-box">
                                                    {documentDataResponse?.status?.id ===
                                                        DOCUEMENTFILTERSTATUS?.INDRAFT?.ID && (
                                                        <div className="reviewTable-FieldInputBox">
                                                            <form
                                                                onSubmit={(event) => {
                                                                    event.preventDefault();
                                                                    handleAddReviewerNew();
                                                                }}>
                                                                <div className="FieldBox">
                                                                    <div className="FieldBoxLabel">
                                                                        <Typography variant="darkValue">
                                                                            Select party type
                                                                        </Typography>
                                                                    </div>
                                                                    <div className="FieldBoxInput">
                                                                        <Select
                                                                            required
                                                                            name="partyType"
                                                                            value={
                                                                                reviewForm?.partyType
                                                                            }
                                                                            onChange={
                                                                                handlePartyTypeChange
                                                                            }
                                                                            displayEmpty
                                                                            inputProps={{
                                                                                'aria-label':
                                                                                    'Without label'
                                                                            }}
                                                                            sx={{
                                                                                fontSize: '16px'
                                                                            }}
                                                                            MenuProps={MenuProps}
                                                                            fullWidth>
                                                                            <MenuItem
                                                                                value=""
                                                                                disabled>
                                                                                <Typography>
                                                                                    Select party
                                                                                    type
                                                                                </Typography>
                                                                            </MenuItem>

                                                                            {partyTypeArray?.length >
                                                                                0 &&
                                                                                partyTypeArray.map(
                                                                                    (item) => (
                                                                                        <MenuItem
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    '16px'
                                                                                            }}
                                                                                            key={
                                                                                                item.id
                                                                                            }
                                                                                            value={
                                                                                                item
                                                                                            }>
                                                                                            {
                                                                                                item.name
                                                                                            }
                                                                                        </MenuItem>
                                                                                    )
                                                                                )}
                                                                        </Select>
                                                                    </div>
                                                                </div>

                                                                <div className="FieldBox">
                                                                    <div className="FieldBoxLabel">
                                                                        <Typography variant="darkValue">
                                                                            Enter name
                                                                        </Typography>
                                                                    </div>
                                                                    <div className="FieldBoxInput">
                                                                        <TextField
                                                                            name="name"
                                                                            type="text"
                                                                            fullWidth
                                                                            required
                                                                            inputProps={{
                                                                                type: 'text',
                                                                                maxlength:
                                                                                    CLVALIDATIONS
                                                                                        ?.NAME
                                                                                        ?.MAXLENGTH,
                                                                                minlength:
                                                                                    CLVALIDATIONS
                                                                                        ?.NAME
                                                                                        ?.MINLENGTH
                                                                            }}
                                                                            value={
                                                                                reviewForm?.name ||
                                                                                ''
                                                                            }
                                                                            id="name"
                                                                            placeholder={
                                                                                PLACEHOLDER?.FULLNAME
                                                                            }
                                                                            onChange={(event) => {
                                                                                if (
                                                                                    event.target
                                                                                        .value ===
                                                                                        '' ||
                                                                                    CLVALIDATIONS?.NAME?.PATTERN.test(
                                                                                        event
                                                                                            ?.target
                                                                                            ?.value
                                                                                    )
                                                                                ) {
                                                                                    handleReviewForm(
                                                                                        event
                                                                                    );
                                                                                }
                                                                            }}
                                                                            disabled={
                                                                                disableNameField
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="FieldBox">
                                                                    <div className="FieldBoxLabel">
                                                                        <Typography variant="darkValue">
                                                                            Enter address
                                                                        </Typography>
                                                                    </div>
                                                                    <div className="FieldBoxInput">
                                                                        <TextField
                                                                            name="address"
                                                                            type="text"
                                                                            fullWidth
                                                                            inputProps={{
                                                                                type: 'text',
                                                                                maxlength:
                                                                                    CLVALIDATIONS
                                                                                        ?.ADDRESS
                                                                                        ?.MAXLENGTH,
                                                                                minlength:
                                                                                    CLVALIDATIONS
                                                                                        ?.ADDRESS
                                                                                        ?.MINLENGTH
                                                                            }}
                                                                            required
                                                                            value={
                                                                                reviewForm?.address ||
                                                                                ''
                                                                            }
                                                                            id="address"
                                                                            placeholder={
                                                                                PLACEHOLDER?.ADDRESS
                                                                            }
                                                                            onChange={(event) => {
                                                                                if (
                                                                                    event.target
                                                                                        .value ===
                                                                                        '' ||
                                                                                    CLVALIDATIONS?.ADDRESS?.PATTERN.test(
                                                                                        event
                                                                                            ?.target
                                                                                            ?.value
                                                                                    )
                                                                                ) {
                                                                                    handleReviewForm(
                                                                                        event
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="FieldBox">
                                                                    <div className="FieldBoxLabel">
                                                                        <Typography variant="darkValue">
                                                                            Enter email
                                                                        </Typography>
                                                                    </div>
                                                                    <div className="FieldBoxInput">
                                                                        <TextField
                                                                            error={
                                                                                !(
                                                                                    reviewForm
                                                                                        ?.errors
                                                                                        ?.email ===
                                                                                        'error' ||
                                                                                    reviewForm
                                                                                        ?.errors
                                                                                        ?.email ===
                                                                                        'noerror'
                                                                                )
                                                                            }
                                                                            helperText={
                                                                                reviewForm?.errors
                                                                                    ?.email ===
                                                                                    'error' ||
                                                                                reviewForm?.errors
                                                                                    ?.email ===
                                                                                    'noerror'
                                                                                    ? ''
                                                                                    : reviewForm
                                                                                          ?.errors
                                                                                          ?.email
                                                                            }
                                                                            name="email"
                                                                            type="email"
                                                                            fullWidth
                                                                            required
                                                                            value={
                                                                                reviewForm.email ||
                                                                                ''
                                                                            }
                                                                            id="email"
                                                                            placeholder={
                                                                                PLACEHOLDER?.EMAIL
                                                                            }
                                                                            onChange={
                                                                                handleReviewFormEmail
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="FieldBox">
                                                                    <div className="FieldBoxLabel">
                                                                        <Typography variant="darkValue">
                                                                            Enter phone number
                                                                        </Typography>
                                                                    </div>
                                                                    <div className="FieldBoxInput">
                                                                        <TextField
                                                                            value={
                                                                                reviewForm?.phone ||
                                                                                ''
                                                                            }
                                                                            fullWidth
                                                                            required
                                                                            type="text"
                                                                            inputProps={{
                                                                                type: 'text',
                                                                                // inputMode: 'numeric',
                                                                                pattern: '[0-9]*',
                                                                                maxlength: 10,
                                                                                minlength: 10
                                                                            }}
                                                                            id="phone"
                                                                            name="phone"
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <img
                                                                                            src={
                                                                                                flag
                                                                                            }
                                                                                            alt="flag"
                                                                                        />
                                                                                        &nbsp;+91 |
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            onChange={(e) => {
                                                                                if (
                                                                                    e.target
                                                                                        .value ===
                                                                                        '' ||
                                                                                    PATTERN?.NUMBERONLY.test(
                                                                                        e?.target
                                                                                            ?.value
                                                                                    )
                                                                                ) {
                                                                                    handleReviewForm(
                                                                                        e
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="FieldBox">
                                                                    <div className="FieldBoxLabel">
                                                                        <Typography variant="darkValue">
                                                                            Select priority
                                                                            <CustomTooltip
                                                                                message={
                                                                                    PRIORITYMESSAGE
                                                                                }
                                                                                openTooltip={
                                                                                    tooltipState?.priorityTooltip
                                                                                }
                                                                                setOpenTooltip={
                                                                                    setTooltipState
                                                                                }
                                                                                handleTooltipOpen={() =>
                                                                                    handleOpenTooltip(
                                                                                        'priorityTooltip'
                                                                                    )
                                                                                }
                                                                                handleTooltipClose={() =>
                                                                                    handleCloseTooltip(
                                                                                        'priorityTooltip'
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Typography>
                                                                    </div>

                                                                    <div className="FieldBoxInput">
                                                                        <Select
                                                                            required
                                                                            name="priority"
                                                                            value={
                                                                                reviewForm?.priority ||
                                                                                ''
                                                                            }
                                                                            onChange={
                                                                                handleReviewForm
                                                                            }
                                                                            displayEmpty
                                                                            inputProps={{
                                                                                'aria-label':
                                                                                    'Without label'
                                                                            }}
                                                                            sx={{
                                                                                fontSize: '16px'
                                                                            }}
                                                                            MenuProps={MenuProps}
                                                                            fullWidth>
                                                                            <MenuItem
                                                                                value=""
                                                                                disabled>
                                                                                <Typography>
                                                                                    Select priority
                                                                                </Typography>
                                                                            </MenuItem>
                                                                            {priorityList?.length >
                                                                                0 &&
                                                                                priorityList?.map(
                                                                                    (item) => (
                                                                                        <MenuItem
                                                                                            disabled={
                                                                                                selectedPriority.includes(
                                                                                                    item
                                                                                                ) &&
                                                                                                item !==
                                                                                                    editModePriority
                                                                                            }
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    '16px'
                                                                                            }}
                                                                                            key={
                                                                                                item
                                                                                            }
                                                                                            value={
                                                                                                item
                                                                                            }>
                                                                                            {item}
                                                                                        </MenuItem>
                                                                                    )
                                                                                )}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div className="FieldBox">
                                                                    <div className="FieldBoxLabel">
                                                                        <Typography variant="darkValue">
                                                                            Consent / e-sign
                                                                            required
                                                                            <CustomTooltip
                                                                                message={
                                                                                    CONSENTMESSAGE
                                                                                }
                                                                                openTooltip={
                                                                                    tooltipState?.signTooltip
                                                                                }
                                                                                setOpenTooltip={
                                                                                    setTooltipState
                                                                                }
                                                                                handleTooltipOpen={() =>
                                                                                    handleOpenTooltip(
                                                                                        'signTooltip'
                                                                                    )
                                                                                }
                                                                                handleTooltipClose={() =>
                                                                                    handleCloseTooltip(
                                                                                        'signTooltip'
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Typography>
                                                                    </div>
                                                                    <div className="FieldBoxInput">
                                                                        <FormGroup
                                                                            sx={{
                                                                                flexDirection: 'row'
                                                                            }}>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        disabled={
                                                                                            reviewForm?.consent
                                                                                        }
                                                                                        sx={{
                                                                                            color: 'var(--g2)',
                                                                                            '&.Mui-checked':
                                                                                                {
                                                                                                    color: '#33AFB9'
                                                                                                }
                                                                                        }}
                                                                                        inputProps={{
                                                                                            'aria-label':
                                                                                                'controlled'
                                                                                        }}
                                                                                        checked={
                                                                                            reviewForm?.eSign
                                                                                        }
                                                                                        name="eSign"
                                                                                        id="eSign"
                                                                                        onChange={
                                                                                            handleReviewFormCheckbox
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label="E-sign"
                                                                            />
                                                                            {documentDataResponse
                                                                                ?.partyType?.id !==
                                                                                PARTYTYPEOPTIONS
                                                                                    ?.SINGLEPARTY
                                                                                    ?.id && (
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            sx={{
                                                                                                color: 'var(--g2)',
                                                                                                '&.Mui-checked':
                                                                                                    {
                                                                                                        color: '#33AFB9'
                                                                                                    }
                                                                                            }}
                                                                                            inputProps={{
                                                                                                'aria-label':
                                                                                                    'controlled'
                                                                                            }}
                                                                                            checked={
                                                                                                reviewForm?.consent
                                                                                            }
                                                                                            name="consent"
                                                                                            id="consent"
                                                                                            onChange={
                                                                                                handleReviewFormCheckbox
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    label="Consent"
                                                                                />
                                                                            )}
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                                <div className="FieldBox">
                                                                    <div className="FieldBoxLabel">
                                                                        <Typography variant="darkValue">
                                                                            Preferred mode of
                                                                            signature
                                                                            <CustomTooltip
                                                                                message={
                                                                                    PREFFEREDSIGNMODEMESSAGE
                                                                                }
                                                                                openTooltip={
                                                                                    tooltipState?.prefferedSignTooltip
                                                                                }
                                                                                setOpenTooltip={
                                                                                    setTooltipState
                                                                                }
                                                                                handleTooltipOpen={() =>
                                                                                    handleOpenTooltip(
                                                                                        'prefferedSignTooltip'
                                                                                    )
                                                                                }
                                                                                handleTooltipClose={() =>
                                                                                    handleCloseTooltip(
                                                                                        'prefferedSignTooltip'
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Typography>
                                                                    </div>
                                                                    <div className="FieldBoxInput">
                                                                        <FormControl>
                                                                            <RadioGroup
                                                                                required
                                                                                name="signMode"
                                                                                row
                                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                value={
                                                                                    reviewForm?.signMode ||
                                                                                    ''
                                                                                }
                                                                                onChange={
                                                                                    handleReviewFormSignModeChange
                                                                                }>
                                                                                {signatureptions?.length >
                                                                                    0 &&
                                                                                    signatureptions.map(
                                                                                        (
                                                                                            signmode,
                                                                                            index
                                                                                        ) => (
                                                                                            <FormControlLabel
                                                                                                key={
                                                                                                    signmode?.id
                                                                                                }
                                                                                                value={
                                                                                                    signmode?.id
                                                                                                }
                                                                                                control={
                                                                                                    <Radio
                                                                                                        sx={{
                                                                                                            color: 'var(--g2)',
                                                                                                            '&.Mui-checked':
                                                                                                                {
                                                                                                                    color: palette
                                                                                                                        .success
                                                                                                                        .dark
                                                                                                                }
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                                label={
                                                                                                    <Typography
                                                                                                        variant="labelSmall"
                                                                                                        sx={{
                                                                                                            color: 'var(--g6)',
                                                                                                            fontSize:
                                                                                                                '14px'
                                                                                                        }}>
                                                                                                        {
                                                                                                            signmode?.title
                                                                                                        }
                                                                                                    </Typography>
                                                                                                }
                                                                                            />
                                                                                        )
                                                                                    )}
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className="reviewTable-FieldInputButton">
                                                                    <Button
                                                                        type="submit"
                                                                        variant="primary">
                                                                        Confirm to Proceed
                                                                    </Button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    )}
                                                    {isProcessingCompleted && (
                                                        <ReviewTable
                                                            documentStatus={
                                                                documentDataResponse?.status
                                                            }
                                                            documentReviewer={documentReviewer}
                                                            handleDeleteReviewer={(item) => {
                                                                setCurrentSelectedReview(item);
                                                                setOpenDeleteAlertModal(true);
                                                            }}
                                                            documentId={documentId}
                                                            handleEditReviewer={handleEditReviewer}
                                                        />
                                                    )}
                                                    {documentDataResponse?.status?.id ===
                                                        DOCUEMENTFILTERSTATUS?.INDRAFT?.ID &&
                                                        documentConfiguration?.document
                                                            ?.reviewConsentButton && (
                                                            <div className="reviewTable-button">
                                                                <Button
                                                                    disabled={
                                                                        isProceedConsentButtonDisabled
                                                                    }
                                                                    variant="primary"
                                                                    onClick={
                                                                        handleSubmitdocumentConsent
                                                                    }>
                                                                    Send document for consent
                                                                </Button>
                                                            </div>
                                                        )}
                                                    {documentDataResponse?.status?.id ===
                                                        DOCUEMENTFILTERSTATUS?.INDRAFT?.ID &&
                                                        !documentConfiguration?.document
                                                            ?.reviewConsentButton && (
                                                            <div className="reviewTable-button">
                                                                <Button
                                                                    variant="primary"
                                                                    onClick={() =>
                                                                        handleOpenStampDutyAccordValidation(
                                                                            documentDataResponse
                                                                                ?.sections?.length +
                                                                                1
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        isProceedConsentButtonDisabled
                                                                    }>
                                                                    Next
                                                                </Button>
                                                            </div>
                                                        )}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>

                                    {/* Stamp duty table */}
                                    {documentConfiguration?.document?.stampDutyAccord &&
                                        documentDataResponse?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.INDRAFT?.ID && (
                                            <Box sx={{ marginTop: '20px' }}>
                                                <Accordion
                                                    disabled={
                                                        documentDataResponse?.status?.id ===
                                                        DOCUEMENTFILTERSTATUS?.WAITINGFORPAYMENT?.ID
                                                    }
                                                    expanded={
                                                        expanded ===
                                                        documentDataResponse?.sections?.length + 1
                                                    }
                                                    onChange={handleExpandStampDutyAccord(
                                                        documentDataResponse?.sections?.length + 1
                                                    )}>
                                                    <div className="toggle">
                                                        <AccordionSummary
                                                            aria-controls="panel1d-content"
                                                            id="panel1d-header">
                                                            <Typography variant="darkValue">
                                                                Stamp Duty Information
                                                            </Typography>
                                                        </AccordionSummary>
                                                    </div>
                                                    <AccordionDetails>
                                                        <div className="reviewTable-box">
                                                            {documentDataResponse?.status?.id ===
                                                                DOCUEMENTFILTERSTATUS?.INDRAFT
                                                                    ?.ID && (
                                                                <div className="reviewTable-FieldInputBox">
                                                                    <form
                                                                        onSubmit={(event) => {
                                                                            event.preventDefault();
                                                                            handleSubmitdocumentConsent();
                                                                        }}>
                                                                        <div className="FieldBox">
                                                                            <div className="FieldBoxLabel">
                                                                                <Typography variant="darkValue">
                                                                                    Select Stamp
                                                                                    Duty Paid By
                                                                                </Typography>
                                                                            </div>
                                                                            <div className="FieldBoxInput">
                                                                                <Select
                                                                                    required
                                                                                    name="stampDutyPaid"
                                                                                    value={
                                                                                        stampDutyInformation?.stampDutyPaid
                                                                                    }
                                                                                    onChange={
                                                                                        handleStampDutyInformation
                                                                                    }
                                                                                    displayEmpty
                                                                                    inputProps={{
                                                                                        'aria-label':
                                                                                            'Without label'
                                                                                    }}
                                                                                    sx={{
                                                                                        fontSize:
                                                                                            '16px'
                                                                                    }}
                                                                                    MenuProps={
                                                                                        MenuProps
                                                                                    }
                                                                                    fullWidth>
                                                                                    <MenuItem
                                                                                        value=""
                                                                                        disabled>
                                                                                        <Typography>
                                                                                            Select
                                                                                            Stamp
                                                                                            Duty
                                                                                            Paid
                                                                                        </Typography>
                                                                                    </MenuItem>

                                                                                    {documentReviewer?.length >
                                                                                        0 &&
                                                                                        documentReviewer
                                                                                            .filter(
                                                                                                (
                                                                                                    item
                                                                                                ) =>
                                                                                                    item
                                                                                                        ?.partyType
                                                                                                        ?.id !==
                                                                                                    PARTYTYPES
                                                                                                        ?.WITNESS
                                                                                                        ?.ID
                                                                                            )
                                                                                            ?.map(
                                                                                                (
                                                                                                    item
                                                                                                ) => (
                                                                                                    <MenuItem
                                                                                                        sx={{
                                                                                                            fontSize:
                                                                                                                '16px'
                                                                                                        }}
                                                                                                        key={
                                                                                                            item.id
                                                                                                        }
                                                                                                        value={
                                                                                                            item
                                                                                                        }>
                                                                                                        {` ${item?.partyType?.name}(${item?.email})`}
                                                                                                    </MenuItem>
                                                                                                )
                                                                                            )}
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                        {documentDataResponse
                                                                            ?.status?.id ===
                                                                            DOCUEMENTFILTERSTATUS
                                                                                ?.INDRAFT?.ID && (
                                                                            <div className="reviewTable-button">
                                                                                <Button
                                                                                    disabled={
                                                                                        isProceedConsentButtonDisabled
                                                                                    }
                                                                                    type="submit"
                                                                                    variant="primary"
                                                                                    // onClick={
                                                                                    //     handleSubmitdocumentConsent
                                                                                    // }
                                                                                >
                                                                                    Send document
                                                                                    for consent
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </form>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                        )}

                                    {/* Payment Box and accept terms and condition checkbox */}
                                    {documentDataResponse?.isOwner && (
                                        <Box sx={{ marginTop: '20px' }}>
                                            <PaymentAccord
                                                handlePaymentInitialization={
                                                    handlePaymentInitialization
                                                }
                                                Accordindex={
                                                    documentDataResponse?.sections?.length + 2
                                                }
                                                expanded={expanded}
                                                handleExpandChange={handleExpandChange}
                                                documentId={documentId}
                                                paymentForm={paymentForm}
                                                setPaymentForm={setPaymentForm}
                                            />

                                            {/* Accept Terms checkbox */}
                                            {/* {documentDataResponse?.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.WAITINGFORPAYMENT?.ID && (
                                        <>
                                            <div style={{ marginTop: '20px' }}>
                                                <FormControlLabel
                                                    sx={{ mb: '15px', mr: 0 }}
                                                    control={
                                                        <Checkbox
                                                            onClick={(event) => {
                                                              setPaymentForm((prev) => ({
                                                                ...prev,
                                                                [event.target.name]:
                                                                        event.target.checked
                                                              }))
                                                            }}
                                                            sx={{
                                                              color: 'var(--g2)',
                                                              '&.Mui-checked': {
                                                                color: '#33AFB9'
                                                              }
                                                            }}
                                                            required
                                                            name="paymentTerms"
                                                            id="paymentTerms"
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            variant="modalLabel"
                                                            sx={{ color: '#555555' }}>
                                                            <Link
                                                                to="/termsAndConditions"
                                                                className="text-link"
                                                                href="https://www.google.com/"
                                                                color="var(--g7)">
                                                                I agree,
                                                            </Link>
                                                            &nbsp; MDR charges will be added by
                                                            payment gateway on top of amount shown.
                                                        </Typography>
                                                    }
                                                />
                                            </div>
                                            <Button
                                                variant="primary"
                                                onClick={handlePaymentInitialization}>
                                                Proceed to payment
                                            </Button>
                                        </>
                                    )} */}
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                    </Scrollbars>
                </Grid>
                <Grid item lg={6}>
                    {/* <img
                        src={commentView}
                        alt="addcomment"
                        style={{ cursor: 'pointer' }}
                        // onClick={() => {
                        //   setOpenAddCommentBox(false)
                        //   setopenViewCommentBox(true)
                        // }}
                      /> */}
                    <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {/* <Button onClick={handlePdf}>pdf</Button> */}

                        <Button
                            variant="link"
                            sx={{ background: '#fff' }}
                            onClick={() => {
                                setPreviewDocumentModalState({ open: true });
                            }}>
                            Preview Document
                        </Button>
                    </Grid>
                    <Box
                        className="details_rightgrid-box"
                        id="previewDocument"
                        sx={{ maxHeight: 'calc(100vh - 10rem)' }}>
                        <PreviewDocument
                            documentId={documentId}
                            documentPreview={documentPreview}
                        />
                    </Box>
                </Grid>
            </Grid>

            {/* Success Form send for review to other parties modal  */}
            {openSuccessModal && (
                <SuccessModal
                    openSuccessModal={openSuccessModal}
                    handleSuccessModalClose={() => setOpenSuccessModal(false)}
                />
            )}

            {/* Alert confirmation modal */}
            {openAlertModal && (
                <AlertModal
                    openAlertModal={openAlertModal}
                    handleAlertModalClose={() => setOpenAlertModal(false)}
                    handleSubmitAlertModal={
                        profile?.role === ROLES?.INDIVIDUAL
                            ? handleSubmitAlertModal
                            : handlePaymentStatusCorpBa
                    }
                    alertModalState={alertModalState}
                />
            )}

            {/* Alert confirmation modal */}
            {openDeleteAlertModal && (
                <AlertModal
                    openAlertModal={openDeleteAlertModal}
                    handleAlertModalClose={() => setOpenDeleteAlertModal(false)}
                    handleSubmitAlertModal={() => handleDeleteReviewer(currentSelectedReview)}
                    alertModalState={deleteAlertModalState}
                />
            )}

            {signatureModalState && (
                <SignatureModal
                    openSignModal={signatureModalState}
                    signatureModalDataState={signatureModalDataState}
                    handleSubmitSignModal={() => {
                        navigate(`/previewEsignPage/${documentId}`, {
                            state: {
                                HeaderTitle: 'Signature Selection'
                            }
                        });
                    }}
                    handleCancelSignModal={() => {
                        setSignatureModalState(false);
                        navigate('/myDocument', {
                            state: {
                                HeaderTitle: 'My Document'
                            }
                        });
                    }}
                />
            )}

            {notesModalState?.open && (
                <CommonMessageModal
                    CommonModalState={notesModalState}
                    handleOkButtonCommonModal={() => {
                        navigate('/myDocument', {
                            state: {
                                HeaderTitle: 'My Documents'
                            }
                        });
                    }}
                />
            )}
            {digitalSignNotesModalState?.open && (
                <CommonMessageButtonModal
                    handleOkButtonCommonModal={() => {
                        setDigitalSignNotesModalState((prev) => ({
                            ...prev,
                            open: false
                        }));
                    }}
                    handleCancelButton={handleCancelDigitalSignNotesModal}
                    CommonMessageModalState={digitalSignNotesModalState}
                />
            )}
            {backdropState && <BackdropLoader backdropState={backdropState} />}

            {previewDocumentModalState?.open && (
                <PreviewDocumentModal
                    handlePrevieDocumentModalClose={() => {
                        setPreviewDocumentModalState({ open: false });
                    }}
                    previewDocumentModalState={previewDocumentModalState}
                    documentId={documentId}
                    documentPreview={document.getElementById('previewDocument')?.innerHTML}
                />
            )}
        </div>
    );
};

export default CustomDocument;
