/* eslint-disable */
const styles = {
  container: {
    position: 'absolute',  // Ensure it fills the whole screen
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    padding: 0,
    overflow: 'hidden',   // Hide overflow if any
  },
  iframe: {
    width: '100%',        // Ensure iframe fills the width of the screen
    height: '100%',       // Ensure iframe fills the height of the screen
    border: 'none',       // Remove the default iframe border
  },
};

export const HelpQuestTandC = () => {
    return(
        <div style={styles.container}>
            <iframe
                src="/termsAndCondition.html"
            style={styles.iframe}
            
            />

            
        </div>
    )


}