import './Login.css'
import React, { useContext, useEffect, useState } from 'react'
import {
  useLazySendTwoFactorOtpQuery,
  useLazyVerifyTwoFactorOtpQuery,
  useLoginUserMutation,
  useSocialLoginMutation
} from '../../../../store/services/userServices'
import { setSession } from '../../../../util/helper'
import {
  Typography,
  Grid,
  Divider,
  TextField,
  Button,
  InputAdornment,
  IconButton
} from '@mui/material'
// import linkedln from '../../../../assets/images/linkedln.svg'
import gmail from '../../../../assets/images/gmail.svg'
import facebook from '../../../../assets/images/facebook.svg'
import eyeOffIcon from '../../../../assets/images/eyeoff.svg'
import eyeOnIcon from '../../../../assets/images/eyeon.svg'
import { validator } from '../../../../util/validator'
import { Link, useNavigate } from 'react-router-dom'
import ExtraInfoModal from '../ExtraInfoModal/ExtraInfoModal'
import { LoginSocialFacebook, LoginSocialGoogle, LoginSocialLinkedin } from 'reactjs-social-login'
import { socialData } from '../../../../util/socialData'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
// import { USER_ROLE } from '../../../../constants/enum'
import { toast } from 'react-toastify'
import Root from '../../../../StyledComponents/DividerStyles/DividerStyles'
import { IpContext } from '../../../../contextapi/IpContext'
import TwoFaModal from './components/TwoFaModal'
import { ALERT_MESSAGE, MESSAGE_CONFIRMATION } from '../../../../constants/message'
import NameAlertModal from '../NameAlertModal/NameAlertModal'
import CryptoJS from 'crypto-js'

const Login = () => {
  const ipContextData = useContext(IpContext)
  // User detail form
  const [userDetails, setUserDetails] = useState({
    form: {
      ipAddress: ''
    },
    errors: {
      email: 'error',
      password: 'error'
    }
  })

  // Step2 Infomodal if profile is not completed
  const [openInfoModal, setOpenInfoModal] = useState(false)

  // move to env
  const key = 'lt7i3/JKHPDUtkpfD/Sabw=='

  // Login Data state
  const [user, setUser] = useState({})

  // Verify OTP for Two Factor Authentication
  const [verifyTwoFactorOtp] = useLazyVerifyTwoFactorOtpQuery()
  const [code, setCode] = useState('')
  // otp child counter state
  const [counter, setCounter] = useState(45)

  // Open 2FA otp modal
  const [openTwoFaOtpModal, setOpenTwoFaOtpModal] = useState(false)

  // Password textfield  type[text:password]
  const [showPassword, setShowPassword] = useState(false)

  // Loader state
  const [backdropState, setBackdropState] = useState(false)

  const navigate = useNavigate()

  // Redirect url for social login
  const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URL_SOCIAL

  // Login user Mutation
  const [loginUser] = useLoginUserMutation({})

  // Social login mutation
  const [socialLogin] = useSocialLoginMutation({})

  // Send otp modal
  const [sendOtp] = useLazySendTwoFactorOtpQuery()

  // Message Modal to inform user regarding his social profile name
  const [messageModal, setMessageModal] = useState({
    open: false,
    provider: '',
    title: ALERT_MESSAGE?.IMPORTANTNOTE,
    message: MESSAGE_CONFIRMATION?.SOCIALLOGINNAME,
    cancelButtonText: 'Cancel',
    submitButtonText: 'Continue'
  })

  // Using this to set the IP Address value in the user detail form
  useEffect(() => {
    if (ipContextData?.currentUserIpAddress) {
      setUserDetails((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          ipAddress: ipContextData?.currentUserIpAddress
        }
      }))
    }
  }, [ipContextData])

  /**
     * Handle social Login
     * @param {string} provider:Social login provider[google,linkdln,facebook]
     * @param {object} data : User information object
     */
  const handleSocialLogin = (provider, data) => {
    console.log('provider Object:LOGIN:SOCIALLOGIN CALLED', provider, data)
    const modifedData = socialData(provider, data) // Payload creation for social login

    socialLogin({ payload: modifedData })
      .unwrap()
      .then((response) => {
        console.log(response?.message, response, 'fulfil:SOcialLOgin:success:LOGIN')
        setSession('token', response?.token)
        setSession('user', JSON.stringify(response?.data))
        toast.success(response.message)

        navigate('/Home', {
          state: {
            HeaderTitle: 'Home'
          }
        })
      })
      .catch((execption) => {
        setBackdropState(false)
        navigate('/signup', { state: { socialData: modifedData } })
        console.log('exception:SOcialLOgin:fail:LOGIN', execption)
        // toast.error(execption.message)
      })
  }

  // Function called on social login click to start loader
  const socialStart = () => {
    setBackdropState(true)

    setTimeout(() => {
      setBackdropState(false)
    }, 8000)
  }

  /**
     * Change values in userDeatils
     * @param {objct} event:field details
     */
  const handleuserDetails = (event) => {
    const err = validator(event.target.name, event.target.value)
    // if the event is password store the encrypted password
    if (event.target.name === 'password') {
      const encryptedPassword = encrypt(event.target.value, key)
      setUserDetails((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          [event.target.name]: encryptedPassword
        },
        errors: {
          ...prev.errors,
          [event.target.name]: 'noerror'
        }
      }))
    } else {
      setUserDetails((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          [event.target.name]: event.target.value
        },
        errors: {
          ...prev.errors,
          [event.target.name]: err
        }
      }))
    }
  }

  /*
  const validateForm = () => {
    Object.entries(userDetails.form).forEach(([key, value]) => {
      let err = validator(key, value,);
      setUserDetails((prev) => ({
        ...prev,
        errors: {
          ...prev.errors,
          [key]: err,
        },
      }));
      console.log(userDetails);
    });
  }; */
  // Handle Verify Two Factor OTP
  const handleVerifyOtp = () => {
    setBackdropState(true)
    verifyTwoFactorOtp(code)
      .unwrap()
      .then((response) => {
        setSession('user', JSON.stringify(user))
        setBackdropState(false)
        toast.success(response.message)
        setOpenTwoFaOtpModal(false)
        navigate('/Home', {
          state: {
            HeaderTitle: 'Home'
          }
        })
        // // condition for managing the visible screen in cases between isSubscription and isProfileVerified
        // if (
        //   !user?.isSubscription &&
        //             !user?.isProfileVerified &&
        //             (user?.role === USER_ROLE?.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase ||
        //                 user?.role === USER_ROLE?.CORPORATE.toUpperCase())
        // ) {
        //   navigate('/Home', {
        //     state: {
        //       HeaderTitle: 'Home'
        //     }
        //   })
        // } else if (
        //   user?.isProfileVerified &&
        //             !user?.isSubscription &&
        //             (user?.role === USER_ROLE?.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase ||
        //                 user?.role === USER_ROLE?.CORPORATE.toUpperCase())
        // ) {
        //   // navigate subscription
        //   navigate('/subscription')
        // } else {
        //   navigate('/Home', {
        //     state: {
        //       HeaderTitle: 'Home'
        //     }
        //   })
        // }

        //   if (
        //     !user?.isSubscription &&
        //               (user?.role === ROLES?.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase() ||
        //                   user?.role === ROLES?.CORPORATE.toUpperCase())
        //   ) {
        //     navigate('/subscription')
        //   } else {
        //     navigate('/Home', {
        //       state: {
        //         HeaderTitle: 'Home'
        //       }
        //     })
        //   }
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('execption:OTP VERIFICATION')
      })
  }

  // Handle form submit
  const handleSubmit = async () => {
    if (userDetails?.errors?.email === 'noerror') {
      setBackdropState(true)
      loginUser({ payload: userDetails.form })
        .unwrap()
        .then((response) => {
          // toast.success(response.message)
          setBackdropState(false)
          console.log(response, 'fulfil:LOGIN')
          setSession('token', response?.token)
          const responseData = response?.data
          if (responseData?.isProfileCompleted) {
            setUser(responseData)
            // setSession('user', JSON.stringify(responseData))
            // open Two FA modal
            setOpenTwoFaOtpModal(true)
            sendOtpManually()
          } else setOpenInfoModal(true)
        })
        .catch((execption) => {
          setBackdropState(false)
          console.log('exception:LOGIN:FAIL', execption)
          // toast.error(execption.message)
        })
    }
  }

  const encrypt = (password, key) => {
    const encrypted = CryptoJS.AES.encrypt(password, CryptoJS.enc.Utf8.parse(key), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString()
    return encrypted
  }

  const sendOtpManually = () => {
    // const dataToSend = {
    //   phoneNumber
    // }
    sendOtp()
      .unwrap()
      .then((response) => {
        setCode('')
        setCounter(60)
      })
      .catch((execption) => {
        console.log('error')
        toast.error(execption.message)
      })
  }

  const handleContinueClick = () => {
    // close modal
  }

  const handlePreventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }

  return (
        <Grid item md={6} className="loginRightGrid">
            <div className="loginHead">
                <Typography variant="labelLarge" sx={{ lineHeight: '43px' }}>
                    Login
                </Typography>

                {/* Social Logins */}
                <div className="socialIcons">
                    <LoginSocialLinkedin
                        onLoginStart={socialStart}
                        client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                        client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                        redirect_uri={REDIRECT_URI || ''}
                        scope="r_liteprofile"
                        onResolve={({ provider, data }) => {
                          handleSocialLogin(provider, data)
                          console.log('Linkedin reponse', provider, data)
                        }}
                        onReject={(err) => {
                          setBackdropState(false)
                          console.log(err)
                        }}>
                        {/* <img
                            alt="linkedln"
                            src={linkedln}
                            className="social-logo"
                            style={{
                              borderRadius: '20px',
                              height: '40px',
                              width: '40px',
                              marginRight: '10px',
                              cursor: 'pointer'
                            }}
                        /> */}
                    </LoginSocialLinkedin>

                    {/* <Gmail /> */}
                    <LoginSocialGoogle
                        // onClick={socialStart}
                        isOnlyGetCode
                        client_id={process.env.REACT_APP_GG_APP_ID || ''}
                        onResolve={({ provider, data }) => {
                          setBackdropState(false)
                          handleSocialLogin(provider, data)
                        }}
                        scope="openid profile email"
                        onReject={(err) => {
                          console.log(err)
                        }}>
                        <img
                            alt="gmail"
                            src={gmail}
                            style={{
                              borderRadius: '20px',
                              height: '40px',
                              width: '40px',
                              marginRight: '10px',
                              cursor: 'pointer'
                            }}
                        />
                    </LoginSocialGoogle>
                    <LoginSocialFacebook
                        appId={process.env.REACT_APP_FB_APP_ID || ''}
                        onResolve={({ provider, data }) => {
                          console.log(provider, data)
                          handleSocialLogin(provider, data)
                        }}
                        onReject={(err) => {
                          console.log(err)
                        }}>
                        <img
                            alt="facebook"
                            src={facebook}
                            style={{
                              borderRadius: '20px',
                              height: '40px',
                              width: '40px',
                              cursor: 'pointer'
                            }}
                        />
                    </LoginSocialFacebook>
                </div>
            </div>

            <Root>
                <Divider style={{ marginBottom: '42px' }}>Or</Divider>
            </Root>

            <form
                onSubmit={(event) => {
                  event.preventDefault()
                  handleSubmit()
                  // checkEncryptPassword();
                }}>
                <TextField
                    fullWidth
                    error={
                        !(
                          userDetails?.errors?.email === 'error' ||
                            userDetails?.errors?.email === 'noerror'
                        )
                    }
                    helperText={
                        userDetails?.errors?.email === 'error' ||
                        userDetails?.errors?.email === 'noerror'
                          ? ''
                          : userDetails?.errors?.email
                    }
                    required
                    id="email"
                    label="E-mail address"
                    style={{ marginBottom: '20px' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    name="email"
                    onChange={(event) => handleuserDetails(event)}
                    className="subvariant-hovered"
                    onKeyDown={handlePreventSpace}
                />

                <TextField
                    fullWidth
                    required
                    name="password"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    style={{ marginBottom: '10px' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? (
                                        <img src={eyeOnIcon} alt="Password" />
                                    ) : (
                                        <img src={eyeOffIcon} alt="Password" />
                                    )}
                                </IconButton>
                            </InputAdornment>
                      )
                    }}
                    onChange={(event) => handleuserDetails(event)}
                    onKeyDown={handlePreventSpace}
                />
                <div className="textForgotPassword">
                    <Link to="/forgot-password" className="text-link">
                        Forgot Password
                    </Link>
                </div>
                <Button fullWidth variant="primary" type="submit">
                    Login
                </Button>
            </form>
            <div className="loginFoot">
                <Typography variant="modalLabel" sx={{ fontSize: '18px' }}>
                    Don&apos;t have an account?&nbsp;
                    <Link className="textSignup" to="/signup">
                        Signup
                    </Link>
                </Typography>
            </div>

            {/* Extra information Modal */}
            <ExtraInfoModal
                openInfoModal={openInfoModal}
                handleExtraInfoClose={() => setOpenInfoModal(false)}
            />

            {/* Two Factor Otp Modal */}
            {
                <TwoFaModal
                    code={code}
                    setCode={setCode}
                    counter={counter}
                    setCounter={setCounter}
                    handleVerifyOtp={handleVerifyOtp}
                    openOtpModal={openTwoFaOtpModal}
                    handleOtpModalClose={() => setOpenTwoFaOtpModal(false)}
                />
            }
            {/* Importnt Information Modal */}
            {messageModal?.open && (
                <NameAlertModal
                    handleOkButtonCommonModal={handleContinueClick}
                    handleCancelButton={() => {
                      setMessageModal((prev) => ({
                        ...prev,
                        open: false
                      }))
                    }}
                    CommonMessageModalState={messageModal}
                    handleSocialLogin={handleSocialLogin}
                />
            )}
            <BackdropLoader backdropState={backdropState} />
        </Grid>
  )
}

export default Login
