import React, { useState } from 'react'
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

import deleteicon from '../../../../../assets/actions/actionDelete.svg'
import emailicon from '../../../../../assets/actions/actionEmail.svg'
import editicon from '../../../../../assets/actions/actionEdit.svg'
import cross from '../../../../../assets/images/cross.svg'
import checked from '../../../../../assets/images/checked.svg'
import PropTypes from 'prop-types'
import { useSendDocumentForReviewMutation } from '../../../../../store/services/documentServices'
import { DOCUEMENTFILTERSTATUS } from '../../../../../constants/documentConstans'
import { toast } from 'react-toastify'
import BackdropLoader from '../../../../../components/BackdropLoader/BackdropLoader'
import CustomErrorTooltip from '../../../../../components/Tooltip/CustomErrorTooltip'
import { ALERT_MESSAGE } from '../../../../../constants/message'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#003A51',
    color: '#FFFFFF',
    fontSize: '14x',
    fontWeight: 400
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#FFFFFF',
    color: '#555555',
    fontSize: '14px',
    fontWeight: 400
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const ReviewTable = ({
  documentReviewer,
  handleDeleteReviewer,
  documentId,
  documentStatus,
  handleEditReviewer
}) => {
  const tableHeaders = [
    'Parties',
    'Name',
    'Email',
    'Address',
    'Phone',
    'Priority',
    'Action',
    'Status'
  ]

  const [openTooltipId, setOpenTooltipId] = useState(null)
  // Send document for review
  const [sendDocumentForReview] = useSendDocumentForReviewMutation({})

  // Loader state
  const [backdropState, setBackdropState] = useState(false)

  /**
     * Handle Resend  Review invite mail
     * @param {array} reviewerData: Selected reviewer data to resend invite mail
     */
  const handleResendInviteReview = (reviewerData) => {
    console.log(reviewerData)
    setBackdropState(true)
    const dataToSend = {
      reviewerList: reviewerData,
      action: 'resend-mail'
      // stampDutyPaidBy: stampDutyInformation?.stampDutyPaid
      //   ? stampDutyInformation?.stampDutyPaid
      //   : null,
      // stampDutyPurchasedBy: stampDutyInformation?.stampDutyPurchased
      //   ? stampDutyInformation?.stampDutyPurchased
      //   : null
    }
    sendDocumentForReview({ payload: dataToSend, documentId })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
        // console.log('payment Status', paymentStatus)
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
      })
  }

  return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {tableHeaders?.map((index) => (
                            <StyledTableCell key={index} align="left">
                                {index}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documentReviewer?.map((item, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell scope="row">{item?.partyType?.name}</StyledTableCell>
                            <StyledTableCell scope="row">{item?.name}</StyledTableCell>
                            <StyledTableCell align="left">{item?.email}</StyledTableCell>
                            <StyledTableCell align="left">{item?.address}</StyledTableCell>
                            <StyledTableCell align="left">{item?.phone}</StyledTableCell>
                            <StyledTableCell align="left">{item?.priority}</StyledTableCell>
                            <StyledTableCell align="left">
                                <div className="actionIcons">
                                    {documentStatus?.id !== DOCUEMENTFILTERSTATUS?.INDRAFT.ID &&
                                        item?.status?.id !==
                                            DOCUEMENTFILTERSTATUS?.CONSENTSUBMITTED?.ID && (
                                            <img
                                                src={emailicon}
                                                alt="emailicon"
                                                title="Resend mail"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleResendInviteReview([item])}
                                            />
                                    )}
                                    {documentStatus?.id === DOCUEMENTFILTERSTATUS?.INDRAFT.ID && (
                                        <img
                                            onClick={() => handleDeleteReviewer(item)}
                                            src={deleteicon}
                                            alt="deleteIcon"
                                            title="Delete reviewer"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                    {documentStatus?.id === DOCUEMENTFILTERSTATUS?.INDRAFT.ID && (
                                        <img
                                            onClick={() => handleEditReviewer(item)}
                                            src={editicon}
                                            alt="editIcon"
                                            title="Edit reviewer"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <div className="actionIcons">
                                    {item.isError && (
                                        <CustomErrorTooltip
                                            information={{
                                              message:
                                                    ALERT_MESSAGE.REVIEWCONSENTCOMPLETEINFORMATION,
                                              id: item.id
                                            }}
                                            openTooltipId={openTooltipId}
                                            handleTooltipOpen={() => setOpenTooltipId(item.id)}
                                            handleTooltipClose={() => setOpenTooltipId(null)}
                                        />
                                    )}
                                    {item?.status?.id ===
                                        DOCUEMENTFILTERSTATUS?.CONSENTSUBMITTED?.ID && (
                                        <img
                                            src={checked}
                                            alt="checked"
                                            title="Submitted"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                    {item?.status?.id !==
                                        DOCUEMENTFILTERSTATUS?.CONSENTSUBMITTED?.ID &&
                                        !item?.isError && (
                                            <img
                                                src={cross}
                                                alt="deleteIcon"
                                                title="Requested"
                                                style={{ cursor: 'pointer' }}
                                            />
                                    )}
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </TableContainer>
  )
}

export default ReviewTable

ReviewTable.propTypes = {
  documentReviewer: PropTypes.array,
  handleDeleteReviewer: PropTypes.func,
  documentId: PropTypes.string,
  documentStatus: PropTypes.object,
  handleEditReviewer: PropTypes.func
}
