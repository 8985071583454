import React, { createContext, useState, useEffect } from 'react'
import { useGetStateQuery } from '../store/services/userServices'
import PropTypes from 'prop-types'

// Create the context
export const StateContext = createContext()

// Provider component
export const StateProvider = ({ children }) => {
  // State to hold the stateList data
  const [stateList, setStateList] = useState({})
  const { data: stateArrayData = [], isSuccess } = useGetStateQuery({})
  useEffect(() => {
    if (stateArrayData && isSuccess) {
      setStateList(stateArrayData)
    }
  }, [stateArrayData, isSuccess])

  // Provide the stateList and updatestateList function to the children components
  return (
        <StateContext.Provider value={{ stateList, setStateList }}>
            {children}
        </StateContext.Provider>
  )
}
StateProvider.propTypes = {
  children: PropTypes.node
}
