import React, { useEffect } from 'react'
import { MenuItem, Select, Typography, FormControl, FormHelperText } from '@mui/material'
import PropTypes from 'prop-types'

const DropdownField = ({
  selectData,
  fieldLabel,
  onchange,
  onfocus,
  documentForm,
  sectionIndex
}) => {
  useEffect(() => {
    // check prefilled value
    const prefilledValue = selectData?.props?.prefilled
    // Need to check whether the value is already present in the form or not, so that prefilled triggers onchange only when not present in form
    if (prefilledValue && !documentForm?.form[`${selectData?.id}`]) {
      const selectedOption = JSON.parse(selectData?.props?.options.replace(/'/g, '"')).find(
        (item) => `${item.id}` === prefilledValue
      )

      if (selectedOption) {
        onchange(selectData, selectedOption)
      }
    }
  }, [selectData?.props?.prefilled])

  return (
        <div className="FieldBox">
            <div className="FieldBoxLabel">
                <Typography variant="darkValue">{fieldLabel}</Typography>
            </div>
            <div className="FieldBoxInput">
                <FormControl fullWidth error={documentForm?.errors[`${selectData?.id}`]}>
                    <Select
                        fullWidth
                        name={`span${selectData?.id}`}
                        value={
                            !documentForm?.form[`${selectData?.id}`] &&
                            documentForm?.form[`${selectData?.id}`] !== ''
                              ? selectData?.props?.prefilled
                              : documentForm?.form[`${selectData?.id}`]
                        }
                        onOpen={(event) => onfocus(event, selectData)}
                        onChange={(event) => {
                          const selectedId = event.target.value // Get the selected id
                          const selectedOption = JSON.parse(
                            selectData?.props?.options.replace(/'/g, '"')
                          ).find((item) => item.id === selectedId) // Find the selected object
                          onchange(selectData, selectedOption)
                        }}
                        displayEmpty
                        placeholder={fieldLabel}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ fontSize: '16px' }}>
                        {/* Placeholder option */}
                        <MenuItem disabled value="">
                            <span style={{ color: '#999' }}>
                                {fieldLabel || 'Select party type'}
                            </span>
                        </MenuItem>
                        {JSON.parse(selectData?.props?.options.replace(/'/g, '"')).length > 0 &&
                            JSON.parse(selectData?.props?.options.replace(/'/g, '"')).map(
                              (item) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={item.id}
                                        value={item.id}>
                                        {item.value}
                                    </MenuItem>
                              )
                            )}
                    </Select>
                    <FormHelperText>{documentForm?.errors[`${selectData?.id}`]}</FormHelperText>
                </FormControl>
            </div>
        </div>
  )
}

export default DropdownField

DropdownField.propTypes = {
  fieldLabel: PropTypes.string,
  onchange: PropTypes.func,
  onfocus: PropTypes.func,
  selectData: PropTypes.object,
  documentForm: PropTypes.object,
  sectionIndex: PropTypes.number
}
