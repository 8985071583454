/* eslint-disable */
import React, { useState } from 'react';
import './index.css';
import { TextField, Typography, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

// import { PATTERN } from '../../constants/enum'

const TimePickerField = ({
    fieldLabel,
    onchange,
    onfocus,
    textFieldData,
    documentForm,
    sectionIndex
}) => {
    const [value, setValue] = useState(() => {
        const initialTime =
            documentForm?.form[`${textFieldData?.id}`] || textFieldData?.props?.prefilled;
        return initialTime ? dayjs(initialTime, 'hh:mm A') : null;
    });

    const handleTimeChange = (event) => {
        if (!event) {
            // If the input is cleared, set the value to null
            setValue(null);
            onchange(null, textFieldData); // Optionally pass null or empty string to onchange
        } else {
            setValue(event);
            onchange(event, textFieldData);
        }
    };

    return (
        <div className="FieldBox">
            <div className="FieldBoxLabel">
                <Typography variant="darkValue">{fieldLabel}</Typography>
            </div>
            <div className="FieldBoxInput">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        id={textFieldData?.id}
                        name={`span${textFieldData?.id}`}
                        value={value}
                        required
                        slotProps={{
                            textField: {
                                helperText: documentForm?.errors[`${textFieldData?.id}`],
                                error: documentForm?.errors[`${textFieldData?.id}`],
                                onFocus: (event) => onfocus(event, textFieldData)
                            }
                        }}
                        sx={{ width: '100%' }}
                        onChange={(newValue) => handleTimeChange(newValue)}
                    />
                </LocalizationProvider>
            </div>
        </div>
    );
};

export default TimePickerField;

TimePickerField.propTypes = {
    fieldLabel: PropTypes.string,
    onchange: PropTypes.func,
    onfocus: PropTypes.func,
    textFieldData: PropTypes.object,
    documentForm: PropTypes.object,
    sectionIndex: PropTypes.number
};
