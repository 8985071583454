import axios from 'axios'
import { parse, stringify } from 'qs'
import { toast } from 'react-toastify'
import { ERRORCODES } from '../constants/enum'
// import { useNavigate } from 'react-router-dom'

const AxiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
      async ({
        method = '',
        url = '',
        params = null,
        payload = null,
        fileUpload = null,
        isEsignDocument = null,
        getFile = null,
        isUploadDigitalSign = null,
        isSupportedCoDocs = null,
        isUploadDocument = null,
        responseType = null,
        noerror = null
      }) => {
        // const navigate = useNavigate()
        const submitParams = {}
        if (params != null) {
          for (const [key, value] of Object.entries(params)) {
            if (value == null || value.length === 0 || value === '') {
              continue
            }
            submitParams[key] = value
          }
        }

        const formsData = new FormData()
        if (isSupportedCoDocs) {
          formsData.append('file', payload.file)
          formsData.append('type', payload.type)

          //   for (const key in payload) {
          //     console.log("key",key)
          //     formsData.append(`${key}`, payload[`${key}`]);
          //     // console.log(`${key}: ${user[key]}`);
          // }
        }
        if (isUploadDocument) {
          for (const key in payload) {
            formsData.append(key, payload[key])
          }
        }

        if (isUploadDigitalSign) {
          for (const key in payload) {
            formsData.append(key, payload[key])
          }
        }

        if (isEsignDocument) {
          for (const key in payload) {
            console.log('im here')
            if (key === 'file') {
              formsData.append(key, payload[key], `outputpdf${new Date().getTime()}.pdf`)
            } else {
              formsData.append(key, payload[key])
            }
          }
        }

        const options = {
          method,
          url: baseUrl + url,
          responseType: responseType ?? 'application/json; charset=utf-8',
          headers: {
            Authorization: sessionStorage.getItem('token')
              ? `Bearer ${sessionStorage.getItem('token')}`
              : '',
            'Content-Type': fileUpload
              ? 'multipart/form-data'
              : 'application/json; charset=utf-8',
            'ngrok-skip-browser-warning': 'any value'
            // 'Content-Security-Policy':
            //         "default-src 'self'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; style-src 'self' 'unsafe-inline'",
            // 'X-Content-Type-Options': 'nosniff',
            // 'X-Frame-Options': 'SAMEORIGIN',
            // 'X-XSS-Protection': '1; mode=block',
            // 'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
            // 'Referrer-Policy': 'strict-origin'
          },
          params: submitParams,
          data: fileUpload ? formsData : payload,
          paramsSerializer: {
            encode: parse,
            serialize: stringify
          },
          transformResponse: [
            function (data) {
              if (getFile) {
                return data
              } else {
                return JSON.parse(data)
              }
            }
          ]
        }
        try {
          const result = await axios.request(options)
          return result
        } catch (axiosError) {
          // console.log('axios error', axiosError)
          const err = axiosError
          // console.log('err.resperr.requestonse', err?.request, err?.response)
          if (err?.request?.status === ERRORCODES?.UNAUTHORIZED?.STATUSCODE) {
            console.log('I am here=====> axios')
            window.location.href = '/session-expired'
            return sessionStorage.clear()
            // navigate('/')
          } else if (err?.request?.status === 511) {
            window.location.href = '/unauthorizedDocument-access'
          } else {
            if (noerror === null || noerror === undefined) {
              toast.error(err?.response?.data?.message)
            }
            return {
              error: {
                status: err?.response?.data?.statusCode,
                message: err?.response?.data?.message || ''
              }
            }
          }
        }
      }

export default AxiosBaseQuery
