/* eslint-disable */
import React, { useContext, useState } from 'react';
import {
    Modal,
    Box,
    Grid,
    Typography,
    FormControl,
    RadioGroup,
    Radio,
    TextField,
    Button,
    FormControlLabel,
    MenuItem,
    Select,
    Tooltip
} from '@mui/material';
import info from '../../../../assets/images/info.svg';
import { USER_ROLE, EXCEPTTHISSYMBOLS, CLVALIDATIONS } from '../../../../constants/enum';
import {
    useExtraInfoMutation,
    useGetCityQuery,
    useGetStateQuery
} from '../../../../store/services/userServices';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import CorporateTabs from '../CorporateTabs/CorporateTabs';
import { validator } from '../../../../util/validator';
import { useNavigate } from 'react-router-dom';
import { setSession } from '../../../../util/helper';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import MenuProps from '../../../../StyledComponents/SelectStyles/SelectStyles';
import palette from '../../../../theme/palette';
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader';
import { IpContext } from '../../../../contextapi/IpContext';
import moment from 'moment';

// Modal box style
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'scroll',
    width: 660,
    height: 457,
    background: palette.primary.light,
    boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark}, 0.06)`,
    borderRadius: '3px',
    p: 4
};

export default function ExtraInfoModal(props) {
    const navigate = useNavigate();

    const ipContextData = useContext(IpContext);

    // Except this symbols in  type number textfield
    const exceptThisSymbols = EXCEPTTHISSYMBOLS;
    const ip = ipContextData?.currentUserIpAddress;

    // State for open confirmation modal
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    // Backdrop loader state
    const [backdropState, setBackdropState] = useState(false);

    const [value, setValue] = useState(0);

    // Files uploaded
    const [myFiles, setMyFiles] = useState({});

    // Form state
    const [infoForm, setinfoForm] = useState({
        form: {
            role: USER_ROLE.INDIVIDUAL,
            stockBroker: 'no',
            dateOfIncorporation: '',
            isBoardResolutionAccepted: false
        },
        errors: {
            panCard: 'error',
            referralEmail: 'error',
            companyName: 'error',
            contactPerson: 'error'
        }
    });

    // Submit form mutation
    const [extraInfo] = useExtraInfoMutation({});

    // Get all states query
    const { data: stateArray = [] } = useGetStateQuery({});

    // Get all cities according to state selected
    const { data: cityArray = [] } = useGetCityQuery(
        { stateId: infoForm.form.state },
        {
            skip: !infoForm.form.state
        }
    );

    // User role array
    const userArray = [USER_ROLE.INDIVIDUAL, USER_ROLE.CORPORATE, USER_ROLE.BUSINESS_ASSOCIATE];

    /**
     * Handle form change
     * @param {object} event: Event object
     */
    const handleInfoForm = (event) => {
        const err = validator(event.target.name, event.target.value);
        setinfoForm((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                [event.target.name]: event.target.value
            },
            errors: {
                ...prev.errors,
                [event.target.name]: err
            }
        }));
        console.log(infoForm);
    };

    // Close extra info modal
    const handleAllClose = () => {
        props.handleExtraInfoClose();
        navigate('/');
    };

    // Handle extra info modal submit
    const handleSubmit = () => {
        setBackdropState(true);
        console.log('infoform:SUbmitBefor', infoForm);
        const dataToSend = {
            ipAddress: ip,
            role:
                infoForm?.form.role === USER_ROLE.BUSINESS_ASSOCIATE
                    ? 'BUSINESS'
                    : infoForm?.form.role.toUpperCase(),
            address: infoForm?.form.address,
            stateId: infoForm?.form.state,
            cityId: infoForm?.form.city,
            pinCode: infoForm?.form.pinCode,
            companyName: infoForm?.form.companyName,
            contactPersonName: infoForm?.form?.contactPerson,
            companyAddress: infoForm?.form.companyAddress,
            companyLandline: infoForm?.form.companyLandline,
            // companyCode: infoForm?.form?.companyCode,
            // companyRegistrationDate: infoForm?.form.companyRegistrationDate,
            panNumber: infoForm?.form.panCard,
            dateOfIncorporation: moment(infoForm?.form.dateOfIncorporation).format('DD-MM-YYYY'),
            moaId: infoForm?.moa,
            poaId: infoForm?.poa,
            aoaId: infoForm?.aoa,
            brId: infoForm?.br,
            panId: infoForm?.pan,
            sebiId: infoForm?.sebi,
            gstId: infoForm?.gst,
            latitude: 0.0,
            longitude: 0.0,
            isBoardResolutionAccepted: infoForm?.form?.isBoardResolutionAccepted,
            isBroker: infoForm?.form.stockBroker === 'yes',
            isPanExist: infoForm?.isPanExist || false,
            referralEmail: infoForm?.form?.referralEmail || ''
        };
        extraInfo({ payload: dataToSend })
            .unwrap()
            .then((response) => {
                props.handleExtraInfoClose();
                setSession('user', JSON.stringify(response?.data));
                setBackdropState(false);
                if (
                    !response?.data?.isSubscription &&
                    !response?.data?.isProfileVerified &&
                    (response?.data?.role ===
                        USER_ROLE?.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase ||
                        response?.data?.role === USER_ROLE?.CORPORATE.toUpperCase())
                ) {
                    navigate('/Home', {
                        state: {
                            HeaderTitle: 'Home'
                        }
                    });
                } else if (
                    response?.data?.isProfileVerified &&
                    !response?.data?.isSubscription &&
                    (response?.data?.role ===
                        USER_ROLE?.BUSINESS_ASSOCIATE.split(' ')[0].toUpperCase ||
                        response?.data?.role === USER_ROLE?.CORPORATE.toUpperCase())
                ) {
                    // navigate subscription
                    navigate('/subscription');
                } else {
                    navigate('/Home', {
                        state: {
                            HeaderTitle: 'Home'
                        }
                    });
                }
                toast.success(response?.message);
            })
            .catch((execption) => {
                setBackdropState(false);
                // toast.error(execption?.message)
            });
    };

    /**
     * Handle prevent space
     * @param {Object} event: Event object
     */
    const handlePreventSpace = (event) => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    };

    return (
        <div>
            <Modal
                id="corporateModal"
                className="MuiBackdrop-root"
                open={props.openInfoModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid container direction="row" justify-content="flex-start">
                        {/* <Tooltip title="Hi some imp instructions" arrow>
                            <img alt="info" src={info} />
                        </Tooltip> */}
                        <Typography variant="modalLabel" sx={{ marginLeft: '10px' }}>
                            Select user role
                        </Typography>
                        <Typography
                            sx={{
                                marginLeft: '10px',
                                color: palette.text.dark,
                                fontStyle: 'italic',
                                fontWeight: 400,
                                fontSize: '14px'
                            }}>
                            (Default as Individiual)
                        </Typography>
                    </Grid>
                    <Grid sx={{ padding: '20px 0px 0px 0px' }}>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="role"
                                value={infoForm?.form?.role}
                                onChange={handleInfoForm}>
                                {userArray &&
                                    userArray.map((user) => (
                                        <FormControlLabel
                                            sx={{
                                                marginRight: '50px',
                                                marginBottom: '30px'
                                            }}
                                            key={user}
                                            value={user}
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: palette.text.main,
                                                        '&.Mui-checked': {
                                                            color: palette.success.dark
                                                        }
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    variant="labelSmall"
                                                    sx={{
                                                        color: palette.text.dark,
                                                        fontSize: '14px'
                                                    }}>
                                                    {user}
                                                </Typography>
                                            }
                                        />
                                    ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {infoForm?.form?.role === USER_ROLE.CORPORATE && (
                        <Grid container marginBottom={5}>
                            <Grid item lg={12}>
                                <CorporateTabs
                                    value={value}
                                    setValue={setValue}
                                    infoForm={infoForm}
                                    setinfoForm={setinfoForm}
                                    setOpenConfirmationModal={setOpenConfirmationModal}
                                    myFiles={myFiles}
                                    setMyFiles={setMyFiles}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {infoForm?.form?.role !== USER_ROLE.CORPORATE && (
                        <Grid container spacing={3} marginBottom={5}>
                            <Grid item xs={12}>
                                <TextField
                                    type="text"
                                    value={infoForm?.form.address || ''}
                                    id="address"
                                    name="address"
                                    label={
                                        infoForm.form.role === USER_ROLE.INDIVIDUAL
                                            ? 'Address'
                                            : 'Company Address'
                                    }
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        type: 'text',
                                        maxlength: CLVALIDATIONS?.ADDRESS?.MAXLENGTH,
                                        minlength: CLVALIDATIONS?.ADDRESS?.MINLENGTH
                                    }}
                                    fullWidth
                                    onChange={handleInfoForm}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <Select
                                        sx={{ fontSize: '16px' }}
                                        value={infoForm.form.state || ''}
                                        labelId="state"
                                        name="state"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        onChange={(event) => {
                                            setinfoForm((prev) => ({
                                                ...prev,
                                                form: {
                                                    ...prev.form,
                                                    city: '',
                                                    [event.target.name]: event.target.value
                                                }
                                            }));
                                        }}
                                        MenuProps={MenuProps}>
                                        <MenuItem value="" disabled>
                                            <Typography>Select State</Typography>
                                        </MenuItem>
                                        {stateArray &&
                                            stateArray.map((item) => (
                                                <MenuItem
                                                    sx={{ fontSize: '16px' }}
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <Select
                                        sx={{ fontSize: '16px' }}
                                        value={infoForm.form.city || ''}
                                        disabled={!infoForm.form.state}
                                        labelId="city"
                                        id="city"
                                        name="city"
                                        onChange={handleInfoForm}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        displayEmpty
                                        MenuProps={MenuProps}>
                                        <MenuItem value="" disabled>
                                            <Typography>Select City</Typography>
                                        </MenuItem>
                                        {cityArray &&
                                            cityArray.map((item) => (
                                                <MenuItem
                                                    sx={{ fontSize: '16px' }}
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    type="number"
                                    value={infoForm?.form.pinCode || ''}
                                    id="pinCode"
                                    name="pinCode"
                                    label="Pin code"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyDown={(e) =>
                                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                                    }
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value))
                                            .toString()
                                            .slice(0, 6);
                                    }}
                                    fullWidth
                                    onChange={handleInfoForm}
                                />
                            </Grid>
                            {infoForm?.form?.role === USER_ROLE.BUSINESS_ASSOCIATE && (
                                <Grid item xs={8}>
                                    <TextField
                                        error={
                                            !(
                                                infoForm?.errors?.referralEmail === 'error' ||
                                                infoForm?.errors?.referralEmail === 'noerror'
                                            )
                                        }
                                        helperText={
                                            infoForm?.errors?.referralEmail === 'error' ||
                                            infoForm?.errors?.referralEmail === 'noerror'
                                                ? ''
                                                : infoForm?.errors?.referralEmail
                                        }
                                        type="email"
                                        value={infoForm?.form.referralEmail || ''}
                                        id="referralEmail"
                                        name="referralEmail"
                                        label="Referral Email"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder="Enter Refferal Email"
                                        fullWidth
                                        onChange={handleInfoForm}
                                        onKeyDown={handlePreventSpace}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}

                    {/* Buttons */}
                    {((infoForm?.form.role === USER_ROLE?.CORPORATE && value) ||
                        infoForm?.form.role !== USER_ROLE?.CORPORATE) && (
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}>
                            <Grid item>
                                <Button variant="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="cancelButton"
                                    onClick={() => setOpenConfirmationModal(true)}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Modal>

            {/* Confirmation Modal */}
            <ConfirmationModal
                openConfirmationModal={openConfirmationModal}
                handleConfirmationModalClose={() => setOpenConfirmationModal(false)}
                handleAll={handleAllClose}
            />
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </div>
    );
}

ExtraInfoModal.propTypes = {
    openInfoModal: PropTypes.bool,
    handleExtraInfoClose: PropTypes.func
};
