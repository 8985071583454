import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CardHeader, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import palette from '../../theme/palette'

import CustomTooltip from '../Tooltip/CustomTooltip'

export default function CustomCard ({
  headerTitle,
  children,
  action,
  height,
  isToolTip = false,
  message = [],
  openTooltip,
  setOpenTooltip,
  handleTooltipClose,
  handleTooltipOpen
}) {
  return (
        <Card
            sx={{
              border: `0.5px solid ${palette.text.light}`,
              height: { height },
              backgroundColor: palette.primary.light,
              borderRadius: '5px',
              marginBottom: ' 30px'
            }}>
            <CardHeader
                action={action}
                sx={{
                  borderBottom: `0.5px solid ${palette.text.light}`,
                  backgroundColor: palette.secondary.light
                }}
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>{headerTitle}</Typography>
                        {isToolTip && (
                            <CustomTooltip
                                message={message}
                                openTooltip={openTooltip}
                                setOpenTooltip={setOpenTooltip}
                                handleTooltipOpen={handleTooltipOpen}
                                handleTooltipClose={handleTooltipClose}
                            />
                        )}
                    </div>
                }
                titleTypographyProps={{
                  fontWeight: '600',
                  fontSize: '16px',
                  color: palette.text.dark
                }}></CardHeader>
            <CardContent>{children}</CardContent>
        </Card>
  )
}
CustomCard.propTypes = {
  headerTitle: PropTypes?.string,
  children: PropTypes.node,
  action: PropTypes.object,
  height: PropTypes.string,
  isToolTip: PropTypes.bool,
  message: PropTypes.array,
  openTooltip: PropTypes.bool,
  setOpenTooltip: PropTypes.func,
  handleTooltipOpen: PropTypes.func,
  handleTooltipClose: PropTypes.func
}
