import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import cross from '../../../../assets/images/cross.svg'
import PropTypes from 'prop-types'
import { PATTERN, COMMON } from '../../../../constants/enum'
import palette from '../../../../theme/palette'
import CustomTooltip from '../../../../components/Tooltip/CustomTooltip'
import { InfoOutlined } from '@mui/icons-material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}

const AddMoneyModal = ({
  amountToAdd,
  setAmountToAdd,
  openAddMoneyModal,
  handleAddMoneyModalClose,
  handleSubmitAddMoneyModal
}) => {
  /**
     * Handle amount change
     * @param {object} event:Event object
     */

  // MDR tooltip
  const [openMdrTooltip, setMdrTooltip] = useState(false)

  const handleMdrTooltipOpen = () => setMdrTooltip(true)
  const handleMdrTooltipClose = () => setMdrTooltip(false)
  const handleAmountChange = (event) => {
    setAmountToAdd(event.target.value)
  }
  return (
        <div>
            <Modal
                open={openAddMoneyModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleAddMoneyModalClose}>
                        <img src={cross} alt="cross" height="21px" width="21px" />
                    </Grid>
                    <form
                        onSubmit={(e) => {
                          e.preventDefault()
                          handleSubmitAddMoneyModal()
                        }}>
                        <Grid pl={2}>
                            <Typography
                                variant="labelLarge"
                                component="div"
                                sx={{ fontSize: '25px', marginBottom: '10px', fontWeight: 600 }}>
                                Add money to wallet
                            </Typography>
                            <Grid mb={3}>
                                <Typography
                                    variant="darkValue"
                                    sx={{ fontSize: '15px', lineHeight: '31px' }}>
                                    Enter amount to be added
                                </Typography>
                                <TextField
                                    value={amountToAdd}
                                    type="text"
                                    name="money"
                                    id="money"
                                    fullWidth
                                    required
                                    InputProps={{
                                      startAdornment: (
                                            <InputAdornment position="start">
                                                {COMMON?.RUPPES}
                                            </InputAdornment>
                                      ),
                                      type: 'text',
                                      maxlength: 15,
                                      minlength: 1
                                    }}
                                    onChange={(event) => {
                                      if (
                                        event.target.value === '' ||
                                            PATTERN?.NUMBERONLY.test(event?.target?.value)
                                      ) {
                                        handleAmountChange(event)
                                      }
                                    }}
                                />
                            </Grid>
                            <FormControlLabel
                                sx={{ mb: '15px', mr: 0 }}
                                control={
                                    <Checkbox
                                        onClick={(event) => {}}
                                        sx={{
                                          color: 'var(--g2)',
                                          '&.Mui-checked': {
                                            color: '#33AFB9'
                                          }
                                        }}
                                        required
                                        name="paymentTerms"
                                        id="paymentTerms"
                                    />
                                }
                                label={
                                    <Typography variant="modalLabel" sx={{ color: '#555555' }}>
                                        I agree, MDR charges{' '}
                                        <CustomTooltip
                                            message={[
                                              'Merchant Discount Rate, for details please refer FAQ section'
                                            ]}
                                            openTooltip={openMdrTooltip}
                                            setOpenTooltip={setMdrTooltip}
                                            handleTooltipOpen={handleMdrTooltipOpen}
                                            handleTooltipClose={handleMdrTooltipClose}>
                                            <InfoOutlined
                                                sx={{
                                                  fontSize: 18,
                                                  color: palette.text.main,
                                                  cursor: 'pointer',
                                                  marginLeft: '5px'
                                                }}
                                            />
                                        </CustomTooltip>{' '}
                                        will be added by payment gateway on top of amount shown.
                                    </Typography>
                                }
                            />

                            <Button variant="primary" type="submit" sx={{ marginBottom: '10px' }}>
                                Proceed to add money
                            </Button>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
  )
}

export default AddMoneyModal

AddMoneyModal.propTypes = {
  amountToAdd: PropTypes.string,
  setAmountToAdd: PropTypes.func,
  openAddMoneyModal: PropTypes.bool,
  handleAddMoneyModalClose: PropTypes.func,
  handleSubmitAddMoneyModal: PropTypes.func
}
