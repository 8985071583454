module.exports = Object.freeze({
  EMAILREQUIRED: 'Email is required!',
  EMAILNOTVALID: 'This is not a valid email format!',
  EMAILPRESENT: 'Email is already added',
  PHONEREQUIRED: 'Phone Number is required!',
  PHONEMINLEN: 'Phone Number at least 10 digit',
  PASSWORDREQUIRED: 'Password is required!',
  PASSWORDUPPERCASE: 'At least one Uppercase',
  PASSWORDLOWERCASE: 'At least one Lowercase',
  PASSWORDDIGIT: 'At least one digit',
  PASSWORDSPECCHAR: 'At least one Special Characters',
  PASSWORDMINLEN: 'At least minumum 8 characters',
  CONFIRMPASSREQUIRED: 'Confirm Password is required',
  CONFIRMPASSNOMATCH: 'Password and Confirm Password does not match.',
  PANREQUIRED: 'Pan Number is required!',
  PANNOTVALID: 'This is not a valid pan number',
  IFSCCODEREQUIRED: 'IFSC Code is required!',
  IFSCCODENOTVAL: 'This is not a valid IFSC Code',
  IFSCCODEMINLEN: 'IFSC code at least 11 digit',
  NOERROR: 'noerror',
  ACTION_VALID: 'Some other action',
  STAMPDUTYREQUIRED: 'Please enter stamp duty',
  TERMSCHECKREQUIRED: 'Please Check the agree terms checkbox',
  TOKENEXPNOVALID:
        'The invite is expired or not Valid, Please contact the document owner to resend the invite',
  CONSENTREQUIRED: 'Consent or e-sign is required',
  FILELARGE: 'File too large. Please upload less than 5mb',
  FILENOTACCEPTED:
        'This file format is not supported. Please upload a pdf, jpg, jpeg or png file.',
  NOMANGERLIST: 'Please add a admin first',
  MAXIMUMALLOWEDDOCUMEN: 'Maximum allowed document exceed',
  EMAILVERIFIED: 'Please verify your email from profile to continue',
  PROFILEVERIFIEDHO: 'Please wait till your profile is being verified by Head Office',
  KYCCOMPLETED: 'Please complete your DigiLocker KYC',
  PHONE_VALID: 'This is not a valid Phone Number',
  CONFIRMACCOUNTNUMBERREQUIRED: 'Confirm Account number is required',
  CONFIRMACCOUNTNUMBERNOMATCH: 'Account number does not match.',
  SROBRANCHREQUIRED: 'Please select Location',
  PINCODECHECKREQUIRED: 'Please check your pincode is servicable or not',
  DOCUMENTDELIVERYTYPEREQUIREDERROR: 'Please select the document delivery type.',
  REVIEWPRIORITYMISSING: 'Please add all the priority in the sequence',
  DOCUMENTREVIEWRMINREQUIRED: 'Please complete the previous step first',
  BULKTOTALAMOUNTPROGRESS: 'Calculation is in progress',
  NAMEISREQUIRED: 'Name is required!',
  NAMESPECIALCHARACTERSANDLENGTH:
        ' Name should not contain any special characters, digits and its length should not exceed 40 cahracters.',
  NAMESPECIALCHARACTERS: ' Name should not contain any special characters and digits.',
  NAMELENGHT: 'Name should excedd 40 characters.',
  FATHERNAMEISREQUIRED: "Father's Name is required!",
  OTPMINREQUIRED: 'Please fill 6 digit otp',
  TERMSERROR:
        'Looks like you have not accepted the Terms & Conditions / Privacy Policy / Disclaimer. Please go to "HOME" menu to complete this process.',
  DOBERROR: 'You must be atleast 18 years to proceed.',
  PANNUMBERMANDATORY: 'PAN No. is mandatory to use this platform.',
  SUBSCRIPTIONMESSAGE: 'Please buy subscription first.',
  ADDADDRESS: 'Please add shipping address before payment.',
  ENTERVALIDPINCODE: 'Please enter valid Pin Code !'
})
