/* eslint-disable */
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import AadharModal from './components/AadharModal/AadharModal'
import './Signature.css';
import {
    useGetDocumentPreviewAndSignModeByDocumentIdQuery,
    useGetEstampCertificateDetailsQuery,
    useInitializeDigitalSignatureProcessMutation,
    useInitializeEsignProcessMutation,
    useLazyGetEstampCertificateDetailsQuery
} from '../../../../../store/services/signatureService';
import PreviewDocument from './PreviewDocument/PreviewDocument';
import { ProfileContext } from '../../../../../contextapi/ProfileContext';
import {
    DIGITALSIGNATUREPOSITION,
    SIGNATUREMESSAGE
} from '../../../../../constants/signatureConstant';
import {
    handleEsign1,
    handleEsignModified,
    injectSignature
} from '../../../../../util/digitalsign';
import { PARTYTYPEOPTIONS, SIGNATUREMODES } from '../../../../../constants/documentConstans';
import BackdropLoader from '../../../../../components/BackdropLoader/BackdropLoader';
import { toast } from 'react-toastify';
import { PARTYTYPES } from '../../../../../constants/enum';
import DigitalSignatureGuideModal from './components/DigitalSignatureGuideModal/DigitalSignatureGuideModal';
import info from '../../../../../assets/images/info.svg';
import { IpContext } from '../../../../../contextapi/IpContext';
import { useLinkClPdfWithDocumentChildMutation } from '../../../../../store/services/documentServices';
import html2pdf from 'html2pdf.js';
import { generateStampPaper } from '../../../../../util/stampPaperHelper';
import { DOCUMENTMESSAGE } from '../../../../../constants/message';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    background: '#FFFFFF',
    boxShadow: '6px 11px 60px 12px rgba(0, 0, 0, 0.06)',
    borderRadius: '3px',
    p: 3,
    display: 'flex',
    flexDirection: 'column'
};

const Signature = () => {
    const navigate = useNavigate();

    const ipContextData = useContext(IpContext);
    const { documentId } = useParams();

    // Profile data from context api
    const { profile } = useContext(ProfileContext);
    const ip = ipContextData?.currentUserIpAddress;

    const [signatureXml, setsignatureXml] = useState('');

    const [openThanksModal, setOpenThanksModal] = useState(false);

    const [digitalSignModalState, setdigitalSignModalState] = useState({
        open: false,
        title: '',
        message: '',
        cancelButtonText: 'Cancel',
        submitButtonText: 'Ok'
    });

    // Backdrop loader state
    const [backdropState, setBackdropState] = useState(false);
    // Get document Preview and Review section data
    const {
        data: documentDataResponse = {},
        isSuccess,
        refetch
    } = useGetDocumentPreviewAndSignModeByDocumentIdQuery(documentId, {
        skip: !documentId
    });

    // Inititate Signature process
    const [initializeEsignProcess] = useInitializeEsignProcessMutation({});

    // Inititate Digital Signature process
    const [initializeDscProcess] = useInitializeDigitalSignatureProcessMutation({});

    function decodeXml(encodedXml) {
        const decodedString = atob(encodedXml);
        const decoder = new TextDecoder('utf-8');
        const decodedXml = decoder.decode(
            new Uint8Array([...decodedString].map((char) => char.charCodeAt(0)))
        );
        return decodedXml;
    }
    // const [isExtensionInstalled, setIsExtensionInstalled] = useState(false)

    // useEffect(() => {
    //   const checkExtension = () => {
    //     // eslint-disable-next-line no-undef
    //     // console.log(window.browser, window.browser.runtime, 'browser')

    //     // eslint-disable-next-line no-undef
    //     if (window.browser !== 'undefined' && window.browser.runtime !== 'undefined') {
    //       console.log(isExtensionInstalled, 'isExtensionInstalled')
    //       // Firefox
    //       // eslint-disable-next-line no-undef
    //       window.browser.runtime
    //         .sendMessage('your_extension_id', { checkInstalled: true })
    //         .then((response) => {
    //           if (response && response.isInstalled) {
    //             console.log('im her and installed other', response)
    //           } else {
    //             console.log('im her and uninstalled other')
    //           }
    //         })
    //         .catch(() => console.log('im her and uninstalled other'))
    //       // eslint-disable-next-line no-undef
    //     } else if (window.chrome !== 'undefined' && window.chrome.runtime !== 'undefined') {
    //       // Chrome
    //       // eslint-disable-next-line no-undef
    //       window.chrome.runtime.sendMessage(
    //         'glghokcicpikglmflbbelbgeafpijkkf',
    //         { checkInstalled: true },
    //         (response) => {
    //           if (response && response.isInstalled) {
    //             console.log('im her and installed chrome', response)
    //           } else {
    //             console.log('im her and uniinstalled chrome')
    //             setIsExtensionInstalled(false)
    //           }
    //         }
    //       )
    //     } else {
    //       setIsExtensionInstalled(false)
    //     }
    //   }

    //   checkExtension()
    // }, []) // Run this effect only once when the component mounts

    useEffect(() => {
        if (documentDataResponse?.esignMode?.id === SIGNATUREMODES?.AADHAAR?.id) {
            const dataToSend = {
                docId: documentId,
                userId: profile?.id,
                ipAddress: ip
            };
            initializeEsignProcess({ payload: dataToSend })
                .unwrap()
                .then(async (response) => {
                    const decodedSignatureXml = await decodeXml(response?.data);
                    setsignatureXml(decodedSignatureXml);
                    console.log(response);
                })
                .catch((exception) => {
                    console.log('EXCEPTION:Signature  INITIALIZED', exception);
                });
        } else if (documentDataResponse?.esignMode?.id === SIGNATUREMODES?.DSC?.id) {
            handleOpenDigitalSignStepsModal();
        }
    }, [isSuccess]);

    const handleOpenDigitalSignStepsModal = () => {
        setdigitalSignModalState({
            open: true,
            title: 'Steps to be followed in Digital Signature: - ',
            message: [],
            submitButtonText: 'Ok',
            proceedButtonText: 'Continue'
        });
    };

    const handleDigitalSign = async () => {
        console.log('in if');
        setBackdropState(true);
        const signerDitalResponse = await handleEsignModified();
        console.log(signerDitalResponse, 'pdfResBlob');
        if (!signerDitalResponse?.err) {
            // toast.success('im here');
            const signMarkedPdfBlob = await handleMarkDigitalSignPdf(signerDitalResponse?.data);
            handleSubmitDigitalSignature(signerDitalResponse?.data, signMarkedPdfBlob);
        } else {
            console.log(
                typeof signerDitalResponse?.errmsg,
                Object.keys(signerDitalResponse?.errmsg)
            );
            setBackdropState(false);
            toast.error(signerDitalResponse?.errmsg?.message);
        }
    };

    /**
     * Handle Make position coordinates array according to the party type[firstparty, secondpart, witness]
     * @param {Object} signerDitalResponse: Certificate response
     * @returns
     */
    const handleMarkDigitalSignPdf = async (signerDitalResponse) => {
        try {
            let positionCordList = [
                {
                    page: 0,
                    posx:
                        documentDataResponse?.transaction?.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                            ? DIGITALSIGNATUREPOSITION?.FIRSTPARTYPOSITION?.FIRSTPAGEX
                            : DIGITALSIGNATUREPOSITION?.SECONDPARTYPOSITION?.FIRSTPAGEX,
                    posy:
                        documentDataResponse?.transaction?.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                            ? DIGITALSIGNATUREPOSITION?.FIRSTPARTYPOSITION?.FIRSTPAGEY
                            : DIGITALSIGNATUREPOSITION?.SECONDPARTYPOSITION?.FIRSTPAGEY
                }
            ];
            let gapSize = DIGITALSIGNATUREPOSITION?.GAPSIZE?.POS;

            if (
                documentDataResponse?.transaction?.partyId === PARTYTYPES?.FIRSTPARTY?.ID ||
                documentDataResponse?.transaction?.partyId === PARTYTYPES?.SECONDPARTY?.ID
            ) {
                // Array of only the parties that are same with the current user
                // for ex: if current user is first party then all the first party in the document are filtered
                const partyFilteredArray = documentDataResponse?.userList.filter(
                    (obj) => obj?.partyId === documentDataResponse?.transaction?.partyId
                );

                // Sort the partyFilteredArray array based on the 'priority' field
                const sortedPriorityArray = partyFilteredArray.sort(
                    (a, b) => a?.priority - b?.priority
                );

                // Find the index of the object with the current user party
                const indexOfCurrentParty = sortedPriorityArray.findIndex(
                    (obj) => obj.email === documentDataResponse?.transaction?.email
                );

                let initialxpos =
                    documentDataResponse?.transaction?.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                        ? DIGITALSIGNATUREPOSITION?.FIRSTPARTYPOSITION?.XPOS
                        : DIGITALSIGNATUREPOSITION?.SECONDPARTYPOSITION?.XPOS;

                let xpos =
                    documentDataResponse?.transaction?.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                        ? initialxpos + gapSize * indexOfCurrentParty
                        : initialxpos - gapSize * indexOfCurrentParty;

                let ypos =
                    documentDataResponse?.transaction?.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                        ? DIGITALSIGNATUREPOSITION?.FIRSTPARTYPOSITION?.YPOS
                        : DIGITALSIGNATUREPOSITION?.SECONDPARTYPOSITION?.YPOS;

                for (let i = 1; i < documentDataResponse?.pages - 1; i++) {
                    let position = {
                        page: i,
                        posx: xpos,
                        posy: ypos
                    };
                    positionCordList.push(position);
                }
                // let markedPosition = {
                //     page: documentDataResponse?.transaction?.pageNumber - 1,
                //     posx: documentDataResponse?.transaction?.positionX,
                //     posy: documentDataResponse?.transaction?.positionY
                // };

                // positionCordList.push(markedPosition);
            }
            // Will Work both for Party and Witness, as we need witness signature only on the marked position
            let markedPosition = {
                page: documentDataResponse?.transaction?.pageNumber - 1,
                posx: documentDataResponse?.transaction?.positionX,
                posy: documentDataResponse?.transaction?.positionY
            };

            positionCordList.push(markedPosition);

            console.log(positionCordList, 'This is the final list');

            const pdfResBlob = await injectSignature(
                signerDitalResponse,
                documentDataResponse?.unsignedFile?.fileUri,
                positionCordList,
                ip
            );
            return pdfResBlob;
        } catch (error) {
            setBackdropState(false);
        }
    };

    const handleSubmitDigitalSignature = (signerDitalResponse, pdfResBlob) => {
        const dataToSend = {
            file: pdfResBlob,
            ipAddress: ip,
            selCertSubject: signerDitalResponse?.SelCertSubject,
            certThumbPrint: signerDitalResponse?.CertThumbPrint,
            publicKey: signerDitalResponse?.PublicKey,
            cert: signerDitalResponse?.Cert,
            eMail: signerDitalResponse?.eMail,
            validFrom: signerDitalResponse?.ValidFrom,
            expDate: signerDitalResponse?.ExpDate
        };

        initializeDscProcess({ payload: dataToSend, docId: documentId })
            .unwrap()
            .then(async (response) => {
                setOpenThanksModal(true);
                setBackdropState(false);
                console.log(response);
            })
            .catch((exception) => {
                console.log('EXCEPTION:Signature  INITIALIZED', exception);
                setBackdropState(false);
            });
    };

    const handleOkButton = () => {
        setOpenThanksModal(false);
        navigate('/Home', {
            state: {
                HeaderTitle: 'Home'
            }
        });
    };

    return (
        <Box p={2}>
            <Grid container lg={12} spacing={2}>
                <Grid item lg={5}>
                    <Grid
                        overflow="scroll"
                        className="previewDocument_commentsection"
                        sx={{ maxHeight: 'calc(100vh - 12rem)' }}>
                        <PreviewDocument file={documentDataResponse?.unsignedFile?.fileUri} />
                    </Grid>
                    {/* <div className="signatureDocument_previewBox">PreviewDocument</div> */}
                </Grid>
                <Grid item lg={7}>
                    {/* <Button
                        onClick={() => {
                            setdigitalSignModalState({
                                open: true,
                                title: 'Steps to be followed in Digital Signature: - ',
                                message: [],
                                submitButtonText: 'Ok'
                            });
                        }}>
                        Open
                    </Button> */}
                    {documentDataResponse?.esignMode?.id === SIGNATUREMODES?.DSC?.id && (
                        <>
                            <img
                                src={info}
                                alt="info"
                                style={{ marginRight: '15px' }}
                                // id={id}
                                onClick={handleOpenDigitalSignStepsModal}
                                // ref={signaturePlacementPopRef}
                            />
                            <Typography variant="darkValueLarge">Important notes</Typography>
                        </>
                    )}
                    <div className="signatureDocument_rightGridBox">
                        <Typography
                            variant="darkValueLarge"
                            component="div"
                            sx={{ marginBottom: '10px' }}>
                            Please sign the document
                        </Typography>
                        <div className="signatureDocument_radiobutton">
                            <Typography variant="darkValue">
                                {documentDataResponse?.esignMode?.id === SIGNATUREMODES?.AADHAAR?.id
                                    ? SIGNATUREMESSAGE?.AADHAR
                                    : SIGNATUREMESSAGE?.DIGITALDSC}
                            </Typography>
                        </div>

                        {documentDataResponse?.esignMode?.id === SIGNATUREMODES?.DSC?.id && (
                            <div className="signatureDocument_digitalBox">
                                <div className="signatureDocument_digitalContent">
                                    <Typography variant="darkValue">
                                        Please insert your DSC token
                                    </Typography>
                                    <Button variant="primary" onClick={handleDigitalSign}>
                                        Sign
                                    </Button>
                                </div>
                            </div>
                        )}
                        {documentDataResponse?.esignMode?.id === SIGNATUREMODES?.AADHAAR?.id && (
                            <form
                                id="URL"
                                name="URL"
                                method="POST"
                                action="https://pregw.esign.egov-nsdl.com/nsdl-esp/authenticate/esign-doc/"
                                encType="multipart/form-data">
                                <Button variant="primary" type="submit" disabled={!signatureXml}>
                                    Initiate Signatures
                                </Button>
                                <input type="hidden" name="msg" value={signatureXml} />
                            </form>
                        )}
                    </div>
                </Grid>
            </Grid>
            <Modal
                open={openThanksModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '20px' }}>
                        Thanks for signing the document. We will notify the other party and will
                        move the process ahead.
                    </Typography>
                    <Button variant="primary" sx={{ width: '60px' }} onClick={handleOkButton}>
                        Ok
                    </Button>
                </Box>
            </Modal>

            <DigitalSignatureGuideModal
                digitalSignGuideModalState={digitalSignModalState}
                handleOkButtonDigitalSignModal={() => {
                    setdigitalSignModalState({ open: false });
                }}
                componentType="signature"
            />

            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
    );
};

export default Signature;
