import React, { useState } from 'react'
import './ForgotPassword.css'
import { Typography, Grid, TextField, Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useLazyForgetPasswordQuery } from '../../../../store/services/userServices'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
// import { toast } from 'react-toastify'

const ForgotPassword = () => {
  const navigate = useNavigate()

  // Email state
  const [email, setEmail] = useState()

  // Loader state
  const [backdropState, setBackdropState] = useState(false)

  const params = {
    email
  }
  const [forgetPassword] = useLazyForgetPasswordQuery(undefined, { skip: true })

  // Handle forgot password form submit
  const handleSubmit = () => {
    setBackdropState(true)
    console.log('in forgot password')
    forgetPassword(params)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        console.log('success')
        console.log(response, 'fulfil')
        navigate('/email-Link')
      })
      .catch((execption) => {
        setBackdropState(false)
        // toast.error(execption?.message)
        console.log('exception', execption)
      })
  }
  const handlePreventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }

  return (
        <Grid item md={6} className="forgetPasswordRightGrid">
            <div className="forgetPasswordHead">
                <Typography variant="labelLarge" sx={{ lineHeight: '64px' }}>
                    Forgot your password
                </Typography>

                <Typography
                    variant="modalLabel"
                    sx={{ fontSize: '18px', marginBottom: '39px', textAlign: 'left' }}>
                    Please enter your email address. You will receive a link to create new password
                    via email.
                </Typography>
            </div>
            <form
                onSubmit={(event) => {
                  event.preventDefault()
                  handleSubmit()
                }}>
                <TextField
                    required
                    type="email"
                    id="email"
                    label="E-mail address"
                    style={{ marginBottom: '20px' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    name="email"
                    fullWidth
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                    onKeyDown={handlePreventSpace}
                />
                <Button variant="primary" fullWidth type="submit">
                    Send
                </Button>
            </form>
            <div className="forgetPasswordFoot">
                <Typography variant="modalLabel" sx={{ fontSize: '18px' }}>
                    Click here to go back to&nbsp;
                    <Link className="textSignup" to="/">
                        Login
                    </Link>
                </Typography>
            </div>

            <BackdropLoader backdropState={backdropState} />
        </Grid>
  )
}

export default ForgotPassword
