import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import palette from '../../../../theme/palette'
import CustomTooltip from '../../../../components/Tooltip/CustomTooltip'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 425,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}
const DocumentCopiesEntryModal = ({
  openAlertModal,
  handleAlertModalClose,
  handleSubmitAlertModal,
  alertModalState,
  handleInitializeFormCopiesChange
}) => {
  const [openCopiesTooltip, setCopiesTooltip] = useState(false)

  const handleCopiesTooltipOpen = () => setCopiesTooltip(true)
  const handleCopiesTooltipClose = () => setCopiesTooltip(false)
  const handlePreventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }
  return (
        <Modal
            open={openAlertModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid p={2}>
                    <form
                        onSubmit={(event) => {
                          event.preventDefault()
                          handleSubmitAlertModal(event)
                        }}>
                        <Grid mb={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                variant="darkValue"
                                sx={{ fontSize: '15px', lineHeight: '31px' }}>
                                {alertModalState.label}
                            </Typography>
                            <CustomTooltip
                                message={[
                                  'All physically executed documents would need wet signatures and can be executed in one or more counterparts on chargeable basis.'
                                ]}
                                openTooltip={openCopiesTooltip}
                                setOpenTooltip={setCopiesTooltip}
                                handleTooltipOpen={handleCopiesTooltipOpen}
                                handleTooltipClose={handleCopiesTooltipClose}
                            />
                        </Grid>

                        <Grid display="flex" justifyContent="space-between" gap={2}>
                            <TextField
                                value={alertModalState?.copies}
                                type="text"
                                inputProps={{
                                  type: 'text',
                                  // inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                  maxlength: 2,
                                  minlength: 1
                                }}
                                sx={{ width: '180px' }}
                                required
                                id="copies"
                                name="copies"
                                onChange={handleInitializeFormCopiesChange}
                                onKeyDown={handlePreventSpace}
                            />
                            <Grid>
                                <Button variant="primary" type="submit" sx={{ width: '150px' }}>
                                    {alertModalState.submitButtonText}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Box>
        </Modal>
  )
}

export default DocumentCopiesEntryModal
DocumentCopiesEntryModal.propTypes = {
  openAlertModal: PropTypes.bool,
  handleAlertModalClose: PropTypes.func,
  handleSubmitAlertModal: PropTypes.func,
  alertModalState: PropTypes.object,
  handleInitializeFormCopiesChange: PropTypes.func
}
