/* eslint-disable no-unused-vars */
/* eslint-disable  */
import {
    Box,
    Button,
    Paper,
    Popover,
    Skeleton,
    // Skeleton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import React, { useContext, useState } from 'react';
import info from '../../../../assets/images/info.svg';
import StyledTableCell from '../../../../StyledComponents/TableStyles/TableStyles';
import CustomTablePagination from '../../../../components/CustomTablePagination/CustomTablePagination';
import palette from '../../../../theme/palette';
import viewicon from '../../../../assets/actions/actionView.svg';
import makePaymentIcon from '../../../../assets/actions/actionMakePayment.svg';
import regenrateDocumentIcon from '../../../../assets/actions/actionRegenrateDocument.svg';
import digitalSign from '../../../../assets/actions/actionDigitalSign.svg';
import bulkSignatureIcon from '../../../../assets/actions/actionBulkSignature.svg';
import deleteicon from '../../../../assets/actions/actionDelete.svg';
// import emailicon from '../../../../assets/actions/actionEmail.svg'
import PropTypes from 'prop-types';
import { DOCUEMENTFILTERSTATUS } from '../../../../constants/documentConstans';
import BulkSignatureModal from '../../../../components/BulkSignatureModal/BulkSignatureModal';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import { ALERT_MESSAGE, TOAST_MESSAGE } from '../../../../constants/message';
import MakePaymentModal from './MakePaymentModal/MakePaymentModal';
import {
    useDeleteDocumentByBulkIdMutation,
    useInitateBulkDocumentPaymentCorpMutation,
    useLazyGetAllDocumentsByBulkIdQuery,
    useLazyGetClEstampDataByDocumentIdQuery,
    useUpdateBulkDocumentStatusInProcessMutation,
    useUpdateBulkDocumentStatusMutation,
    useUploadLinkBulkDocumentClpdfMutation
} from '../../../../store/services/documentServices';
import { toast } from 'react-toastify';
import { PARTYTYPES, PAYMENT_TYPE } from '../../../../constants/enum';
// import { handleEsign } from '../../../../util/digitalsign'
import { generateDocument } from '../../../../util/generateClDocument';
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader';
import notVerifiedIcon from '../../../../assets/images/notverifiedIcon.svg';
import CorrectionRequired from './CorrectionRequiredModal/CorrectionRequired';
import DigitalSignatureGuideModal from '../../../CreateDocument/CustomFlow/ConsentFlow/Signature/components/DigitalSignatureGuideModal/DigitalSignatureGuideModal';
import { getNameByDigitalCertificate, handleEsignModified } from '../../../../util/digitalsign';
import { DIGITALSIGNATUREPOSITION } from '../../../../constants/signatureConstant';
import { IpContext } from '../../../../contextapi/IpContext';
import { generateStampPaper } from '../../../../util/stampPaperHelper';
import { generateBulkSignDocument } from '../../../../util/bulkDocumentSign';
import { useGetAllRestrictionChecksQuery } from '../../../../store/services/commonServices';
import { RestrictionContext } from '../../../../contextapi/RestrictionContext';
const BulkTransactionTable = ({
    bulkDocumentsList,
    getAllBulkDocuments,
    isLoading,
    handleGetTotalAmountByBulkId,
    setPaginationFilter,
    paginationFilter,
    totalCount
}) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState();

    // const open = Boolean(anchorEl)

    const [popoverIndex, setPopoverIndex] = useState(null);
    const ipContextData = useContext(IpContext);

    const id = 'simple-popover';
    const handlePopoverOpen = (index, event) => {
        setPopoverIndex(index);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopoverIndex(null);
    };

    const ip = ipContextData?.currentUserIpAddress;

    //   for popover
    // const handleClick = (event) => {
    //   setAnchorEl(event.currentTarget)
    // }

    // const handleClose = () => {
    //   setAnchorEl(null)
    // }

    // Alert modal state
    const [openAlertModal, setOpenAlertModal] = useState(false);

    // make payment modal state
    const [openMakePaymentModal, setOpenMakePaymentModal] = useState(false);

    // Digital Signature steps guide modal state
    const [digitalSignModalState, setdigitalSignModalState] = useState({
        open: false,
        title: '',
        message: '',
        cancelButtonText: 'Cancel',
        submitButtonText: 'Ok'
    });

    // correction required modal state
    const [openCorrectionRequiredModal, setOpenCorrectionRequiredModal] = useState(false);

    const [selectedDocument, setSelectedDocument] = useState({});

    const [backdropState, setBackdropState] = useState(false);

    // state for bulk signature modal
    const [openBulkSignatureModal, setOpenBulkSignatureModal] = useState(false);

    // Update document payment status  for BA or Corporate
    const [initializeBulkDocumentPayment] = useInitateBulkDocumentPaymentCorpMutation({});

    // Update document payment status  for BA or Corporate
    const [deleteBulkDocumentByid] = useDeleteDocumentByBulkIdMutation({});

    // Regenrate Cl Estamp data
    const [regenrateEstampData] = useLazyGetClEstampDataByDocumentIdQuery({});

    // Update documentstatus for correction required file Corporate
    const [updateBulkDocumentStatusByid] = useUpdateBulkDocumentStatusMutation({});

    // Update bulk document status for inProcess required file Corporate
    const [updateBulkDocumentStatusInProcess] = useUpdateBulkDocumentStatusInProcessMutation({});

    const [getAllDocumentsByBulkId] = useLazyGetAllDocumentsByBulkIdQuery();

    // Upload document pdf on successfull payment
    const [linkBulkDocumentClPdf] = useUploadLinkBulkDocumentClpdfMutation();

    const {
        restrictionValues,
        setRestrictionValues,
        tempSubscriptionStatus,
        setTempSubscriptionStatus,
        getRestriction
    } = useContext(RestrictionContext);

    // Alert Modal description
    const alertModalState = {
        title: 'Alert',
        message: ALERT_MESSAGE.DELETEDOCUMENT,
        cancelButtonText: 'No',
        submitButtonText: 'I agree'
    };

    // make payment modal state
    const MakePaymentModalState = {
        title: 'Make Payment',
        message: 'Please confirm to make payment for the document',
        cancelButtonText: 'Cancel',
        submitButtonText: 'Confirm',
        walletBalance: `Your wallet balance is: Rs.${bulkDocumentsList[0]?.groupWalletBalance.toFixed(
            2
        )}`
    };

    // make payment modal state
    const correctionRequiredState = {
        title: 'Correction Required',
        message: ALERT_MESSAGE?.DOCUMENTCORRECTIONREQUIRED,
        cancelButtonText: 'Download',
        submitButtonText: 'Continue',
        walletBalance: ''
    };

    // pagination
    // const [page, setPage] = useState(0)
    // const [rowsPerPage, setRowsPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        // setPage(newPage)

        setPaginationFilter((prev) => ({
            ...prev,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setPaginationFilter((prev) => ({
            ...prev,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        }));

        // setRowsPerPage(parseInt(event.target.value, 10))
        // setPage(0)
    };

    const bulkTransactionDocumentTableHeaders = [
        'Bulk File ID',
        'Date of initiation',
        'No. of Document requests',
        'Article Type',
        'Uploaded By',
        'Total Amount',
        'Status',
        'Date of Completion',
        'Actions'
    ];

    const handleDeleteIcon = (documentData) => {
        setOpenAlertModal(true);
        setSelectedDocument(documentData);
        // setDocID(documentData?.id)
        console.log('documentID');
    };

    const handleMakePayment = (documentData) => {
        console.log(documentData);
        setOpenMakePaymentModal(true);
        setSelectedDocument(documentData);
        console.log('documentID');
    };

    const handleSubmitMakePaymentModalButton = () => {
        setOpenMakePaymentModal(false);
        setBackdropState(true);
        console.log('in make payment submit');
        const dataToSend = {
            documentId: selectedDocument?.bulkFileId,
            transactionType: PAYMENT_TYPE?.BULKDOCUMENT
        };
        initializeBulkDocumentPayment({ payload: dataToSend })
            .unwrap()
            .then((response) => {
                setSelectedDocument({});
                toast.success(response?.message);
                setBackdropState(false);
                getRestriction()
                    .unwrap()
                    .then((response) => {
                        console.log('restriction response===>', response);
                        setRestrictionValues(response);
                        setTempSubscriptionStatus(null);
                        if (response?.isForceLogout) {
                            sessionStorage.clear();
                            navigate('/session-expired');
                            // window.location.href = '/session-expired'
                        }
                    })
                    .catch((execption) => {
                        // toast.error(execption.message)
                    });
            })
            .catch((execption) => {
                setBackdropState(false);

                console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption);
            });
    };

    /**
     * Handle Delete icon
     * @param {object} document: Document selected
     */
    const handleSubmitAlertModal = () => {
        console.log('docID');
        console.log(selectedDocument);
        const params = {
            bulkid: selectedDocument?.bulkFileId
        };
        deleteBulkDocumentByid(params)
            .unwrap()
            .then((response) => {
                setSelectedDocument({});
                setOpenAlertModal(false);
                toast.success(TOAST_MESSAGE.BULK_TRANSACTION_DELETED);
                // console.log('payment Status', paymentStatus)
            })
            .catch((execption) => {
                console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption);
            });
    };

    /**
     * Handle Initiate signature
     * @param {object} data: Bulk document data
     */
    const handleInitiateDigitalSign = async (data) => {
        console.log(data);
        setSelectedDocument(data);
        setOpenBulkSignatureModal(true);
        // const eSignResponse = await handleEsign('bulkDocument')
        // const informationArray = eSignResponse?.SelCertSubject.split(',')
        // let name
        // console.log(informationArray, 'informationArray')
        // informationArray.forEach((item, index) => {
        //   if (item.toLowerCase().includes('cn')) {
        //     name = informationArray[index].split('=')[1]
        //   }
        // })
        // const newPdfBlob = await generateDocument(documentPreview?.link, 'custom', clResponse)
    };

    const handleSubmitDigitalSignModal = async () => {
        console.log('in if');
        setBackdropState(true);
        const signerDitalResponse = await handleEsignModified();
        console.log(signerDitalResponse, 'pdfResBlob');
        if (!signerDitalResponse?.err) {
            const documentPositionResponse = {
                partyId: selectedDocument?.partyId,
                pages: selectedDocument?.pages + 1,
                pageNumber: selectedDocument?.pageNumber,
                positionX: selectedDocument?.positionX,
                positionY: selectedDocument?.positionY
            };

            console.log(documentPositionResponse, signerDitalResponse, 'positioncordListfdfd');

            const positioncordList = await handleMarkDigitalSignPdf(
                documentPositionResponse,
                signerDitalResponse?.data
            );

            console.log(positioncordList, 'positioncordList');

            const name = await getNameByDigitalCertificate(signerDitalResponse?.data);

            // Get all child documents by bulk id
            getAllDocumentsByBulkId(selectedDocument?.bulkFileId)
                .unwrap()
                .then(async (response) => {
                    let isallClCompleted = true;
                    // Loop all child documents and link the cl data pdf with the documents
                    for (let i = 0; i < response.length; i++) {
                        if (response[i]?.estampData) {
                            console.log('Pdf sign data', response[i]?.estampData);
                            const extraFieldResponse = {
                                stateHeading: response[i]?.stampPaperStateHeading,
                                belowHeadingTitle:
                                    response[i]?.stampPaperStateSecondHeading +
                                    response[i]?.estampData.certificateNo
                            };

                            const clFrontPdfResBlob = await generateStampPaper(
                                response[i]?.stateId,
                                response[i]?.fileLink,
                                'bulkDocument',
                                response[i]?.estampData,
                                extraFieldResponse
                            );
                            // const signedPdfResBlob = await generateBulkSignDocument(
                            //     clFrontPdfResBlob,
                            //     'bulkDocument',
                            //     name,
                            //     positioncordList,
                            //     ip
                            // );
                            // const signedPdfResBlob = await generateDocument(
                            //   response[i]?.fileLink,
                            //   'bulkDocument',
                            //   response[i]?.estampData,
                            //   name,
                            //   positioncordList,
                            //   ip
                            // )
                            const dataToSend = {
                                file: clFrontPdfResBlob,
                                ipAddress: ip,
                                selCertSubject: signerDitalResponse?.data?.SelCertSubject,
                                certThumbPrint: signerDitalResponse?.data?.CertThumbPrint,
                                publicKey: signerDitalResponse?.data?.PublicKey,
                                cert: signerDitalResponse?.data?.Cert,
                                eMail: signerDitalResponse?.data?.eMail,
                                validFrom: signerDitalResponse?.data?.ValidFrom,
                                expDate: signerDitalResponse?.data?.ExpDate
                            };

                            const documentId = response[i].estampData?.userDocumentId;
                            console.log('data', dataToSend);
                            linkBulkDocumentClPdf({ payload: dataToSend, documentId })
                                .unwrap()
                                .then((response) => {
                                    console.log('response', response);
                                })
                                .catch((execption) => {
                                    console.log('excepjohkf', execption);
                                });
                        } else {
                            setBackdropState(false);
                            isallClCompleted = false;
                            toast.error('CL Error');
                        }
                    }

                    if (isallClCompleted) {
                        // Update bulk document status to in proces
                        updateBulkDocumentStatusInProcess(selectedDocument?.bulkFileId)
                            .unwrap()
                            .then((response) => {
                                toast.success(response.message);
                                setOpenBulkSignatureModal(false);
                                setdigitalSignModalState({
                                    open: false,
                                    title: 'Steps to be followed for Digital Signature: - ',
                                    message: [],
                                    submitButtonText: 'Ok',
                                    proceedButtonText: 'Continue'
                                });
                                setBackdropState(false);
                            })
                            .catch((execption) => {
                                setBackdropState(false);
                            });
                    }
                })
                .catch((execption) => {
                    setBackdropState(false);

                    console.log('All Bulk Documents:Backend Failed', execption);
                });
            // handleSubmitDigitalSignature(signMarkedPdfBlob)
        } else {
            setBackdropState(false);
            toast.error(signerDitalResponse?.errmsg?.message);
        }

        // setOpenBulkSignatureModal(false)
        // setBackdropState(true)
        // Get all child documents by bulk id
        // getAllDocumentsByBulkId(selectedDocument?.bulkFileId)
        //   .unwrap()
        //   .then(async (response) => {
        //     console.log('response all document', response)
        //     let isallClCompleted = true
        //     // Loop all child documents and link the cl data pdf with the documents
        //     for (let i = 0; i < response.length; i++) {
        //       if (response[i]?.estampData) {
        //         const newPdfBlob = await generateDocument(
        //           response[i]?.fileLink,
        //           'bulkDocument',
        //           response[i]?.estampData,
        //           'signatureName'
        //         )
        //         const dataToSend = {
        //           file: newPdfBlob
        //         }
        //         const documentId = response[i].estampData?.userDocumentId

        //         linkBulkDocumentClPdf({ payload: dataToSend, documentId })
        //           .unwrap()
        //           .then((response) => {})
        //           .catch((execption) => {})
        //         console.log(newPdfBlob)
        //       } else {
        //         setBackdropState(false)
        //         isallClCompleted = false
        //         toast.error('CL Error')
        //       }
        //     }

        //     if (isallClCompleted) {
        //       // Update bulk document status to in proces
        //       updateBulkDocumentStatusInProcess(selectedDocument?.bulkFileId)
        //         .unwrap()
        //         .then((response) => {
        //           setBackdropState(false)
        //         })
        //         .catch((execption) => {
        //           setBackdropState(false)
        //         })
        //     }
        //   })
        //   .catch((execption) => {
        //     setBackdropState(false)

        //     console.log('All Bulk Documents:Backend Failed', execption)
        //   })
    };

    const handleMarkDigitalSignPdf = (documentPositionResponse, signerDitalResponse) => {
        try {
            const positionCordList = [
                {
                    page: 0,
                    posx:
                        documentPositionResponse?.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                            ? DIGITALSIGNATUREPOSITION?.FIRSTPARTYPOSITION?.FIRSTPAGEX
                            : DIGITALSIGNATUREPOSITION?.SECONDPARTYPOSITION?.FIRSTPAGEX,
                    posy:
                        documentPositionResponse?.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                            ? DIGITALSIGNATUREPOSITION?.FIRSTPARTYPOSITION?.FIRSTPAGEY
                            : DIGITALSIGNATUREPOSITION?.SECONDPARTYPOSITION?.FIRSTPAGEY
                }
            ];
            const xpos =
                documentPositionResponse?.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                    ? DIGITALSIGNATUREPOSITION?.FIRSTPARTYPOSITION?.XPOS
                    : DIGITALSIGNATUREPOSITION?.SECONDPARTYPOSITION?.XPOS;
            const ypos =
                documentPositionResponse?.partyId === PARTYTYPES?.FIRSTPARTY?.ID
                    ? DIGITALSIGNATUREPOSITION?.FIRSTPARTYPOSITION?.YPOS
                    : DIGITALSIGNATUREPOSITION?.SECONDPARTYPOSITION?.YPOS;

            for (let i = 1; i < documentPositionResponse?.pages - 1; i++) {
                const position = {
                    page: i,
                    posx: xpos,
                    posy: ypos
                };
                positionCordList.push(position);
            }
            // Will Work both for Party and Witness, as we need witness signature only on the marked position
            const markedPosition = {
                page: documentPositionResponse?.pageNumber,
                posx: documentPositionResponse?.positionX,
                posy: documentPositionResponse?.positionY
            };

            positionCordList.push(markedPosition);

            console.log(positionCordList, 'This is the final list');

            return positionCordList;
            // const pdfResBlob = await injectSignature(
            //     signerDitalResponse,
            //     documentPositionResponse?.unsignedFile?.fileUri,
            //     positionCordList
            // );
            // return pdfResBlob;
        } catch (error) {
            setBackdropState(false);
        }
    };

    const handleOpenCorrectionRequiredModal = (documentData) => {
        console.log(documentData);
        setOpenCorrectionRequiredModal(true);
        setSelectedDocument(documentData);
    };

    const handleSubmitCorrectionRequired = () => {
        setBackdropState(true);
        setOpenCorrectionRequiredModal(false);
        const params = {
            document: selectedDocument?.bulkFileId
        };
        updateBulkDocumentStatusByid(params)
            .unwrap()
            .then((response) => {
                setSelectedDocument({});
                toast.success(response?.message);
                setBackdropState(false);
                // console.log('payment Status', paymentStatus)
            })
            .catch((execption) => {
                setBackdropState(false);
                console.log('DOCUMENT update failed:Backend Failed', execption);
            });
    };

    // OpenDigital Signature guide modal
    const handleOpenDigitalSignatureGuideModal = (documentData) => {
        console.log('Document Data--->', documentData);
        setSelectedDocument(documentData);
        setdigitalSignModalState({
            open: true,
            title: 'Steps to be followed for Digital Signature: - ',
            message: [],
            submitButtonText: 'Ok',
            proceedButtonText: 'Continue'
        });
    };

    const handleOpenDigitalSignModal = () => {
        setOpenBulkSignatureModal(true);
    };

    /**
     * Regenrate the estamp data for the selected document [status: EstampRegenrated]
     * @param {string} documentId : selected document id
     */
    const handleRegenrateEstamp = (documentId) => {
        setBackdropState(true);
        regenrateEstampData({ documentId })
            .unwrap()
            .then((response) => {
                // setBackdropState(false)
                setBackdropState(false);
                console.log('reponse', response);
                // handleSendContentPdf(
                //   response?.estampDataResponse,
                //   documentId,
                //   response?.templatePreviewResponse?.content
                // )
            })
            .catch((execption) => {
                setBackdropState(false);

                console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption);
            });
    };

    return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', border: `0.5px solid ${palette.text.light}` }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 18rem)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {bulkTransactionDocumentTableHeaders?.length > 0 &&
                                bulkTransactionDocumentTableHeaders?.map((HeaderName, index) => (
                                    <StyledTableCell align="left" key={index}>
                                        {HeaderName}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            [0, 1, 2, 3, 4].map((item, index) => (
                                <TableRow key={index}>
                                    {bulkTransactionDocumentTableHeaders?.map((item, hindex) => (
                                        <StyledTableCell key={hindex}>
                                            <Skeleton />
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        {
                            // (
                            //     paginationFilter?.rowsPerPage > 0
                            //     ? bulkDocumentsList?.slice(
                            //           paginationFilter?.page * paginationFilter?.rowsPerPage,
                            //           paginationFilter?.page * paginationFilter?.rowsPerPage +
                            //               paginationFilter?.rowsPerPage
                            //       )
                            //     : bulkDocumentsList
                            // )

                            bulkDocumentsList.map((document, index) => (
                                <TableRow key={index}>
                                    <StyledTableCell scope="row">
                                        {document?.bulkFileId}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {document?.initDate}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {document?.totalDocuments}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {document?.articleCategory?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {document?.uploadedBy?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {document?.totalAmount || document?.totalAmount === 0 ? (
                                            <>
                                                <div style={{ display: 'flex', gap: '10px' }}>
                                                    {Number(document?.totalAmount).toFixed(2)}
                                                    <img
                                                        src={info}
                                                        alt="info"
                                                        onClick={(event) =>
                                                            handlePopoverOpen(index, event)
                                                        }
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </div>
                                                <Popover
                                                    key={index}
                                                    id={id}
                                                    open={popoverIndex === index}
                                                    anchorEl={anchorEl}
                                                    onClose={handlePopoverClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            padding: '25px',
                                                            border: `0.1px solid  ${palette.text.main}`,
                                                            background: palette.primary.light
                                                        }}
                                                        className="documentTablePopoverBox"
                                                        key={index}>
                                                        <Typography
                                                            variant="labelSmall"
                                                            sx={{ fontSize: '18px' }}
                                                            component="div"
                                                            mb={2}>
                                                            Segregation of the total amount
                                                        </Typography>
                                                        <Typography variant="darkValue">
                                                            {document?.chargeSegregation?.map(
                                                                (item, index) =>
                                                                    item?.chargeType !==
                                                                    'STORAGE_CHARGES' ? (
                                                                        <>
                                                                            <Typography key={index}>
                                                                                {item?.label}:{' '}
                                                                                {item?.amount?.toFixed(
                                                                                    2
                                                                                )}
                                                                            </Typography>
                                                                        </>
                                                                    ) : null
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Popover>
                                            </>
                                        ) : (
                                            <span>
                                                <Button
                                                    variant="link"
                                                    onClick={() =>
                                                        handleGetTotalAmountByBulkId(document)
                                                    }
                                                    style={{ backgroundColor: 'white' }}>
                                                    Calculate
                                                </Button>
                                            </span>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {document?.status?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {document?.completedDate}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {document?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.WAITINGFORPAYMENT?.ID && (
                                            <div className="actionIcons">
                                                <img
                                                    src={viewicon}
                                                    alt="editIcon"
                                                    title="View document"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        navigate(
                                                            `/myDocument/bulkTransaction/bulkDocumentDetails/${document?.bulkFileId}`
                                                        )
                                                    }
                                                />
                                                <img
                                                    src={deleteicon}
                                                    alt="deleteIcon"
                                                    title="Delete document"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleDeleteIcon(document)}
                                                />
                                                <img
                                                    src={makePaymentIcon}
                                                    alt="MakePaymentIcon"
                                                    title="Make Payment1"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleMakePayment(document)}
                                                />
                                            </div>
                                        )}
                                        {document?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.SIGNATURESREQUESTED?.ID && (
                                            <div className="actionIcons">
                                                <img
                                                    src={viewicon}
                                                    alt="editIcon"
                                                    title="Edit document"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        navigate(
                                                            `/myDocument/bulkTransaction/bulkDocumentDetails/${document?.bulkFileId}`
                                                        )
                                                    }
                                                />
                                                {/* <img
                                                src={bulkSignatureIcon}
                                                alt="bulkSignatureIcon"
                                                title="Bulk Signature"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleInitiateDigitalSign(document)}
                                            /> */}
                                            </div>
                                        )}
                                        {document?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.INDRAFT?.ID && (
                                            <div className="actionIcons">
                                                <img
                                                    src={deleteicon}
                                                    alt="deleteIcon"
                                                    title="Delete document"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleDeleteIcon(document)}
                                                />
                                            </div>
                                        )}
                                        {document?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.CORRECTIONREQUIRED?.ID && (
                                            <div className="actionIcons">
                                                <img
                                                    src={notVerifiedIcon}
                                                    alt="notVerified"
                                                    height="29px"
                                                    width="29px"
                                                    title="Correction Required"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        handleOpenCorrectionRequiredModal(document)
                                                    }
                                                />
                                                <img
                                                    src={deleteicon}
                                                    alt="deleteIcon"
                                                    title="Delete document"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleDeleteIcon(document)}
                                                />
                                            </div>
                                        )}
                                        {document?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.SIGNATUREPLACEMENT?.ID && (
                                            <div className="actionIcons">
                                                <img
                                                    src={bulkSignatureIcon}
                                                    alt="bulkSignatureIcon"
                                                    title="Signature Placement"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        navigate(
                                                            `/previewBulkSignaturePlacement/${document?.bulkFileId}`,
                                                            {
                                                                state: {
                                                                    HeaderTitle:
                                                                        'Signature Placement'
                                                                }
                                                            }
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}

                                        {document?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.SIGNATURESREQUIRED?.ID && (
                                            <div className="actionIcons">
                                                <img
                                                    src={digitalSign}
                                                    alt="digitalSign"
                                                    title="Bulk Signature DSC"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        handleOpenDigitalSignatureGuideModal(
                                                            document
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                        {document?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.INPROCESS?.ID && (
                                            <div className="actionIcons">
                                                <img
                                                    src={viewicon}
                                                    alt="editIcon"
                                                    title="View document"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        navigate(
                                                            `/myDocument/bulkTransaction/bulkDocumentDetails/${document?.bulkFileId}`
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                        {document?.status?.id ===
                                            DOCUEMENTFILTERSTATUS?.ESTAMPREGENERATED?.ID && (
                                            <div className="actionIcons">
                                                <img
                                                    src={regenrateDocumentIcon}
                                                    alt="Regenrate Estamp"
                                                    title="Regenrate Estamp"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleRegenrateEstamp(document?.bulkFileId);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </StyledTableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {bulkDocumentsList?.length === 0 && !isLoading && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        No Data Found
                    </Typography>
                )}
            </TableContainer>

            {/* pagination calling here */}
            <CustomTablePagination
                count={totalCount}
                rowsPerPage={paginationFilter?.rowsPerPage}
                page={paginationFilter?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {openBulkSignatureModal && (
                <BulkSignatureModal
                    openBulkSignatureModal={openBulkSignatureModal}
                    handleBulkSignatureModalClose={() => setOpenBulkSignatureModal(false)}
                    handleSubmitDigitalSignModal={handleSubmitDigitalSignModal}
                />
            )}

            {/* Alert confirmation modal */}
            {openAlertModal && (
                <AlertModal
                    openAlertModal={openAlertModal}
                    handleAlertModalClose={() => setOpenAlertModal(false)}
                    handleSubmitAlertModal={handleSubmitAlertModal}
                    alertModalState={alertModalState}
                />
            )}

            {/* Make Payment modal */}
            {openMakePaymentModal && (
                <MakePaymentModal
                    openMakePaymentModal={openMakePaymentModal}
                    handleMakePaymentModalClose={() => setOpenMakePaymentModal(false)}
                    handleSubmitMakePaymentModalButton={handleSubmitMakePaymentModalButton}
                    MakePaymentModalState={MakePaymentModalState}
                />
            )}

            {/* Correction Required modal */}
            {openCorrectionRequiredModal && (
                <CorrectionRequired
                    selectedDocument={selectedDocument}
                    openCorrectionRequiredModal={openCorrectionRequiredModal}
                    handleCorrectionRequiredModalClose={() => setOpenCorrectionRequiredModal(false)}
                    handleSubmitCorrectionRequiredModalButton={handleSubmitCorrectionRequired}
                    correctionrequiredModalState={correctionRequiredState}
                />
            )}

            {/* Digital Signature guide steps modal */}
            <DigitalSignatureGuideModal
                digitalSignGuideModalState={digitalSignModalState}
                handleOkButtonDigitalSignModal={() => {
                    setdigitalSignModalState({ open: false });
                }}
                handleProccedButtonDigitalSignModal={handleOpenDigitalSignModal}
                componentType="bulkSignature"
            />

            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Paper>
    );
};

export default BulkTransactionTable;

BulkTransactionTable.propTypes = {
    bulkDocumentsList: PropTypes.array,
    getAllBulkDocuments: PropTypes.func,
    isLoading: PropTypes.bool,
    handleGetTotalAmountByBulkId: PropTypes.func,
    totalCount: PropTypes.number,
    setPaginationFilter: PropTypes?.func,
    paginationFilter: PropTypes?.object
};
