import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles'
import search from '../../../assets/images/searchicon.svg'
import { PLACEHOLDER } from '../../../constants/placeholder'
import IndividualTransactionTable from './components/IndividualTransactionTable'
import {
  useGetAllDocumentsQuery
  // useGetDocumentFilterStatusQuery
} from '../../../store/services/documentServices'
import { useGetFilterLocationQuery } from '../../../store/services/commonServices'
import { CORPORATFILTERROLE, PAGINATIONINITIALS, ROLES, SUB_ROLE } from '../../../constants/enum'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { ProfileContext } from '../../../contextapi/ProfileContext'
import { useGetStaffUserDataQuery } from '../../../store/services/manageStaffUserServices'
import CommonMessageButtonModal from '../../../components/commonMessageButtonModal/CommonMessageButtonModal'
import { ALERT_MESSAGE, MESSAGE_CONFIRMATION } from '../../../constants/message'
import { RestrictionContext } from '../../../contextapi/RestrictionContext'

const IndividualTransaction = () => {
  const filterObj = {
    status: 1,
    role: '',
    location: '',
    createdBy: '',
    search: ''
  }

  const intialPaginationFilterState = {
    page: PAGINATIONINITIALS?.PAGE,
    rowsPerPage: PAGINATIONINITIALS?.ROWSPERPAGE
  }

  const { documentStatusArray } = useOutletContext()

  const [individualTransactionFilter, setIndividualTransactionFilter] = useState(filterObj)

  const navigate = useNavigate()
  // Pagination Filter
  const [paginationFilter, setPaginationFilter] = useState(intialPaginationFilterState)

  // Restriction context api
  const { restrictionValues } = useContext(RestrictionContext)

  // Message Modal to navigate user to review and consent tab in case of pending actions
  const [messageModal, setMessageModal] = useState({
    open: false,
    title: ALERT_MESSAGE?.PENDINGACTIONS,
    message: MESSAGE_CONFIRMATION?.ACTIONDOCUMENTS,
    cancelButtonText: 'Skip',
    submitButtonText: 'Ok'
  })

  // Get all document data
  const {
    data: { list = [], totalCount = 0 } = {},
    isLoading,
    refetch
  } = useGetAllDocumentsQuery(
    // individualTransactionFilter
    {
      page: paginationFilter?.page,
      size: paginationFilter?.rowsPerPage,
      status: individualTransactionFilter?.status,
      role: individualTransactionFilter?.role,
      location: individualTransactionFilter?.location,
      createdBy: individualTransactionFilter?.createdBy,
      search: individualTransactionFilter?.search
    }
  )

  useEffect(() => {
    if (
      restrictionValues != null &&
            restrictionValues.reviewConsentForcePopUpResponse?.showReviewTabPopUp
    ) {
      setMessageModal((prev) => ({
        ...prev,
        open: true
      }))
    } else {
      setMessageModal((prev) => ({
        ...prev,
        open: false
      }))
    }
  }, [restrictionValues])

  // Profile context api
  const { profile } = useContext(ProfileContext)

  // Get staff user Location filter query
  const { data: locationFilterList = [] } = useGetFilterLocationQuery(profile?.role, {
    skip: profile?.role !== ROLES?.CORPORATE
  })

  const { data: { list: staffUserList = [] } = {} } = useGetStaffUserDataQuery(
    {
      status: 'ACTIVE',
      role: individualTransactionFilter?.role
    },
    { skip: profile.subRole === SUB_ROLE?.STAFF }
  )

  const handleSelectFilterChange = (event) => {
    setIndividualTransactionFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    resetPage()
  }

  const handleRoleFilterChange = (event) => {
    setIndividualTransactionFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    resetPage()
  }

  /**
     * Handle filter search change
     * @param {object} event: Event object
     */
  const handleSearchTextfield = (event) => {
    setIndividualTransactionFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
    resetPage()
  }

  // Reset page to the initial page [initial Page=0]
  const resetPage = () => {
    setPaginationFilter((prev) => ({
      ...prev,
      page: PAGINATIONINITIALS?.PAGE
    }))
  }

  const handleMessageModalOkClick = () => {
    // close modal
    setMessageModal((prev) => ({
      ...prev,
      open: false
    }))
    // navigate to review and consent tab
    navigate('/myDocument/reviewAndConsent', {
      state: {
        HeaderTitle: 'Review Consent'
      }
    })
  }

  const handleFilterClear = () => {
    // clear all filters
    setIndividualTransactionFilter(filterObj)
  }

  return (
        <>
            <Grid container spacing={2} mb={3}>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <Select
                        name="status"
                        value={individualTransactionFilter?.status}
                        onChange={handleSelectFilterChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                        MenuProps={MenuProps}>
                        <MenuItem value="">
                            <Typography>Select status</Typography>
                        </MenuItem>
                        {documentStatusArray &&
                            documentStatusArray.map((item, index) => (
                                <MenuItem key={index} value={item?.id}>
                                    {item?.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Grid>
                {profile.subRole !== SUB_ROLE.STAFF && (
                    <>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <Select
                                name="location"
                                value={individualTransactionFilter?.location}
                                onChange={handleSelectFilterChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                  fontSize: '16px'
                                }}
                                MenuProps={MenuProps}
                                fullWidth>
                                <MenuItem value="">
                                    <Typography>Select location</Typography>
                                </MenuItem>
                                {locationFilterList?.length > 0 &&
                                    locationFilterList?.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item?.id}
                                            value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <Select
                                name="role"
                                value={individualTransactionFilter?.role}
                                onChange={handleRoleFilterChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                  fontSize: '16px'
                                }}
                                MenuProps={MenuProps}
                                fullWidth>
                                <MenuItem value="">
                                    <Typography>Select role</Typography>
                                </MenuItem>
                                {CORPORATFILTERROLE?.length > 0 &&
                                    CORPORATFILTERROLE?.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item?.id}
                                            value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <Select
                                name="createdBy"
                                value={individualTransactionFilter?.createdBy}
                                onChange={handleSelectFilterChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                  fontSize: '16px'
                                }}
                                MenuProps={MenuProps}
                                fullWidth>
                                <MenuItem value="">
                                    <Typography>Created by (select staff)</Typography>
                                </MenuItem>
                                {staffUserList?.length > 0 &&
                                    staffUserList?.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item?.id}
                                            value={item?.id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                    </>
                )}
                <Grid item lg={5} md={6} sm={12} xs={12} display="flex" alignItems="center">
                    <TextField
                        name="search"
                        id="search"
                        placeholder={PLACEHOLDER.SEARCHMYDOCUMENTINDIVIDUALTRANSACTION}
                        value={individualTransactionFilter?.search}
                        onChange={handleSearchTextfield}
                        InputLabelProps={{
                          shrink: false
                        }}
                        InputProps={{
                          style: {
                            borderRadius: '5px'
                          },
                          startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton aria-label="toggle password visibility">
                                        <img src={search} alt="search" />
                                    </IconButton>
                                </InputAdornment>
                          )
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                    <Button variant="primary" onClick={handleFilterClear}>
                        Clear
                    </Button>
                </Grid>
            </Grid>
            <IndividualTransactionTable
                refetch={refetch}
                totalCount={totalCount}
                documentTableData={list}
                isLoading={isLoading}
                setPaginationFilter={setPaginationFilter}
                paginationFilter={paginationFilter}
            />
            {messageModal?.open && (
                <CommonMessageButtonModal
                    handleOkButtonCommonModal={handleMessageModalOkClick}
                    handleCancelButton={() => {
                      setMessageModal((prev) => ({
                        ...prev,
                        open: false
                      }))
                    }}
                    CommonMessageModalState={messageModal}
                />
            )}
        </>
  )
}

export default IndividualTransaction
