import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import palette from '../../../../theme/palette'
import StyledTableCell from '../../../../StyledComponents/TableStyles/TableStyles'
import PropTypes from 'prop-types'
import './TemplateTable.css'
const NewTemplateTable = ({
  articleTemplateList,
  handleFormSubmit,
  templateTypeSelected,
  initializeForm,
  tableTitle
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null)

  const listChunk = (array, size) => {
    const chunks = []
    for (let index = 0; index < array.length; index += size) {
      chunks.push(array.slice(index, index + size))
    }
    return chunks
  }

  const chunkedArticles = listChunk(articleTemplateList, 4) // Chunk the list into arrays of 4

  return (
        <Card
            sx={{
              border: `0.5px solid ${palette.text.light}`,
              height: 'calc(100% - 20px)',
              backgroundColor: palette.primary.light,
              borderRadius: '5px',
              marginBottom: ' 30px'
            }}>
            <CardHeader
                sx={{
                  borderBottom: `0.5px solid ${palette.text.light}`,
                  backgroundColor: palette.secondary.light
                }}
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>{tableTitle}</Typography>
                    </div>
                }
                titleTypographyProps={{
                  fontWeight: '600',
                  fontSize: '16px',
                  color: palette.text.dark
                }}></CardHeader>
            <CardContent>
                <TableContainer>
                    <Table aria-label="simple table">
                        {/* <TableHead>
                            <TableRow sx={{ whiteSpace: 'nowrap' }}>
                                <StyledTableCell
                                    align="left"
                                    sx={{
                                      // borderRight: '1px solid #ddd',
                                      padding: '20px',
                                      width: '300px',
                                      fontWeight: '600',
                                      fontSize: '18px'
                                      // color: palette.text.dark
                                    }}>
                                    {tableTitle}
                                </StyledTableCell>
                            </TableRow>
                        </TableHead> */}
                        <TableBody>
                            {chunkedArticles.map((articleChunk, rowIndex) => (
                                <TableRow
                                    key={rowIndex}
                                    sx={{
                                      background: palette.primary.light,
                                      borderBottom:
                                            chunkedArticles.length === 1
                                              ? 'none'
                                              : '1px solid #e0e0e0'
                                    }}>
                                    {articleChunk?.length > 0 &&
                                        articleChunk.map((articleCategory) => (
                                            <>
                                                <StyledTableCell
                                                    key={articleCategory?.id}
                                                    sx={{ borderBottom: 'none' }}>
                                                    <Grid
                                                        spacing={2}
                                                        container
                                                        justifyContent="flex-start"
                                                        alignContent="center"
                                                        direction="row">
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={3}
                                                            sm={2}
                                                            lg={3}
                                                            key={articleCategory?.id}>
                                                            <Typography
                                                                className={
                                                                    selectedCategory ===
                                                                    articleCategory?.id
                                                                      ? 'selected-Template'
                                                                      : 'article-templ'
                                                                }
                                                                sx={{
                                                                  whiteSpace: 'nowrap',
                                                                  cursor: 'pointer',
                                                                  justifyContent: 'center',
                                                                  textAlign: 'center',
                                                                  width: 'fit-content',
                                                                  minWidth: '200px',
                                                                  display: 'flex',
                                                                  padding: '10px',

                                                                  '&:hover': {
                                                                    background:
                                                                            palette.gradient.main,
                                                                    color: 'white'
                                                                  },
                                                                  backgroundColor: '#f5f7f9',
                                                                  borderRadius: '5px'
                                                                }}
                                                                onClick={() => {
                                                                  setSelectedCategory(
                                                                    articleCategory?.id
                                                                  )
                                                                  handleFormSubmit(
                                                                    articleCategory
                                                                  )
                                                                }}>
                                                                {articleCategory?.name ||
                                                                    articleCategory?.templateName}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell>
                                            </>
                                        ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {articleTemplateList?.length === 0 && (
                        <Typography
                            variant="modalLabelBold"
                            p={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              backgroundColor: '#fff'
                            }}>
                            No Article Template Found
                        </Typography>
                    )}
                </TableContainer>
            </CardContent>
        </Card>
        // <Paper
        //     sx={{
        //       width: '100%',
        //       overflow: 'hidden',
        //       border: `0.5px solid ${palette.text.light}`
        //     }}>

  //       <Box>
  //         <Grid>

  //         </Grid>
  //       </Box>

  // </Paper>
  )
}

export default NewTemplateTable
NewTemplateTable.propTypes = {
  articleTemplateList: PropTypes.array,
  handleFormSubmit: PropTypes.func,
  templateTypeSelected: PropTypes.string,
  initializeForm: PropTypes.object,
  tableTitle: PropTypes.string
}
