import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import errormessages from '../constants/errormessages'
import {
  useGetAllRestrictionChecksQuery,
  useLazyGetAllRestrictionChecksQuery
  // useLazyGetAllRestrictionChecksQuery
} from '../store/services/commonServices'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROLES, USERKYCSTATUS } from '../constants/enum'
import { getSession } from '../util/helper'

// Create the context
export const RestrictionContext = createContext()

// Provider component
export const RestrictionProvider = ({ children }) => {
  const location = useLocation()

  const routes = ['/myDocument', '/Home', '/createDocument', '/help', '/myProfile']
  const navigate = useNavigate()
  // State to hold the restrictionValues data
  const [restrictionValues, setRestrictionValues] = useState({
    documentAllowed: '',
    documentConsumed: '',
    emailVerified: true,
    errorMessage: '',
    isKycCompleted: true,
    userKycStatus: USERKYCSTATUS.NOT_SUBMITTED,
    isProfileCompleted: true,
    phoneVerified: true,
    profileRejected: true,
    profileStatus: '',
    profileVerified: true,
    subscriptionPurchased: false
  })
  // Restriction data
  const {
    data: restrictionData = {
      documentAllowed: '',
      documentConsumed: '',
      emailVerified: true,
      errorMessage: '',
      isKycCompleted: true,
      userKycStatus: USERKYCSTATUS.NOT_SUBMITTED,
      isProfileCompleted: true,
      phoneVerified: true,
      profileRejected: true,
      profileStatus: '',
      profileVerified: true,
      subscriptionPurchased: false,
      isSubscriptionCanceled: false
    },
    isSuccess
  } = useGetAllRestrictionChecksQuery({})

  const [getRestriction] = useLazyGetAllRestrictionChecksQuery({})
  const [tempSubscriptionStatus, setTempSubscriptionStatus] = useState(null) // Temporary state for subscription

  const role = JSON.parse(getSession('user')).role

  useEffect(() => {
    if (restrictionData && isSuccess) {
      setRestrictionValues(restrictionData)
      setTempSubscriptionStatus(null)
      console.log('restrictionData', restrictionData)
    }
  }, [restrictionData, isSuccess])

  useEffect(() => {
    console.log('location is ', location.pathname)
    if (routes.includes(location.pathname)) {
      getRestriction()
        .unwrap()
        .then((response) => {
          console.log('restriction response', response)
          setRestrictionValues(response)
          setTempSubscriptionStatus(null)
          if (response?.isForceLogout) {
            sessionStorage.clear()
            navigate('/session-expired')
            // window.location.href = '/session-expired'
          }
        })
        .catch((execption) => {
          // toast.error(execption.message)
        })

      console.log('api reload please')
      // setRestrictionValues(restrictionData)
      // console.log('restrictionData', restrictionData)
    }
  }, [location])

  const handleRestriction = () => {
    if (!restrictionValues?.emailVerified) {
      return errormessages?.EMAILVERIFIED
    } else if (!restrictionValues?.profileVerified) {
      return errormessages?.PROFILEVERIFIEDHO
    } else if (!restrictionValues?.isKycCompleted) {
      return errormessages?.KYCCOMPLETED
    } else if (
      !restrictionValues?.subscriptionPurchased &&
            !restrictionValues?.isSubscriptionCanceled &&
            role !== ROLES.INDIVIDUAL
    ) {
      return errormessages?.SUBSCRIPTIONMESSAGE
    } else if (
      (restrictionValues?.userDisclaimerVersion === null &&
                restrictionValues?.userPrivacyVersion === null &&
                restrictionValues?.userTermsVersion === null) ||
            restrictionValues?.userDisclaimerVersion <
                restrictionValues?.currentDisclaimerVersion ||
            restrictionValues?.userPrivacyVersion < restrictionValues?.currentPrivacyVersion ||
            restrictionValues?.userTermsVersion < restrictionValues?.currentTermsVersion
    ) {
      return errormessages?.TERMSERROR
    } else {
      return ''
    }
  }

  return (
        <RestrictionContext.Provider
            value={{
              restrictionValues,
              setRestrictionValues,
              handleRestriction,
              tempSubscriptionStatus,
              setTempSubscriptionStatus,
              getRestriction
            }}>
            {children}
        </RestrictionContext.Provider>
  )
}
RestrictionProvider.propTypes = {
  children: PropTypes.node
}
