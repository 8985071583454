import React, { useState } from 'react'
import search from '../../assets/images/searchicon.svg'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { PLACEHOLDER } from '../../constants/placeholder'
import DateRangeComponent from '../../components/DateRange/DateRange'
import calendericon from '../../assets/images/calendericon.svg'
import {
  useGetAllPhysicalBluedartDocumentQuery,
  useGetBluedartStatusListQuery
} from '../../store/services/documentServices'
import MenuProps from '../../StyledComponents/SelectStyles/SelectStyles'
import moment from 'moment'
import IndividualPhysialDocumentTable from './components/DocumentPhysicalTable/IndividualPhysialDocumentTable'
import { PAGINATIONINITIALS } from '../../constants/enum'
import { ClearIcon } from '@mui/x-date-pickers'
const IndividualPhysicalDocument = () => {
  // Filter initial object
  const filterObj = {
    status: '',
    limit: 100,
    search: '',
    dateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        skip: true
      }
    ]
  }

  const intialPaginationFilterState = {
    page: PAGINATIONINITIALS?.PAGE,
    rowsPerPage: PAGINATIONINITIALS?.ROWSPERPAGE
  }

  // Filter state
  const [filter, setFilter] = useState(filterObj)

  // Pagination Filter
  const [paginationFilter, setPaginationFilter] = useState(intialPaginationFilterState)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // Get all Physical document
  const { data: { list = [], totalCount = 0 } = {}, isLoading } =
        useGetAllPhysicalBluedartDocumentQuery({
          page: paginationFilter?.page,
          size: paginationFilter?.rowsPerPage,
          status: filter?.status,
          search: filter?.search,
          fromDate: !filter?.dateRange[0]?.skip
            ? moment(filter?.dateRange[0]?.startDate).format('YYYY-MM-DD')
            : '',
          toDate: !filter?.dateRange[0]?.skip
            ? moment(filter?.dateRange[0]?.endDate).format('YYYY-MM-DD')
            : ''
        })

  /**
     * Handle filter status change
     * @param {object} event: Event object
     */
  const handleStatusChange = (event) => {
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    resetPage()
  }

  /**
     * Handle filter date range change
     * @param {array} item: date range array
     */
  const dateRangeHandler = (item) => {
    const dateRangeValue = [
      {
        startDate: item[0]?.startDate,
        endDate: item[0]?.endDate,
        key: 'selection',
        skip: false
      }
    ]
    setFilter((prev) => ({
      ...prev,
      dateRange: dateRangeValue
    }))
    resetPage()
  }

  /**
     *  Handle Clear Date Range
     *
     */
  const handleClear = () => {
    const dateRangeValue = [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        skip: true
      }
    ]
    setFilter((prev) => ({
      ...prev,
      dateRange: dateRangeValue
    }))
    resetPage()
    handleClose() // Close the Popover after clearing
  }

  /**
     * Handle filter search change
     * @param {object} event: Event object
     */
  const handleSearchTextfield = (event) => {
    setFilter((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
    resetPage()
  }

  // Reset page to the initial page [initial Page=0]
  const resetPage = () => {
    setPaginationFilter((prev) => ({
      ...prev,
      page: PAGINATIONINITIALS?.PAGE
    }))
  }

  // Get all Blue dart Status query
  const { data: blueDartStatusArray = [] } = useGetBluedartStatusListQuery({})

  const handleFilterClear = () => {
    // clear all filters
    setFilter(filterObj)
  }

  return (
        <Box sx={{ padding: '1% 3%' }}>
            <>
                <Grid
                    container
                    spacing={3}
                    marginBottom={3}
                    direction="row"
                    justifyContent={'left'}
                    alignItems="center">
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Select
                            name="status"
                            value={filter?.status}
                            onChange={handleStatusChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{
                              fontSize: '16px'
                            }}
                            MenuProps={MenuProps}
                            fullWidth>
                            <MenuItem value="">
                                <Typography>Select status</Typography>
                            </MenuItem>
                            {blueDartStatusArray &&
                                blueDartStatusArray.map((item, index) => (
                                    <MenuItem key={index} value={item?.id}>
                                        {item?.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <div className="date-range-input-container">
                            <TextField
                                aria-describedby={id}
                                onClick={handleClick}
                                type="text"
                                value={
                                    !filter?.dateRange[0].skip
                                      ? `${filter?.dateRange[0].startDate.toLocaleDateString()} - ${filter?.dateRange[0].endDate.toLocaleDateString()}`
                                      : 'Date Range'
                                }
                                readOnly
                                fullWidth
                            />
                            <div
                                style={{ display: 'flex', flexDirection: 'row' }}
                                className="calendar-icon">
                                {!filter?.dateRange[0].skip && (
                                    <IconButton sx={{ marginRight: '30px' }} onClick={handleClear}>
                                        <ClearIcon />
                                    </IconButton>
                                )}
                                <img
                                    src={calendericon}
                                    alt="calender"
                                    className="calendar-icon"
                                    onClick={handleClick}
                                />
                            </div>
                        </div>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left'
                            }}>
                            <DateRangeComponent
                                maxDate={new Date()}
                                dateRange={filter?.dateRange}
                                setDateRange={dateRangeHandler}
                            />
                        </Popover>
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                        <TextField
                            value={filter?.search}
                            name="search"
                            id="search"
                            placeholder={PLACEHOLDER.SEARCHDOCUMENT}
                            onChange={handleSearchTextfield}
                            InputLabelProps={{
                              shrink: false
                            }}
                            InputProps={{
                              style: {
                                borderRadius: '5px'
                              },
                              startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton aria-label="toggle password visibility">
                                            <img src={search} alt="search" />
                                        </IconButton>
                                    </InputAdornment>
                              )
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                        <Button variant="primary" onClick={handleFilterClear}>
                            Clear
                        </Button>
                    </Grid>
                </Grid>
                <IndividualPhysialDocumentTable
                    physicalDocument={list}
                    isLoading={isLoading}
                    paginationFilter={paginationFilter}
                    setPaginationFilter={setPaginationFilter}
                    totalCount={totalCount}
                />
            </>
        </Box>
  )
}

export default IndividualPhysicalDocument
