import React from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
// import palette from "../../.theme/palette";
import PropTypes from 'prop-types'
import palette from '../../theme/palette'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}

const CommonMultipleMessageModal = ({ CommonModalState, handleOkButtonCommonModal }) => {
  return (
        <Modal
            open={CommonModalState?.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid p={2}>
                    <Typography
                        variant="darkValueLarge"
                        component="div"
                        mb={3}
                        sx={{ textAlign: 'center', fontSize: '22px', lineHeight: '31px' }}>
                        {CommonModalState?.title}
                    </Typography>
                    {CommonModalState?.message?.map((item, index) => (
                        <Grid mb={1} key={index}>
                            <Typography
                                variant="darkValue"
                                sx={{ fontSize: '16px', lineHeight: '31px' }}>
                                {item}
                            </Typography>
                        </Grid>
                    ))}

                    <Grid display="flex">
                        <Button variant="primary" onClick={handleOkButtonCommonModal}>
                            {CommonModalState?.buttonText}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}

export default CommonMultipleMessageModal
CommonMultipleMessageModal.propTypes = {
  handleOkButtonCommonModal: PropTypes.func,
  CommonModalState: PropTypes.object
}
