import React, { useState, useEffect, useContext } from 'react'
import './Sidebar.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../assets/headerLogo/digidoc.svg'
import digiLocker from '../../assets/images/digidocker-logo.svg'
import {
  SIDEBAR,
  SIDEBARNO_VISIBLE
  // SIDEBAR_BUSINESS,
  // SIDEBAR_CORPORATE,
  // SIDEBAR_INDIVIDUAL,
  // SUB_ROLE
} from '../../constants/enum'
import { getSession } from '../../util/helper'
import { Grid, Typography } from '@mui/material'
import palette from '../../theme/palette'
import { RestrictionContext } from '../../contextapi/RestrictionContext'
import { toast } from 'react-toastify'
import { DIGILOCKERINFORMATIONMESSAGE, MESSAGE_CONFIRMATION } from '../../constants/message'
import CommonMultipleMessageModal from '../commonMessageModal/CommonMultipleMessageModal'

export default function Sidebar () {
  const [selectedItem, setSelectedItem] = useState('Home')
  const [visibility] = useState('visible')
  const navigate = useNavigate()
  const location = useLocation()
  const [openCommonModal, setOpenCommonModal] = useState({})

  // Restriction context api
  const { handleRestriction } = useContext(RestrictionContext)

  const noVisible = [SIDEBARNO_VISIBLE?.SUBSCRIPTION, SIDEBARNO_VISIBLE?.VERIFYSUBSCRIPTION]

  const noVisibleActive = [
    SIDEBARNO_VISIBLE?.SUBSCRIPTION,
    SIDEBARNO_VISIBLE?.VERIFYSUBSCRIPTION,
    SIDEBARNO_VISIBLE?.MYPROFILE
  ]

  const role = JSON.parse(getSession('user')).role
  const subRole = JSON.parse(getSession('user')).subRole

  // const sidebarContent =
  //       role === 'INDIVIDUAL'
  //         ? SIDEBAR_INDIVIDUAL
  //         : role === 'BUSINESS' && (subRole === SUB_ROLE?.OWNER || subRole === SUB_ROLE?.ADMIN)
  //           ? SIDEBAR_BUSINESS
  //           : SIDEBAR_CORPORATE

  const sidebarContent = SIDEBAR.filter(
    (item) => item.roles.includes(role) && item.subRoles.includes(subRole)
  )

  console.log('SIDEBAR CONTENT-====> ', sidebarContent)
  const sidebarActive = [
    {
      title: 'Home',
      route: '/Home'
    },
    {
      title: 'My Document',
      route: '/myDocument'
    },
    {
      title: 'Payment History',
      route: '/invoice'
    },
    {
      title: 'Delivery Status',
      route: '/physicalDocument'
    },
    {
      title: 'Create Document',
      route: '/createDocument'
    },
    {
      title: 'Help',
      route: '/help'
    },
    {
      title: 'Help',
      route: '/help/tickets'
    },
    {
      title: 'Help',
      route: '/addTicket'
    },
    {
      title: 'Help',
      route: '/ticketSummary'
    },
    {
      title: 'Create Document',
      route: '/documentDetails'
    },
    {
      title: 'Create Document',
      route: '/paymentSummary'
    },
    {
      title: 'Create Document',
      route: '/thankYou'
    },
    {
      title: 'Create Document',
      route: '/customDocument'
    },
    {
      title: 'Create Document',
      route: '/commentSection'
    },
    {
      title: 'Create Document',
      route: '/signature'
    },
    {
      title: 'Create Document',
      route: '/customDocument/:documentId'
    },
    {
      title: 'Manage Staff Users',
      route: '/manageStaffUsers'
    },
    {
      title: 'Manage Staff Users',
      route: '/addNewUser'
    },
    {
      title: 'Wallet & Transaction',
      route: '/walletAndTransactions'
    },
    {
      title: 'Transfer Documents to DigiLocker',
      route: '/digi'
    }
  ]

  function handleSidebarClick (sidebarItem) {
    // if (sidebarItem.title === "")
    //   setVisibility((prev) => (prev === "visible" ? "hidden" : "visible"));
    // else
    const errormessage = handleRestriction()
    if (errormessage && !['Home'].includes(sidebarItem?.title)) {
      toast.error(errormessage)
      return errormessage
    }
    setSelectedItem(sidebarItem.title)
    navigate(`${sidebarItem.route}`, {
      state: {
        HeaderTitle: sidebarItem.title
      }
    })
  }

  useEffect(() => {
    const headerText = sidebarActive.find((item) =>
      item.route.includes(location.pathname.split('/')[1])
    )
    console.log('in useEffect called', location.pathname, headerText)

    setSelectedItem(headerText?.title)
  }, [location])

  return (
        <Grid
            sx={{
              // backgroundColor: palette.primary.light,
              background: 'linear-gradient(180deg, #f5f7f9  28.86%, #3b6654 90.97%)',
              borderRight: `1px solid ${palette.text.light}`
            }}
            id={noVisible.includes(location.pathname) ? 'SidebarHidden' : 'Sidebar'}
            className={visibility}>
            <Grid mt={3} ml={2} mb={3}>
                <img src={logo} />
            </Grid>
            {sidebarContent.map((sidebarItem, key) => (
                <Typography
                    variant="modalLabelBold"
                    // sx={{ fontWeight: 600 }}
                    id="Sidebaritem"
                    sx={{
                      borderRight:
                            sidebarItem.title === selectedItem &&
                            !noVisibleActive.includes(location.pathname)
                              ? '5px solid linear-gradient(89.91deg,#0d9249 -3.86%, #05559e 105.97%)'
                              : palette.text.main,
                      background:
                            sidebarItem.title === selectedItem &&
                            !noVisibleActive.includes(location.pathname)
                              ? palette.gradient.main
                              : 'transparent',
                      fontWeight:
                            sidebarItem.title === selectedItem &&
                            !noVisibleActive.includes(location.pathname) &&
                            700
                    }}
                    key={key}
                    onClick={() => handleSidebarClick(sidebarItem)}>
                    {sidebarItem?.isDigiLockerOption ? (
                        <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '20px'
                            }}>
                            {/* <img
                                id="Sidebaritem_icon"
                                src={digiLocker}
                                alt="DigiLocker Logo"
                                style={{ width: '30px', marginRight: '10px', borderRadius: '8px' }}
                            />
                            <div
                                id="Sidebaritem_title"
                                style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography
                                    style={{
                                      color: 'red',
                                      fontWeight: 'bold',
                                      fontSize: '14px',
                                      textAlign: 'center'
                                    }}>
                                    Free!
                                </Typography>
                                <Typography
                                    style={{
                                      fontSize: '14px',
                                      color: palette.primary.dark,
                                      fontWeight: '600',
                                      lineHeight: '1.2'
                                    }}>
                                    Transfer documents to DigiLocker
                                </Typography>
                                <Typography
                                    component="a"
                                    href="#"
                                    onClick={(e) => {
                                      // e.preventDefault()
                                      // navigate('/digilocker/info')
                                    }}
                                    style={{
                                      fontSize: '12px',
                                      color: 'blue',
                                      textDecoration: 'underline'
                                    }}>
                                    Know more...
                                </Typography>
                            </div> */}
                        </div>
                    ) : (
                        <>
                            <img
                                id="Sidebaritem_icon"
                                style={{
                                  filter:
                                        sidebarItem.title === selectedItem &&
                                        !noVisibleActive.includes(location.pathname)
                                          ? 'invert(1) brightness(2)'
                                          : 'invert(0)'
                                }}
                                src={sidebarItem.logo}
                            />
                            {visibility === 'visible' ? (
                                <div
                                    id="Sidebaritem_title"
                                    style={{
                                      color:
                                            sidebarItem.title === selectedItem &&
                                            !noVisibleActive.includes(location.pathname)
                                              ? palette.primary.light
                                              : 'inherit',
                                      fontSize: '18px'
                                    }}>
                                    {sidebarItem.title}
                                </div>
                            ) : (
                              ''
                            )}
                        </>
                    )}
                </Typography>
            ))}

            <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '15px',
                  marginTop: '20px',
                  borderTop: 'groove',
                  borderColor: 'ButtonShadow'
                }}>
                <img
                    // id="Sidebaritem_icon"
                    src={digiLocker}
                    alt="DigiLocker Logo"
                    style={{
                      width: '60px',
                      marginRight: '10px',
                      borderRadius: '8px',
                      background: 'white',
                      padding: '5px'
                    }}
                />
                <div
                    style={{
                      alignSelf: 'stretch',
                      width: '1px',
                      background: 'white',
                      margin: '0 10px'
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        style={{
                          color: 'red',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          textAlign: 'center'
                        }}>
                        Free!
                    </Typography>
                    <Typography
                        style={{
                          fontSize: '16px',
                          color: palette.primary.dark,
                          fontWeight: '600',
                          lineHeight: '1.2'
                        }}>
                        Transfer documents to DigiLocker
                    </Typography>
                    <Link
                        component="a"
                        onClick={(e) => {
                          // e.preventDefault()
                          // open digilocker information modal
                          setOpenCommonModal({
                            open: true,
                            message: DIGILOCKERINFORMATIONMESSAGE,
                            title: MESSAGE_CONFIRMATION?.DIGILOCKERINFORMATIONTITLE,
                            buttonText: 'Ok'
                          })
                        }}
                        style={{
                          fontSize: '12px',
                          color: 'blue',
                          textDecoration: 'underline'
                        }}>
                        Know more...
                    </Link>
                </div>
            </div>

            <CommonMultipleMessageModal
                CommonModalState={openCommonModal}
                handleOkButtonCommonModal={() => {
                  setOpenCommonModal({
                    open: false,
                    message: []
                  })
                }}
            />
        </Grid>
  )
}
