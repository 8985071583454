import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React from 'react'
import palette from '../../../theme/palette'
import StyledTableCell from '../../../StyledComponents/TableStyles/TableStyles'
import PropTypes from 'prop-types'
import './TemplateTable.css'
import { TEMPLATETYPE } from '../../../constants/documentConstans'
const TemplateTable = ({
  articleTemplateList,
  handleFormSubmit,
  templateTypeSelected,
  initializeForm
}) => {
  return (
        <Paper
            sx={{
              width: '100%',
              overflow: 'hidden',
              border: `0.5px solid ${palette.text.light}`
            }}>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ whiteSpace: 'nowrap' }}>
                            <StyledTableCell
                                align="center"
                                sx={{
                                  borderRight: '1px solid #ddd',
                                  Padding: '8px',
                                  width: '300px'
                                }}>
                                Article Type
                            </StyledTableCell>
                            <StyledTableCell align="center">Template</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {articleTemplateList?.length > 0 &&
                            articleTemplateList.map((articleCategory) => (
                                <TableRow key={articleCategory.id}>
                                    <StyledTableCell
                                        sx={{
                                          whiteSpace: 'nowrap',
                                          borderRight: '1px solid #ddd',
                                          Padding: '8px'
                                        }}
                                        align="center">
                                        <Typography variant="modalLabelBold">
                                            {articleCategory.name}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Grid
                                            spacing={2}
                                            container
                                            justifyContent="flex-start"
                                            alignContent="center"
                                            direction="row">
                                            <Grid
                                                item
                                                xs={12}
                                                md={3}
                                                sm={2}
                                                lg={3}
                                                key={articleCategory?.id}>
                                                <>
                                                    {TEMPLATETYPE?.SYSTEMGENERATED?.value ===
                                                        templateTypeSelected && (
                                                        <Typography
                                                            className="article-templ"
                                                            sx={{
                                                              cursor: 'pointer',
                                                              justifyContent: 'center',
                                                              textAlign: 'center',
                                                              display: 'flex',
                                                              padding: '5px',
                                                              '&:hover': {
                                                                background:
                                                                        'linear-gradient(89.91deg, #30A772 -3.86%, #06B5FA 105.97%)',
                                                                color: 'white'
                                                              },
                                                              backgroundColor: '#f5f7f9',
                                                              borderRadius: '5px'
                                                            }}
                                                            onClick={() =>
                                                              handleFormSubmit(articleCategory)
                                                            }>
                                                            {articleCategory?.templateName}
                                                        </Typography>
                                                    )}
                                                    {TEMPLATETYPE?.CUSTOMUPLOAD?.value ===
                                                        templateTypeSelected && (
                                                        <Typography
                                                            className={
                                                                initializeForm?.template ===
                                                                articleCategory?.id
                                                                  ? 'selected-Template'
                                                                  : 'article-templ'
                                                            }
                                                            sx={{
                                                              cursor: 'pointer',
                                                              justifyContent: 'center',
                                                              textAlign: 'center',
                                                              display: 'flex',
                                                              padding: '5px',
                                                              '&:hover': {
                                                                background:
                                                                        'linear-gradient(89.91deg, #30A772 -3.86%, #06B5FA 105.97%)',
                                                                color: 'white'
                                                              },
                                                              backgroundColor: '#f5f7f9',
                                                              borderRadius: '5px'
                                                            }}
                                                            onClick={() =>
                                                              handleFormSubmit(articleCategory)
                                                            }>
                                                            {articleCategory?.templateName}
                                                        </Typography>
                                                    )}
                                                </>
                                            </Grid>
                                            {/* {articleCategory?.templates?.map((template) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={3}
                                                    sm={2}
                                                    lg={3}
                                                    key={template?.id}>
                                                    <>
                                                        {TEMPLATETYPE?.SYSTEMGENERATED?.value ===
                                                            templateTypeSelected && (
                                                            <Typography
                                                                className="article-templ"
                                                                sx={{
                                                                  cursor: 'pointer',
                                                                  justifyContent: 'center',
                                                                  textAlign: 'center',
                                                                  display: 'flex',
                                                                  padding: '5px',
                                                                  '&:hover': {
                                                                    background:
                                                                            'linear-gradient(89.91deg, #30A772 -3.86%, #06B5FA 105.97%)',
                                                                    color: 'white'
                                                                  },
                                                                  backgroundColor: '#f5f7f9',
                                                                  borderRadius: '5px'
                                                                }}
                                                                onClick={() =>
                                                                  handleFormSubmit(template)
                                                                }>
                                                                {template?.templateName}
                                                            </Typography>
                                                        )}
                                                        {TEMPLATETYPE?.CUSTOMUPLOAD?.value ===
                                                            templateTypeSelected && (
                                                            <Typography
                                                                className={
                                                                    initializeForm?.template ===
                                                                    template?.id
                                                                      ? 'selected-Template'
                                                                      : 'article-templ'
                                                                }
                                                                sx={{
                                                                  cursor: 'pointer',
                                                                  justifyContent: 'center',
                                                                  textAlign: 'center',
                                                                  display: 'flex',
                                                                  padding: '5px',
                                                                  '&:hover': {
                                                                    background:
                                                                            'linear-gradient(89.91deg, #30A772 -3.86%, #06B5FA 105.97%)',
                                                                    color: 'white'
                                                                  },
                                                                  backgroundColor: '#f5f7f9',
                                                                  borderRadius: '5px'
                                                                }}
                                                                onClick={() =>
                                                                  handleFormSubmit(template)
                                                                }>
                                                                {template?.templateName}
                                                            </Typography>
                                                        )}
                                                    </>
                                                </Grid>
                                            ))} */}
                                        </Grid>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {articleTemplateList?.length === 0 && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fff' }}>
                        No Article Template Found
                    </Typography>
                )}
            </TableContainer>
        </Paper>
  )
}

export default TemplateTable
TemplateTable.propTypes = {
  articleTemplateList: PropTypes.array,
  handleFormSubmit: PropTypes.func,
  templateTypeSelected: PropTypes.string,
  initializeForm: PropTypes.object
}
