import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import React from 'react'
import palette from '../../../../theme/palette'
import caution from '../../../../assets/images/caution.svg'
import PropTypes from 'prop-types'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark}, 0.06)`,
  borderRadius: '12px',
  p: 4
}

export const DowntimeModal = ({ downtimeModalState, handleDowntimeModalClose }) => {
  return (
        <Modal
            open={downtimeModalState?.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid p={2}>
                    <Typography
                        variant="labelLarge"
                        component="div"
                        sx={{ fontSize: '25px', marginBottom: '10px', fontWeight: 600 }}>
                        {downtimeModalState.popUpHeading
                          ? downtimeModalState?.popUpHeading
                          : 'Maintenance Downtime'}

                        <img src={caution} style={{ marginBottom: 0 }} />
                    </Typography>

                    <Grid mb={3}>
                        <Typography
                            variant="darkValue"
                            sx={{ fontSize: '15px', lineHeight: '31px' }}>
                            {downtimeModalState.message}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Button variant="primary" onClick={handleDowntimeModalClose}>
                            Okay
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}

DowntimeModal.propTypes = {
  downtimeModalState: PropTypes.object,
  handleDowntimeModalClose: PropTypes.func
}
