import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material'
import PropTypes from 'prop-types'

const NumberInputFieldWithArrows = ({ form, handleCopiesChange }) => {
  const [value, setValue] = useState(form?.copies || 1)
  const handleIncrement = () => {
    const newValue = value < 99 ? value + 1 : 99
    setValue(newValue)
    handleCopiesChange(newValue)
  }

  const handleDecrement = () => {
    const newValue = value > 1 ? value - 1 : 1
    setValue(newValue)
    handleCopiesChange(newValue)
  }

  const handleChange = (event) => {
    let newValue = parseInt(event.target.value, 10)
    if (isNaN(newValue) || newValue < 1) {
      newValue = 1
    } else if (newValue > 99) {
      newValue = 99
    }
    setValue(newValue)
    handleCopiesChange(newValue)
  }

  return (
        <TextField
            type="number"
            variant="outlined"
            value={value}
            onChange={handleChange}
            InputProps={{
              inputProps: { min: 1 },
              endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            size="small"
                            onClick={handleIncrement}
                            sx={{
                              padding: 0,
                              margin: 0
                            }}>
                            <ArrowDropUp fontSize="small" />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleDecrement}
                            sx={{
                              padding: 0,
                              margin: 0
                            }}>
                            <ArrowDropDown fontSize="small" />
                        </IconButton>
                    </InputAdornment>
              )
            }}
            sx={{ width: '120px' }}
        />
  )
}

export default NumberInputFieldWithArrows

NumberInputFieldWithArrows.propTypes = {
  form: PropTypes.object,
  handleCopiesChange: PropTypes.func
}
