import React, { useState, useEffect, useContext } from 'react'

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Select,
  MenuItem
} from '@mui/material'
import flag from '../../../assets/images/india-flag.svg'
import {
  useGetCityQuery,
  // useGetProfileQuery,
  useGetStateQuery,
  useLazyResendOtpQuery,
  useLazyVerifyOtpQuery
} from '../../../store/services/userServices'
import { useNavigate } from 'react-router-dom'
// import { EXCEPTTHISSYMBOLS } from '../../../constants/enum'
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles'
import { toast } from 'react-toastify'
import { CLVALIDATIONS, PATTERN } from '../../../constants/enum'
import OtpModal from '../../../components/OtpModal/OtpModal'
import { useUpdateProfileMutation } from '../../../store/services/profleServices'
import { ProfileContext } from '../../../contextapi/ProfileContext'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import { PLACEHOLDER } from '../../../constants/placeholder'

const EditProfile = () => {
  const navigate = useNavigate()

  // Open otp modal if user enters phone number
  const [openOtpModal, setOpenOtpModal] = useState(false)

  // otp child counter state
  const [counter, setCounter] = useState(45)
  const [code, setCode] = useState('')

  // Update Profile form
  const [updateProfileForm, setupdateProfileForm] = useState({})

  // States Array
  const { data: stateArray = [] } = useGetStateQuery({})

  // Profile context api
  const { profile: profileData } = useContext(ProfileContext)

  // Update profile mutation
  const [profileUpdate] = useUpdateProfileMutation({})

  // Get cities according to the state selected
  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: updateProfileForm.stateId },
    {
      skip: !updateProfileForm.stateId
    }
  )

  // state for loader
  const [backdropState, setBackdropState] = useState(false)

  const [resendOtp] = useLazyResendOtpQuery()

  const [verifyOtp] = useLazyVerifyOtpQuery()

  useEffect(() => {
    console.log('im here')
    if (profileData) {
      setupdateProfileForm({
        name: profileData?.name || '',
        email: profileData?.email || '',
        phone: profileData?.phone || '',
        address: profileData?.address || '',
        stateId: profileData?.state?.id || '',
        cityId: profileData?.city?.id || '',
        pinCode: profileData?.pinCode || ''
      })
    }
  }, [profileData])

  const handleUpdateProfileFormChange = (event) => {
    if (event.target.name === 'stateId') {
      setupdateProfileForm((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
        cityId: ''
      }))
    } else {
      setupdateProfileForm((prev) => ({
        ...prev,
        [event.target.name]: event.target.value.replace(/\s/gy, '')
      }))
    }
  }

  const handleVerifyOtp = () => {
    setBackdropState(true)
    verifyOtp(code)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response.message)
        setOpenOtpModal(false)
        handleUpdateProfile()
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('execption:OTP VERIFICATION')
      })
  }

  const handlePhoneNumberVerify = () => {
    setBackdropState(true)
    resendOtp(updateProfileForm?.phone)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
        setOpenOtpModal(true)
        setCode('')
        setCounter(45)
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('error')
      })
    console.log('phone changed', openOtpModal)
  }
  const handleUpdateProfile = () => {
    const dataToSend = {
      name: updateProfileForm?.name,
      phone: updateProfileForm?.phone,
      address: updateProfileForm?.address,
      stateId: updateProfileForm?.stateId,
      cityId: updateProfileForm?.cityId,
      pinCode: Number(updateProfileForm?.pinCode)
    }
    setBackdropState(true)
    profileUpdate({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        navigate('/myProfile', {
          state: {
            HeaderTitle: 'My Profile'
          }
        })
        toast.success(response?.message)
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('EXCEPTION:CHANGE PASSWORD', execption)
        // toast.error(execption?.message)
      })
  }
  // Handle update profile form submit
  const handleUpdateFormSubmit = () => {
    console.log(updateProfileForm?.phone, profileData?.phone)
    if (updateProfileForm?.phone !== profileData?.phone) {
      handlePhoneNumberVerify()
    } else {
      handleUpdateProfile()
    }
  }

  return (
        <Box sx={{ padding: '3%' }}>
            <form
                onSubmit={(event) => {
                  event.preventDefault()
                  handleUpdateFormSubmit()
                }}>
                <Grid container spacing={5} marginBottom={3}>
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Name
                        </Typography>
                        <TextField
                            value={updateProfileForm?.name || ''}
                            placeholder={PLACEHOLDER?.NAMEASPERAADHAR}
                            disabled
                            required
                            id="name"
                            name="name"
                            fullWidth
                            onChange={handleUpdateProfileFormChange}
                        />
                    </Grid>
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Email address
                        </Typography>
                        <TextField
                            value={updateProfileForm?.email || ''}
                            disabled
                            type="email"
                            required
                            id="email"
                            name="email"
                            // inputProps={{
                            //   style: {
                            //     height: "10px",
                            //     // width:"322px"
                            //   },
                            // }}
                            fullWidth
                            onChange={handleUpdateProfileFormChange}
                        />
                    </Grid>
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Phone Number
                        </Typography>
                        <TextField
                            required={Boolean(profileData?.phone)}
                            value={updateProfileForm?.phone || ''}
                            type="number"
                            id="phoneNumber"
                            name="phone"
                            inputProps={{
                              type: 'text',
                              // inputMode: 'numeric',
                              pattern: '[0-9]*',
                              maxlength: 10,
                              minlength: 10
                            }}
                            // onKeyDown={(e) =>
                            //   exceptThisSymbols.includes(e.key) && e.preventDefault()
                            // }
                            // onInput={(e) => {
                            //   e.target.value = Math.max(0, parseInt(e.target.value))
                            //     .toString()
                            //     .slice(0, 10)
                            // }}
                            InputProps={{
                              startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={flag} />
                                        &nbsp;+91 |
                                    </InputAdornment>
                              )
                            }}
                            fullWidth
                            // onChange={handleUpdateProfileFormChange}
                            onChange={(e) => {
                              if (
                                e.target.value === '' ||
                                    PATTERN?.NUMBERONLY.test(e?.target?.value)
                              ) {
                                handleUpdateProfileFormChange(e)
                              }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={5} marginBottom={3}>
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Address
                        </Typography>
                        <TextField
                            value={updateProfileForm?.address || ''}
                            type="text"
                            required
                            id="address"
                            name="address"
                            fullWidth
                            inputProps={{
                              type: 'text',
                              maxlength: CLVALIDATIONS?.ADDRESS?.MAXLENGTH,
                              minlength: CLVALIDATIONS?.ADDRESS?.MINLENGTH
                            }}
                            onChange={handleUpdateProfileFormChange}
                        />
                    </Grid>
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            State
                        </Typography>

                        <Select
                            name="stateId"
                            value={updateProfileForm?.stateId || ''}
                            onChange={handleUpdateProfileFormChange}
                            required
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ fontSize: '16px' }}
                            MenuProps={MenuProps}
                            fullWidth>
                            <MenuItem value="" disabled>
                                <Typography>Select State</Typography>
                            </MenuItem>

                            {stateArray &&
                                stateArray.map((item) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={item.id}
                                        value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            City
                        </Typography>
                        <Select
                            required
                            value={updateProfileForm?.cityId || ''}
                            name="cityId"
                            onChange={(event) => {
                              setupdateProfileForm((prev) => ({
                                ...prev,
                                [event.target.name]: event.target.value
                              }))
                            }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ fontSize: '16px' }}
                            MenuProps={MenuProps}
                            fullWidth>
                            <MenuItem value="" disabled>
                                <Typography>Select City</Typography>
                            </MenuItem>

                            {cityArray &&
                                cityArray.map((item) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={item.id}
                                        value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={5} marginBottom={3}>
                    <Grid lg={4} md={4} sm={12} xs={12} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Pin Code
                        </Typography>
                        <TextField
                            value={updateProfileForm?.pinCode || ''}
                            type="text"
                            inputProps={{
                              type: 'text',
                              // inputMode: 'numeric',
                              pattern: '[0-9]*',
                              maxlength: 6,
                              minlength: 6
                            }}
                            required
                            id="pinCode"
                            name="pinCode"
                            // inputProps={{
                            //   style: {
                            //     height: "10px",
                            //     // width:"322px"
                            //   },
                            // }}
                            // onInput={(e) => {
                            //   e.target.value = Math.max(0, parseInt(e.target.value))
                            //     .toString()
                            //     .slice(0, 6)
                            // }}
                            fullWidth
                            onChange={(e) => {
                              if (e.target.value === '' || /^[0-9\b]+$/.test(e?.target?.value)) {
                                handleUpdateProfileFormChange(e)
                              }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid marginTop={8} marginBottom={8}>
                    <Button type="submit" variant="primary">
                        Update info
                    </Button>
                </Grid>
            </form>
            {openOtpModal && (
                <OtpModal
                    code={code}
                    setCode={setCode}
                    counter={counter}
                    setCounter={setCounter}
                    phoneNumberUpdate={updateProfileForm?.phone}
                    handleVerifyOtp={handleVerifyOtp}
                    openOtpModal={openOtpModal}
                    handleOtpModalClose={() => setOpenOtpModal(false)}
                />
            )}
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default EditProfile
