import {
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import './TicketsTable.css'
import React, { useState } from 'react'
import viewicon from '../../../../../assets/actions/actionView.svg'
import { ALERT_MESSAGE } from '../../../../../constants/message'
import AlertModal from '../../../../../components/AlertModal/AlertModal'
import {
  TICKETSTABLEHEADERBUSINESS,
  TICKETSTABLEHEADERCORP,
  TICKETSTABLEHEADERINDV
} from '../../../../../constants/faqConstants'
import { getSession } from '../../../../../util/helper'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import CustomTablePagination from '../../../../../components/CustomTablePagination/CustomTablePagination'
import palette from '../../../../../theme/palette'
import { ROLES } from '../../../../../constants/enum'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: palette.secondary.light,
    color: palette.text.main,
    fontSize: '14x',
    fontWeight: 600
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: palette.primary.light,
    color: palette.text.dark,
    fontSize: '14px',
    fontWeight: 400,
    maxWidth: '300px'
  }
}))

const TicketsTable = ({
  ticketTableData,
  totalCount,
  isLoading,
  setPaginationFilter,
  paginationFilter
}) => {
  const navigate = useNavigate()
  // Alert modal state
  const [openAlertModal, setOpenAlertModal] = useState(false)

  // Role of the current user
  const role = JSON.parse(getSession('user')).role

  // pagination
  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPaginationFilter((prev) => ({
      ...prev,
      page: newPage
    }))
    // setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPaginationFilter((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }))
    // setRowsPerPage(parseInt(event.target.value, 10))
    // setPage(0)
  }

  // Ticket table header for different roles
  const TicketsTableHeaders =
        role === 'INDIVIDUAL'
          ? TICKETSTABLEHEADERINDV
          : role === 'BUSINESS'
            ? TICKETSTABLEHEADERBUSINESS
            : TICKETSTABLEHEADERCORP

  const alertModalState = {
    title: 'Action Required',
    message: ALERT_MESSAGE.DELETETICKET,
    cancelButtonText: 'No',
    submitButtonText: 'Yes'
  }

  return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', border: `0.5px solid ${palette.text.light}` }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 22rem)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {TicketsTableHeaders?.map((headerName, index) => (
                                <StyledTableCell align="left" key={index}>
                                    {headerName}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            [0, 1, 2, 3, 4].map((item, index) => (
                                <TableRow key={index}>
                                    {TicketsTableHeaders?.map((item, hindex) => (
                                        <StyledTableCell key={hindex}>
                                            <Skeleton />
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        {
                            // (rowsPerPage > 0
                            //   ? ticketTableData?.slice(
                            //     page * rowsPerPage,
                            //     page * rowsPerPage + rowsPerPage
                            //   )
                            //   : ticketTableData
                            // )
                            ticketTableData?.map((ticket, index) => (
                                <TableRow key={index}>
                                    {/* {Object.keys(item).map((item1)=>(
                         <StyledTableCell align="left">{item[`${item1}`]}</StyledTableCell>
                    ))}  */}
                                    <StyledTableCell scope="row">
                                        {ticket?.ticketNumber}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {ticket?.issueCategory}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {ticket?.description}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {ticket?.createdDate}
                                    </StyledTableCell>
                                    {role === ROLES?.CORPORATE && (
                                        <StyledTableCell align="left">
                                            {ticket?.location?.name}
                                        </StyledTableCell>
                                    )}
                                    {role !== ROLES?.INDIVIDUAL && (
                                        <StyledTableCell align="left">
                                            {ticket?.createdBy?.name}
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell align="left">
                                        {ticket?.status?.value}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className="actionIcons">
                                            <img
                                                src={viewicon}
                                                alt="viewIcon"
                                                title="View ticket"
                                                onClick={() =>
                                                  navigate(
                                                        `/ticketSummary/${encodeURIComponent(ticket?.id)}`,
                                                        {
                                                          state: {
                                                            HeaderTitle: 'Ticket Summary'
                                                          }
                                                        }
                                                  )
                                                }
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    </StyledTableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {ticketTableData.length === 0 && !isLoading && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        No Data Found
                    </Typography>
                )}
            </TableContainer>

            {/* Pagination */}
            <CustomTablePagination
                count={totalCount}
                rowsPerPage={paginationFilter?.rowsPerPage}
                page={paginationFilter?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {/* Delete alert modal */}
            {openAlertModal && (
                <AlertModal
                    openAlertModal={openAlertModal}
                    handleAlertModalClose={() => setOpenAlertModal(false)}
                    alertModalState={alertModalState}
                />
            )}
        </Paper>
  )
}

export default TicketsTable

TicketsTable.propTypes = {
  ticketTableData: PropTypes.array,
  isLoading: PropTypes.bool,
  totalCount: PropTypes.number,
  setPaginationFilter: PropTypes?.func,
  paginationFilter: PropTypes?.object
}
