import React from 'react'
import palette from '../../../../theme/palette'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 425,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark} 0.06)`,
  borderRadius: '3px',
  p: 2
}
const PartyCountModal = ({
  partyCountModalState,
  handlePartyCountChange,
  handleAlertModalClose,
  partyCountOptions,
  partyCountValue
}) => {
  return (
        <Modal
            open={partyCountModalState}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid
                    container
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    spacing={2}
                    m={2}>
                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                        Please select party type
                    </Typography>
                    <FormControl sx={{ marginTop: '20px' }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={partyCountValue}
                            onChange={handlePartyCountChange}>
                            {partyCountOptions &&
                                partyCountOptions.map((item, user) => (
                                    <FormControlLabel
                                        key={user}
                                        value={item?.id}
                                        control={
                                            <Radio
                                                sx={{
                                                  color: palette.text.main,
                                                  '&.Mui-checked': {
                                                    color: palette.success.dark
                                                  }
                                                }}
                                            />
                                        }
                                        label={
                                            <Grid>
                                                <Typography
                                                    variant="labelSmall"
                                                    sx={{
                                                      color: palette.text.dark,
                                                      fontSize: '14px'
                                                    }}>
                                                    {item?.label}
                                                </Typography>
                                            </Grid>
                                        }
                                    />
                                ))}
                        </RadioGroup>
                    </FormControl>
                    <Grid>
                        <Button
                            variant="primary"
                            onClick={handleAlertModalClose}
                            sx={{ width: '100px', marginTop: '20px' }}>
                            Proceed
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
  )
}

export default PartyCountModal
PartyCountModal.propTypes = {
  partyCountModalState: PropTypes.bool,
  handlePartyCountChange: PropTypes.func,
  handleAlertModalClose: PropTypes.func,
  partyCountOptions: PropTypes.array,
  partyCountValue: PropTypes.string
}
