export const COMPONENTTYPES = {
  TEXTFIELD: 'text',
  DATEFIELD: 'date',
  SELECT: 'select',
  SELECTTEXT: 'select-text',
  RADIOTEXT: 'radio-text'
}

export const FIELDCONFIG = {
  MANDATORY: 'mandatory',
  OPTIONS: 'options',
  LENGTH: 'length'
}

export const DOCUEMENTFILTERSTATUS = {
  ALLSTATUSES: {
    ID: 1,
    LABEL: 'All Status'
  },
  INDRAFT: {
    ID: 2,
    LABEL: 'In Draft'
  },
  CONSENTREQUESTED: {
    ID: 3,
    LABEL: 'Consent Requested'
  },
  WAITINGFORPAYMENT: {
    ID: 4,
    LABEL: 'Waiting For Payment'
  },
  SIGNATURESREQUESTED: {
    ID: 5,
    LABEL: 'Signatures Requested'
  },
  INPROCESS: {
    ID: 6,
    LABEL: 'In Process'
  },
  DISPATCHED: {
    ID: 7,
    LABEL: 'Dispatched'
  },
  DELIVERED: {
    ID: 8,
    LABEL: 'Delivered'
  },
  CONSENTREQUIRED: {
    ID: 9,
    LABEL: 'Consent Required'
  },
  CONSENTSUBMITTED: {
    ID: 10,
    LABEL: 'Consent Submitted'
  },
  CHANGESREQUESTED: {
    ID: 11,
    LABEL: 'Changes Requested'
  },
  SIGNATURESREQUIRED: {
    ID: 12,
    LABEL: 'Signatures Required'
  },
  DOCUMENTSIGNED: {
    ID: 13,
    LABEL: 'Document Signed'
  },
  HANDEDOVER: {
    ID: 14,
    LABEL: 'Handed Over'
  },
  READYFORDISPATCH: {
    ID: 15,
    LABEL: 'Ready for Dispatch'
  },
  RETURNED: {
    ID: 16,
    LABEL: 'Returned'
  },
  READYFORHANDOVER: {
    ID: 17,
    LABEL: 'Ready For Handover'
  },
  HANDOVER: {
    ID: 18,
    LABEL: 'Handover'
  },
  SIGNATUREPLACEMENT: {
    ID: 19,
    LABEL: 'Signature Placement'
  },
  CORRECTIONREQUIRED: {
    ID: 20,
    LABEL: 'Correction Required'
  },
  ESTAMPPENDING: {
    ID: 21,
    LABEL: 'Estamp Pending'
  },
  ESTAMPREGENERATED: {
    ID: 22,
    LABEL: 'Estamp Regenrated'
  }
}

export const PAYMENTDETAIL = [
  // {
  //   title: 'Stamp duty amount',
  //   amountTitle: 'stampDuty'
  // },
  {
    title: 'Platform charges',
    amountTitle: 'platformCharge'
  },
  {
    title: 'Dispatch charges',
    amountTitle: 'dispatchCharge'
  },
  // {
  //   title: 'eSign charges (esignnumber Signatures Required)',
  //   amountTitle: 'eSignCharge'
  // },
  {
    title: 'Stationary & Printing charges',
    amountTitle: 'printingCharge'
  }
  // {
  //   title: `GST (${gstCharge}%)`,
  //   amountTitle: 'gst'
  // }
]

export const FILE_STATUS = {
  VERIFIED: {
    id: 0,
    name: 'VERIFIED'
  },
  REJECTED: {
    id: 1,
    name: 'REJECTED'
  },
  PENDING: {
    id: 2,
    name: 'PENDING'
  },
  REVISIONSSUBMITTED: {
    id: 3,
    name: 'Revision submitted'
  }
}

export const SIGNATUREMODES = {
  AADHAAR: {
    id: 2,
    title: 'Esign aadhaar'
  },
  DSC: {
    id: 1,
    title: 'Digital Signature'
  }
}

export const CORPBULKPARTY = {
  FIRSTPARTY: {
    id: 1,
    title: 'First Party'
  },
  SECONDPARTY: {
    id: 2,
    title: 'Second Party'
  }
}

export const TEMPLATETYPE = {
  SYSTEMGENERATED: {
    label: 'System Generated',
    value: 'systemGenerated',
    id: 1
  },
  CUSTOMUPLOAD: {
    label: 'Custom Document Upload',
    value: 'customUpload',
    id: 2
  }
}

export const PARTYCOUNT = {
  SINGLEPARTY: {
    id: 1,
    label: 'Single Party'
  },
  MULTIPLEPARTY: {
    id: 2,
    label: 'Multiple Party'
  }
}

export const STAMPDUTYCALCULATIONOPTIONS = {
  CONSIDERATIONPRICE: {
    id: 1,
    name: 'Percentage of Consideration'
  },
  MINMAXSTAMPDUTY: {
    id: 2,
    name: 'Min Max stamp duty'
  }
}

// export const STORAGETYPEOPTIONS = {
//     FREETIER: {
//         id: 1,
//         label: 'Free tier(60 days)'
//     },
//     PAIDTIER: {
//         id: 2,
//         label: 'Premium tier(7 years)'
//     }
// };
export const STORAGETYPEOPTIONS = {
  FREETIER: {
    id: 1,
    label: 'Basic (60 Days)'
  },
  PAIDTIER: {
    id: 2,
    label: 'Premium (5 Years)'
  }
}

export const PARTYTYPEOPTIONS = {
  SINGLEPARTY: {
    id: 1,
    name: 'Single Party'
  },
  MULTIPLEPARTY: {
    id: 2,
    name: 'Multiple Party'
  }
}

export const MISCCHARGECONFIGTYPE = {
  PERCENTAGE: {
    id: 'PERCENTAGE'
  },
  STATICAMOUNT: {
    id: 'STATIC_AMOUNT'
  }
}
