/* eslint-disable */
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib';
import { ToWords } from 'to-words';
import moment from 'moment';
import { STAMPPAPER, STAMPPAPERIMAGELINKS } from '../../../constants/clStampPaperConstant';
import { getBelowHeadingTitle } from '../../stampPaperHelper';
import { hexToRgb } from '@mui/material';
/**
 *
 * @param {blob,string} pdfRes: blob in case of system generated
 * @param {string} path: which template function is  executed
 * @param {object} clResponse: response of the stamp paper
 * @param {object} extraFieldResponse: includes extraFields required for document [stateHeading,belowHeadingTitle]
 * @param {string} digitalSignName: only in case of bulk document
 * @returns
 */ export const generateGujaratBelowHundredStampPaper = async (
    pdfRes,
    path,
    clResponse,
    extraFieldResponse,
    digitalSignName = '',
    bulkSignPositionList = []
) => {
    const toWords = new ToWords({
        converterOptions: {
            doNotAddOnly: false
        }
    });
    // Register a Fontkit instance
    // PDFDocument.registerFontkit(Fontkit)

    let response;
    let pdfArrayBuffer;
    let existingPdfBytes;
    if (path === 'custom' || path === 'bulkDocument') {
        response = await fetch(pdfRes); // Replace with the actual PDF link
        pdfArrayBuffer = await response.arrayBuffer();
        existingPdfBytes = pdfArrayBuffer;
    } else {
        pdfArrayBuffer = await pdfRes.arrayBuffer();
        existingPdfBytes = pdfArrayBuffer;
    }

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // const imagePath = '/src/assets/MicrosoftTeams-image(1).png' // Adjust the path accordingly

    // Fetch the Emblem image as a Blob
    const emblemResponseImage = await fetch(STAMPPAPERIMAGELINKS?.EMBLEM);
    const emblemImageBytes = await emblemResponseImage.arrayBuffer();
    const emblemPngImage = await pdfDoc.embedPng(emblemImageBytes);

    // Fetch the right border image as a Blob
    const rightBorderResponseImage = await fetch(STAMPPAPERIMAGELINKS?.RIGHTBORDER);
    const rightBorderImageBytes = await rightBorderResponseImage.arrayBuffer();
    const rightBorderpngImage = await pdfDoc.embedPng(rightBorderImageBytes);

    // Fetch the left border image as a Blob
    const leftBorderResponseImage = await fetch(STAMPPAPERIMAGELINKS?.LEFTBORDER);
    const leftBorderImageBytes = await leftBorderResponseImage.arrayBuffer();
    const leftBorderpngImage = await pdfDoc.embedPng(leftBorderImageBytes);

    // Fetch the center emblem image as a Blob
    const centerEmblemResponseImage = await fetch(STAMPPAPERIMAGELINKS?.CENTEREMBLEM);
    const centerEmblemImageBytes = await centerEmblemResponseImage.arrayBuffer();
    const centerEmblempngImage = await pdfDoc.embedPng(centerEmblemImageBytes);

    // Fetch the stockholding image for all other pages
    const stockholdingImage = await fetch(STAMPPAPERIMAGELINKS?.STOCKHOLDINGLOGO);
    const stockholdingImageBytes = await stockholdingImage.arrayBuffer();
    const stockholdingpngImage = await pdfDoc.embedJpg(stockholdingImageBytes);

    // Fonts
    const timesRomanFontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    // const helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
    // const notoFont = await pdfDoc.embedFont('./NotoSans.ttf')

    // insert a new page
    pdfDoc.insertPage(0);

    // Get the first page of the document
    const pages = pdfDoc.getPages();

    // New blank page that is added
    const firstPage = pages[0];

    // Page width and height
    const { width, height } = firstPage.getSize();

    // Embed Center Emblem Image
    const centerEmblempngImagedims = centerEmblempngImage.scale(0.2);
    firstPage.drawImage(centerEmblempngImage, {
        x: width / 2 - 190,
        y: height / 2 - 120,
        width: centerEmblempngImagedims.width,
        height: centerEmblempngImagedims.height
    });

    // Embed Emblem Image
    const emblemPngImageDims = emblemPngImage.scale(0.05);
    firstPage.drawImage(emblemPngImage, {
        x: width / 2 - 50,
        y: height - 180,
        width: emblemPngImageDims.width,
        height: emblemPngImageDims.height
    });

    // Embed right border image
    const bothBorderpngImageDims = rightBorderpngImage.scale(0.8);
    firstPage.drawImage(rightBorderpngImage, {
        x: width - 70,
        y: height - 850,
        width: bothBorderpngImageDims.width,
        height
    });

    // Embed left border image
    firstPage.drawImage(leftBorderpngImage, {
        x: 10,
        y: height - 850,
        width: bothBorderpngImageDims.width,
        height
    });

    // Add header and footer in all the pages od the document
    for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        const { height } = page.getSize();

        // Fetch the stockholding image for all other pages
        const stockholdingImage = await fetch(STAMPPAPERIMAGELINKS?.STOCKHOLDINGLOGO);
        const stockholdingImageBytes = await stockholdingImage.arrayBuffer();
        const stockholdingpngImage = await pdfDoc.embedJpg(stockholdingImageBytes);

        // Add header
        page.drawText(
            `Stamp Duty Paid Rs.${clResponse?.stampDutyAmount} vide e-Stamp Certificate Id:${clResponse?.certificateNo} StockHolding DigiDoc URN:${clResponse?.urn}`,
            {
                x: 60,
                y: height - 20,
                size: 8,
                font: timesRomanFont
            }
        );

        // Adding page number to all pages
        page.drawText(`Page ${i + 1} of ${pages.length}`, {
            x: 550,
            y: 20,
            size: 8,
            font: timesRomanFont
        });
        // Add footer

        // page.drawText(clResponse?.certificateNo, {
        //   x: width / 2,
        //   y: 10,
        //   size: 10,
        //   font: timesRomanFont
        // })
    }

    // Top header title 'INDIA NON JUDICIAL'
    firstPage.drawText(STAMPPAPER?.HEADERTITILE, {
        x: width / 2 - 80,
        y: height - 40,
        size: 12,
        font: timesRomanFontBold
    });

    firstPage.drawText('Government of Gujarat', {
        x: width / 2 - 100,
        y: height - 70,
        size: 19,
        font: timesRomanFontBold
    });

    firstPage.drawText('Certificate of Stamp Duty', {
        x: width / 2 - 105,
        y: height - 200,
        size: 17,
        font: timesRomanFontBold
    });

    const extraFieldResponseChanges = {
        stateHeading: 'Certificate of Stamp Duty',
        belowHeadingTitle: extraFieldResponse?.belowHeadingTitle
    };

    console.log('extrafield reposne', extraFieldResponseChanges, extraFieldResponse);
    // Check that how much time we just want to repeat small titile heading
    // No of loop depend on the heading text width and small text width
    // Add the initialSmallsubheading till  the remainingWidth is greater than the
    const stateSmallHeading = await getBelowHeadingTitle(
        extraFieldResponseChanges,
        timesRomanFontBold,
        17
    );
    // Small line under the title
    firstPage.drawText(stateSmallHeading, {
        x: width / 2 - 105,
        y: height - 204,
        size: 2,
        font: timesRomanFontBold
    });

    // Sample certificate line
    // firstPage.drawText(STAMPPAPER?.SAMPLECERTIFICATELINE, {
    //   x: 40,
    //   y: 40,
    //   size: 50,
    //   font: timesRomanFont,
    //   color: rgb(0.2, 0.2, 0.2),
    //   rotate: degrees(55),
    //   opacity: 0.2
    // })

    // // Draw text in middle 'e-Stamp'
    // firstPage.drawText(STAMPPAPER?.ESTAMP, {
    //   x: width / 2,
    //   y: height - 166,
    //   size: 10,
    //   font: timesRomanFontBold
    // })

    // // Draw line for text 'e-Stamp'
    // firstPage.drawLine({
    //   start: { x: 20, y: height - 165 },
    //   end: { x: width - 20, y: height - 165 },
    //   thickness: 10,
    //   color: rgb(0.8, 0.8, 0.8),
    //   opacity: 0.2
    // })

    // // Shcil line repeat text
    // let schilX = 20
    // for (let i = 0; i <= 36; i++) {
    //   firstPage.drawText(STAMPPAPER?.SHCIL, {
    //     x: schilX,
    //     y: height - 156,
    //     size: 4,
    //     color: rgb(0.8, 0.8, 0.8),
    //     font: timesRomanFont
    //   })
    //   schilX += 14
    // }

    // // Draw line for Shcil text
    // firstPage.drawLine({
    //   start: { x: 20, y: height - 155 },
    //   end: { x: width - 20, y: height - 155 },
    //   thickness: 5,
    //   color: rgb(0.8, 0.8, 0.8),
    //   opacity: 0.2
    // })

    firstPage.drawText(`${STAMPPAPER?.RUPPEESYMBOL}.${clResponse?.stampDutyAmount}`, {
        x: width - 100,
        y: height - 100,
        size: 10,
        color: rgb(0.4, 0.4, 0.4),
        font: timesRomanFont
    });

    // Rs 20 image
    // Create a new graphics object
    // Set the line width and color
    // firstPage.drawLine({
    //   start: { x: width - 200, y: height - 200 },
    //   end: { x: width, y: height },
    //   borderWidth: 1,
    //   borderColor: rgb(0, 0, 0),
    // });

    // Define the triangle vertices
    // const triangleWidth = 50;
    // const triangleHeight = 30;
    // const triangleX = (width - triangleWidth) / 2;
    // const triangleY = height - 50 - triangleHeight;
    // const vertices = [
    //   { triangleX, triangleY },
    //   { x: triangleX + triangleWidth / 2, y: y - triangleHeight },
    //   { x: triangleX + triangleWidth, y },
    // ];

    firstPage.drawRectangle({
        x: width - 180,
        y: height - 180,
        width: 100,
        height: 75,
        borderWidth: 1,
        borderColor: rgb(229 / 255, 228 / 255, 226 / 255),
        borderStyle: 'solid',
        fillColor: rgb(0.3, 0.3, 0.3)
    });

    // triangles inside the rectangle according to height and width of rectangle
    const rectX = width - 180;
    const rectY = height - 180;
    const rectWidth = 100;
    const rectHeight = 75;

    // Define the size and spacing of the triangles
    const triangleWidth = 6;
    const triangleHeight = 6;
    const triangleSpacing = 5;

    // Calculate the number of triangles that can fit horizontally and vertically
    const numHorizontalTriangles = Math.floor(rectWidth / (triangleWidth + triangleSpacing));
    const numVerticalTriangles = Math.floor(rectHeight / (triangleHeight + triangleSpacing));

    // Calculate the horizontal and vertical spacing between triangles
    const horizontalSpacing =
        (rectWidth - numHorizontalTriangles * triangleWidth) / (numHorizontalTriangles + 1);
    const verticalSpacing =
        (rectHeight - numVerticalTriangles * triangleHeight) / (numVerticalTriangles + 1);

    // Draw the triangles inside the rectangle
    for (let i = 0; i < numVerticalTriangles; i++) {
        for (let j = 0; j < numHorizontalTriangles; j++) {
            const triangleX = rectX + horizontalSpacing + j * (triangleWidth + horizontalSpacing);
            const triangleY = rectY + verticalSpacing + i * (triangleHeight + verticalSpacing);

            // Draw the lines of the upside-down triangle
            firstPage.drawLine({
                start: { x: triangleX, y: triangleY + triangleHeight }, // Bottom-left corner
                end: { x: triangleX + triangleWidth / 2, y: triangleY }, // Top-center
                thickness: 1,
                color: rgb(229 / 255, 228 / 255, 226 / 255)
            });

            firstPage.drawLine({
                start: { x: triangleX + triangleWidth / 2, y: triangleY }, // Top-center
                end: { x: triangleX + triangleWidth, y: triangleY + triangleHeight }, // Bottom-right corner
                thickness: 1,
                color: rgb(229 / 255, 228 / 255, 226 / 255)
            });

            firstPage.drawLine({
                start: { x: triangleX + triangleWidth, y: triangleY + triangleHeight }, // Bottom-right corner
                end: { x: triangleX, y: triangleY + triangleHeight }, // Bottom-left corner
                thickness: 1,
                color: rgb(229 / 255, 228 / 255, 226 / 255)
            });
        }
    }

    // Draw Rs text inside the rectangle
    const textX = rectX + 40; // Adjust the x-coordinate as needed
    const textY = rectY + 60; // Adjust the y-coordinate as needed
    firstPage.drawText('Rs.', {
        x: textX,
        y: textY,
        size: 17, // Adjust the font size as needed
        color: rgb(0, 0, 0),
        font: timesRomanFontBold // Assuming you have a font object defined
    });

    // Draw the dynamic value inside the rectangle
    const dynamicValueX = textX - 10; // Adjust the x-coordinate as needed
    const dynamicValueY = textY - 50; // Adjust the y-coordinate as needed
    const dynamicValue = clResponse?.stampDutyAmount; // Replace this with your dynamic value
    firstPage.drawText(dynamicValue, {
        x: dynamicValueX,
        y: dynamicValueY,
        size: 52, // Adjust the font size as needed
        color: rgb(0, 0, 0),
        font: timesRomanFontBold // Assuming you have a font object defined
    });

    // Draw the triangle
    // firstPage.drawLine(vertices);
    // Below Last Line
    // firstPage.drawText('..........................................................', {
    //   x: 20,
    //   y: height - 583,
    //   size: 10,
    //   font: timesRomanFontBold
    // })

    // // Draw text in middle
    // firstPage.drawText(STAMPPAPER?.BELOWLINE, {
    //   x: width / 2 - 100,
    //   y: height - 583,
    //   size: 10,
    //   font: timesRomanFont
    // })

    // firstPage.drawText(
    //   '...................................................................................',
    //   {
    //     x: width - 200,
    //     y: height - 583,
    //     size: 10,
    //     font: timesRomanFontBold
    //   }
    // )

    // // Draw line for 'Please write below this line' text
    // firstPage.drawLine({
    //   start: { x: width / 2 - 130, y: height - 580 },
    //   end: { x: width - 200, y: height - 580 },
    //   thickness: 10,
    //   color: rgb(0.8, 0.8, 0.8),
    //   opacity: 0.2
    // })

    // Certificate no in last right with increase in Font-size
    // const cerNo = clResponse?.certificateNo
    // const cerNoLength = cerNo.length
    // const changeCount = 6
    // const loopcount = Math.trunc(cerNoLength / changeCount)
    // let font = 10
    // let start = 0
    // let end = 7
    // let certx = 200
    // for (let i = 0; i < loopcount; i++) {
    //   const string = cerNo.slice(start, end)
    //   firstPage.drawText(`${string}`, {
    //     x: width - certx,
    //     y: height - 580,
    //     size: font,
    //     font: timesRomanFont,
    //     color: rgb(0.4, 0.4, 0.4)
    //   })
    //   const nextTotal = end + 6
    //   start = end
    //   if (cerNoLength - nextTotal < 6) {
    //     //  console.log(end)
    //     end = cerNoLength
    //   } else {
    //     end += 6
    //   }
    //   font += 1
    //   certx -= 36 - i
    // }

    // Statutory Alert section
    firstPage.drawText(STAMPPAPER?.STATUARYALERT, {
        x: 80,
        y: 73, // 75, // 65
        size: 10,
        font: timesRomanFont
    });
    firstPage.drawText(STAMPPAPER?.CLAUSE1, {
        x: 80,
        y: 63, // 65, // 55,
        size: 8,
        font: timesRomanFont
    });
    firstPage.drawText(STAMPPAPER?.CLAUSEPART1, {
        x: 80,
        y: 56, // 58, // 48,
        size: 8,
        font: timesRomanFont
    });

    firstPage.drawText(STAMPPAPER?.CLAUSE2, {
        x: 80,
        y: 46, // 48, // 38,
        size: 8,
        font: timesRomanFont
    });

    firstPage.drawText(STAMPPAPER?.CLAUSE3, {
        x: 80,
        y: 36, // 40 // 30,
        size: 8,
        font: timesRomanFont
    });

    firstPage.drawText(STAMPPAPER?.CLAUSE4, {
        x: 80,
        y: 26, // 32 // 22,
        size: 8,
        font: timesRomanFont
    });
    firstPage.drawText(STAMPPAPER?.CLAUSEPART4, {
        x: 80,
        y: 19, // 25 // 15,
        size: 8,
        font: timesRomanFont
    });

    firstPage.drawText(STAMPPAPER?.CLAUSE5, {
        x: 80,
        y: 9, // 18 // 8,
        size: 8,
        font: timesRomanFont
    });

    firstPage.drawText(STAMPPAPER?.CLAUSEPART5, {
        x: 80,
        y: 3, // 11 // 1,
        size: 8,
        font: timesRomanFont
    });

    // Add vertical date time line
    for (let i = 0; i < 10; i++) {
        firstPage.drawText(clResponse?.certificateIssuedDate, {
            x: 45,
            y: 20 + i * 80,
            size: 8,
            font: timesRomanFont,
            color: rgb(0.4, 0.4, 0.4),
            rotate: degrees(90)
        });
    }

    // Add vertical right side firstpartyname line
    const firstName = clResponse?.firstParty;
    const len = firstName.length;
    console.log(len);
    const loopLimit = Math.ceil((20 / len) * 7);
    console.log(loopLimit);
    for (let j = 0; j < loopLimit; j++) {
        firstPage.drawText(firstName, {
            x: width - 40,
            y: 40 + j * (5 * len),
            size: 10,
            font: timesRomanFont,
            color: rgb(0.4, 0.4, 0.4),
            rotate: degrees(90)
        });
    }

    // Add Vertical stampduty
    for (let i = 0; i < 4; i++) {
        firstPage.drawText(`${STAMPPAPER?.RUPPEESYMBOL}.${clResponse?.stampDutyAmount}`, {
            x: width - 60,
            y: 40 + 680 - i * 40,
            size: 10,
            font: timesRomanFont,
            color: rgb(0.4, 0.4, 0.4),
            rotate: degrees(-90)
        });
    }

    // Add all the cl response object
    const clResponseObject = [
        {
            label: 'Certificate No',
            size: 14,
            value: clResponse?.certificateNo,
            font: timesRomanFontBold
        },
        {
            label: 'Certificate Issued Date',
            size: 10,
            value: clResponse?.certificateIssuedDate,
            font: timesRomanFont
        },
        {
            label: 'Account Reference',
            size: 10,
            value: clResponse?.accountReference,
            font: timesRomanFont
        },
        {
            label: 'Unique Doc. Reference',
            size: 10,
            value: `${clResponse?.uniqueDocReference}/`,
            font: timesRomanFont,
            extraField: true,
            extraFieldValue: clResponse?.urn
        },
        {
            label: 'Purchased by',
            size: 10,
            value: clResponse?.purchaseBy,
            font: timesRomanFont
        },
        {
            label: 'Description of Document',
            size: 10,
            value: clResponse?.descriptionOfDocument,
            font: timesRomanFont
        },
        {
            label: 'Decription',
            size: 10,
            value: clResponse?.propertyDescription,
            font: timesRomanFont
        },
        {
            label: 'Consideration Price (Rs)',
            size: 10,
            value: clResponse?.considerationPrice,
            font: timesRomanFont,
            currencyWord: true,
            currencyPrice: toWords.convert(
                Number(clResponse?.considerationPrice?.replaceAll(',', '')),
                { currency: true }
            )
        },
        {
            label: 'First Party',
            size: 10,
            value: clResponse?.firstParty,
            font: timesRomanFont
        },
        {
            label: 'Second Party',
            size: 10,
            value: clResponse?.secondParty,
            font: timesRomanFont
        },
        {
            label: 'Stamp Duty Paid By',
            size: 10,
            value: clResponse?.stampDutyPaidBy,
            font: timesRomanFont
        },
        {
            label: 'Stamp Duty Amount(Rs.)',
            size: 10,
            value: clResponse?.stampDutyAmount,
            font: timesRomanFont,
            currencyWord: true,
            currencyPrice: toWords.convert(
                Number(clResponse?.stampDutyAmount?.replaceAll(',', '')),
                { currency: true }
            )
        }
    ];

    const x = 80;
    let y = height - 250;
    for (const item of clResponseObject) {
        // Draw label
        firstPage.drawText(`${item?.label}  `, {
            x,
            y,
            size: item?.size,
            font: item?.font
        });

        // Draw value
        firstPage.drawText(`: ${item.value}`, {
            x: x + 160,
            y,
            size: item?.size,
            font: item?.font
        });

        // Draw downwards currency word
        if (item?.currencyWord) {
            console.log('in if');
            firstPage.drawText(`(${item?.currencyPrice})`, {
                x: x + 170,
                y: y - 8,
                size: 10,
                font: item?.font
            });
        }

        // Draw ectra text just below the value (used for urn no)
        if (item?.extraField) {
            firstPage.drawText(`${item?.extraFieldValue}`, {
                x: x + 165,
                y: y - 8,
                size: 10,
                font: item?.font
            });
        }
        // Increment the Y-coordinate for the next line
        y -= 20; // Adjust the vertical spacing as needed
    }

    firstPage.drawText(`Rs.${clResponse?.stampDutyAmount}`, {
        x: 80,
        y: height - 540,
        size: 10,
        font: timesRomanFont,
        rotate: degrees(90)
    });

    // Only in case of bulk document sign all the document
    if (path === 'bulkDocument') {
        for (let i = 0; i < bulkSignPositionList?.length; i++) {
            const page = pages[bulkSignPositionList[i]?.page];
            // const { width, height } = page.getSize()

            // Add header
            page.drawText('Digitally Signed By:-', {
                x: bulkSignPositionList[i]?.posx,
                y: bulkSignPositionList[i]?.posy,
                size: 7,
                font: timesRomanFontBold
            });
            page.drawText(`Name: ${digitalSignName}`, {
                x: bulkSignPositionList[i]?.posx,
                y: bulkSignPositionList[i]?.posy - 8,
                size: 7,
                font: timesRomanFontBold
            });
            page.drawText('Location: Testing Location', {
                x: bulkSignPositionList[i]?.posx,
                y: bulkSignPositionList[i]?.posy - 8 * 2,
                size: 7,
                font: timesRomanFontBold
            });
            page.drawText('Reason: Digidoc Signature', {
                x: bulkSignPositionList[i]?.posx,
                y: bulkSignPositionList[i]?.posy - 8 * 3,
                size: 7,
                font: timesRomanFontBold
            });

            const date = moment();

            // Format the date using the desired pattern
            const formattedDate = date.format('ddd MMM DD HH:mm:ss [IST] YYYY');

            page.drawText(`Date: ${formattedDate}`, {
                x: bulkSignPositionList[i]?.posx,
                y: bulkSignPositionList[i]?.posy - 8 * 4,
                size: 7,
                font: timesRomanFontBold
            });
            // x += 120
        }
    }
    const pdfDOc23 = await pdfDoc.save();
    const pdfBlob = await new Blob([pdfDOc23], { type: 'application/pdf' });
    if (process.env.REACT_APP_ENV_VAR === 'dev') {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = 'generated.pdf';
        link.click();
    }
    return pdfBlob;
};
