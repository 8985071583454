import { Box, Grid, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
const MyDocumentCorporate = ({ documentStatusArray }) => {
  const navigate = useNavigate()
  const location = useLocation()

  // Selected button state
  const [selected, setselected] = useState('')

  // Handle select individual button
  const handleIndividualTransaction = () => {
    navigate('/myDocument', {
      state: {
        HeaderTitle: 'Individual Transaction'
      }
    })
  }
  // Handle select bulk button
  const handleBulkTransaction = () => {
    navigate('/myDocument/bulkTransaction', {
      state: {
        HeaderTitle: 'Bulk Transaction'
      }
    })
  }
  // Handle select review and consent button
  const handleReviewConsentTransaction = () => {
    navigate('/myDocument/reviewAndConsent', {
      state: {
        HeaderTitle: 'Review Consent'
      }
    })
  }

  // Handle select multiple copies document button
  const handleMultipleCopiesTransaction = () => {
    navigate('/myDocument/multipleCopies', {
      state: {
        HeaderTitle: 'Multiple Copies Documents'
      }
    })
  }

  // Set selected button on location url change
  useEffect(() => {
    if (location?.pathname) {
      const text = location?.pathname.split('/')
      setselected(text[text.length - 1])
    }
  }, [location?.pathname])

  return (
        <Box sx={{ p: 2 }}>
            <Grid spacing={2} container mb={3} justifyContent="center">
                <Grid item lg={12} md={12} xs={12} sm={12}>
                    <Button
                        variant={selected === 'myDocument' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '5px 0px 0px 5px' }}
                        onClick={handleIndividualTransaction}>
                        My Documents
                    </Button>
                    <Button
                        variant={selected === 'reviewAndConsent' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '0px 0px 0px 0px' }}
                        onClick={handleReviewConsentTransaction}>
                        Review and Consent Required
                    </Button>
                    <Button
                        variant={selected === 'bulkTransaction' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '0px 5px 5px 0px' }}
                        onClick={handleBulkTransaction}>
                        Bulk Transaction
                    </Button>
                    <Button
                        variant={selected === 'multipleCopies' ? 'tabSelected' : 'tabUnselected'}
                        sx={{ borderRadius: '0px 5px 5px 0px' }}
                        onClick={handleMultipleCopiesTransaction}>
                        Multiple Copies Documents
                    </Button>
                </Grid>
            </Grid>
            <Outlet context={{ documentStatusArray }} />
        </Box>
  )
}

export default MyDocumentCorporate

MyDocumentCorporate.propTypes = {
  documentStatusArray: PropTypes.array
}
