import { Box, IconButton, InputAdornment, Popover, TextField } from '@mui/material'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import calendericon from '../../assets/images/calendericon.svg'
import './index.css'
import { React, useState } from 'react'
import PropTypes from 'prop-types'

const DatePickerComponent = ({ placeholder, date, setDate, maxDate, disabled, label }) => {
  // for popover
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const isValidDate = (date) => date instanceof Date && !isNaN(date)

  return (
        <>
            <Box sx={{ width: '100%' }}>
                <TextField
                    name="date-range"
                    id="date-range"
                    required
                    label={label}
                    placeholder={placeholder || ''}
                    value={isValidDate(new Date(date)) ? new Date(date).toLocaleDateString() : ''}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      endAdornment: (
                            <InputAdornment position="start">
                                <IconButton aria-label="toggle password visibility">
                                    <img src={calendericon} alt="Date Range" />
                                </IconButton>
                            </InputAdornment>
                      )
                    }}
                    fullWidth
                    onClick={!disabled ? handleClick : undefined}
                    disabled={disabled || false}
                />
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    classes={{ root: 'calanderPopover' }}>
                    <Box>
                        <Calendar
                            showDateDisplay
                            maxDate={isValidDate(new Date(maxDate)) ? new Date(maxDate) : undefined}
                            onChange={(date) => {
                              console.log('ON DATE CHANGE===>', date)
                              setDate(date)
                              handleClose()
                            }}
                            date={isValidDate(new Date(date)) ? new Date(date) : new Date()}
                        />
                    </Box>
                </Popover>
            </Box>
        </>
  )
}

export default DatePickerComponent

DatePickerComponent.propTypes = {
  placeholder: PropTypes.string,
  date: PropTypes.string,
  setDate: PropTypes.func,
  maxDate: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string
}
