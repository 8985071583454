import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Root from '../../../StyledComponents/DividerStyles/DividerStyles'
import flag from '../../../assets/images/india-flag.svg'
import { CLVALIDATIONS, PATTERN } from '../../../constants/enum'
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles'
import {
  useGetCityQuery,
  useLazyResendOtpQuery,
  useLazyVerifyOtpQuery
} from '../../../store/services/userServices'
import edit from '../../../assets/images/editIcon.svg'
import BankAccountModal from '../../../components/BankAccountModal/BankAccountModal'
import { toast } from 'react-toastify'
import {
  useEditbankAccountDetailsMutation,
  useGetProfileQuery,
  useUpdateProfileMutation
} from '../../../store/services/profleServices'
import OtpModal from '../../../components/OtpModal/OtpModal'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import { BUSINESSCONFIGURATION } from '../../../constants/configurationConstant'
import info from '../../../assets/images/info.svg'
import { INFORMATION_MESSAGE } from '../../../constants/message'
import { PLACEHOLDER } from '../../../constants/placeholder'
import palette from '../../../theme/palette'

const EditProfileBusiness = () => {
  const navigate = useNavigate()

  // Open otp modal if user enters phone number
  const [openOtpModal, setOpenOtpModal] = useState(false)

  // Bank account modal state
  const [openBankModal, setOpenBankModal] = useState(false)

  // otp child counter state
  const [counter, setCounter] = useState(45)

  const [code, setCode] = useState('')

  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // Bank account Modal labels, buttons
  const bankAccountModalState = {
    label: 'Add Bank Account',
    button: 'Save',
    type: 'change'
  }

  // Add Bank account Form state
  const [addbankAccountForm, setAddbankAccountForm] = useState({
    accountType: '',
    errors: {
      ifscCode: 'error',
      accountNumber: 'error',
      confirmAccountNumber: 'error'
    }
  })

  // Update profile form state
  const [updateProfileForm, setUpdateProfileForm] = useState({
    accountType: '',
    errors: {
      ifscCode: 'noerror'
    }
  })

  // Update profile mutation
  const [profileUpdate] = useUpdateProfileMutation({})

  // Edit Bank account mutation
  const [editbankAccountDetails] = useEditbankAccountDetailsMutation({})

  // Get all cities according to state id query
  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: updateProfileForm.stateId },
    {
      skip: !updateProfileForm.stateId
    }
  )

  // Profile data query
  const { data: profileData = {}, isSuccess } = useGetProfileQuery({})

  // Profile restrictions for business staff
  const profileRestrictionConfiguration = BUSINESSCONFIGURATION[`${profileData?.subRole}`]

  // state for loader
  const [backdropState, setBackdropState] = useState(false)

  // Resend Otp on phone number change lazyQuery
  const [resendOtp] = useLazyResendOtpQuery()

  // Verify otp lazyQuery
  const [verifyOtp] = useLazyVerifyOtpQuery()

  // Set Profile data in updateProfileForm on profileData success
  useEffect(() => {
    if (profileData && isSuccess) {
      setUpdateProfileForm((prev) => ({
        ...prev,
        name: profileData?.name,
        email: profileData?.email,
        phone: profileData?.phone,
        address: profileData?.address,
        state: profileData?.state?.name,
        stateId: profileData?.state?.id,
        cityId: profileData?.city?.id,
        pinCode: profileData?.pinCode,
        bankAccountNumber: profileData?.bankAccount
      }))
    }
  }, [profileData, isSuccess])

  /**
     * Handle update profile form change
     * @param {*} event : Event object
     */
  const handleUpdateProfile = (event) => {
    setUpdateProfileForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.replace(/\s/gy, '')
    }))
  }

  // Update Profile
  const handleUpdateProfileForm = () => {
    const dataToSend = {
      name: updateProfileForm?.name,
      phone: updateProfileForm?.phone,
      address: updateProfileForm?.address,
      stateId: updateProfileForm?.stateId,
      cityId: updateProfileForm?.cityId,
      pinCode: Number(updateProfileForm?.pinCode)
    }
    setBackdropState(true)
    profileUpdate({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        navigate('/myProfileBusiness', {
          state: {
            HeaderTitle: 'My Profile'
          }
        })
        toast.success(response?.message)
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('EXCEPTION:CHANGE PASSWORD', execption)
        // toast.error(execption?.message)
      })
  }

  // Handle Resend otp
  const handlePhoneNumberVerify = () => {
    setBackdropState(true)
    resendOtp(updateProfileForm?.phone)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
        setOpenOtpModal(true)
        setCode('')
        setCounter(45)
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('error')
      })
    console.log('phone changed', openOtpModal)
  }

  // handle Verify otp
  const handleVerifyOtp = () => {
    setBackdropState(true)
    verifyOtp(code)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response.message)
        setOpenOtpModal(false)
        handleUpdateProfileForm()
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('execption:OTP VERIFICATION')
      })
  }

  // Handle submit bank account modal
  const handleSubmitBankModal = () => {
    const dataToSend = {
      bankName: addbankAccountForm?.bankName,
      accountNumber: addbankAccountForm?.accountNumber,
      accountHolderName: addbankAccountForm?.accountHolderName,
      ifscCode: addbankAccountForm?.ifscCode,
      accountType: addbankAccountForm?.accountType
    }
    if (addbankAccountForm?.errors?.ifscCode === 'noerror') {
      setBackdropState(true)
      editbankAccountDetails({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          setBackdropState(false)
          // Reset form after update
          setAddbankAccountForm({
            accountType: '',
            errors: {
              ifscCode: 'error',
              accountNumber: 'error',
              confirmAccountNumber: 'error'
            }
          })
          toast.success(response?.message)
          setOpenBankModal(false)
        })
        .catch((execption) => {
          setBackdropState(false)
          console.log('exception:ACCOUNT ADD:FAIL', execption)
          // toast.error(execption.message)
        })
    } else {
      toast.error('Please fix all errors')
    }
    console.log(updateProfileForm)
  }

  /**
     * Handle update form submit
     * Check user phone number is changed or not accordingly perform actions  Actions:[Verify phone, update profile]
     */
  const handleUpdateFormSubmit = () => {
    console.log(updateProfileForm?.phone, profileData?.phone)
    if (updateProfileForm?.phone !== profileData?.phone) {
      handlePhoneNumberVerify()
    } else {
      handleUpdateProfileForm()
    }
  }

  //   for popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
        <Box p={3}>
            <form
                onSubmit={(event) => {
                  event.preventDefault()
                  handleUpdateFormSubmit()
                }}>
                <Grid container spacing={5} mb={3}>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px', display: 'flex' }}>
                            Name
                            <img
                                style={{ marginLeft: 10 }}
                                src={info}
                                alt="info"
                                onClick={handleClick}
                            />
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}>
                                <Box
                                    sx={{
                                      background: palette.primary.light,
                                      boxShadow: `0px 8px 64px rgba(${palette.sidebar.light}, 0.05), 0px 0px 1px rgba(${palette.sidebar.light}, 0.08)`
                                    }}
                                    className="documentTablePopoverBox">
                                    <Typography
                                        variant="darkValue"
                                        sx={{
                                          letterSpacing: '0.03em',
                                          lineHeight: '30px'
                                        }}>
                                        {INFORMATION_MESSAGE?.NAME_MESSAGE}
                                    </Typography>
                                </Box>
                            </Popover>
                        </Typography>
                        <TextField
                            value={updateProfileForm?.name || ''}
                            type="text"
                            required
                            id="name"
                            placeholder={PLACEHOLDER?.NAMEASPERAADHAR}
                            name="name"
                            disabled
                            fullWidth
                            onChange={(event) => {
                              if (
                                event.target.value === '' ||
                                    PATTERN?.ALPHABETSONLY.test(event?.target?.value)
                              ) {
                                handleUpdateProfile(event)
                              }
                            }}
                        />
                    </Grid>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Email address
                        </Typography>
                        <TextField
                            value={updateProfileForm?.email || ''}
                            required
                            type="email"
                            id="email"
                            name="email"
                            fullWidth
                            disabled
                            onChange={handleUpdateProfile}
                        />
                    </Grid>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Phone Number
                        </Typography>
                        <Typography variant="modalLabel">
                            <TextField
                                required={profileData?.phone}
                                value={updateProfileForm?.phone || ''}
                                type="number"
                                id="phoneNumber"
                                name="phone"
                                inputProps={{
                                  type: 'text',
                                  // inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                  maxlength: 10,
                                  minlength: 10
                                }}
                                InputProps={{
                                  startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={flag} />
                                            &nbsp;+91 |
                                        </InputAdornment>
                                  )
                                }}
                                fullWidth
                                onChange={(e) => {
                                  if (
                                    e.target.value === '' ||
                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                  ) {
                                    handleUpdateProfile(e)
                                  }
                                }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Root>
                    <Divider sx={{ marginBottom: '30px' }} />
                </Root>
                <Grid container spacing={5} mb={3}>
                    <Grid item lg={12}>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Company address
                        </Typography>
                        <TextField
                            value={updateProfileForm?.address || ''}
                            required
                            type="text"
                            id="address"
                            name="address"
                            multiline
                            rows={1}
                            fullWidth
                            inputProps={{
                              type: 'text',
                              maxlength: CLVALIDATIONS?.COBAADDRESS?.MAXLENGTH,
                              minlength: CLVALIDATIONS?.COBAADDRESS?.MINLENGTH
                            }}
                            onChange={handleUpdateProfile}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={5} mb={3}>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            State
                        </Typography>
                        <TextField
                            value={updateProfileForm?.state || ''}
                            required
                            type="text"
                            id="state"
                            name="state"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            City
                        </Typography>
                        <Select
                            value={updateProfileForm?.cityId || ''}
                            name="cityId"
                            onChange={(event) => {
                              setUpdateProfileForm((prev) => ({
                                ...prev,
                                [event.target.name]: event.target.value
                              }))
                            }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ fontSize: '16px' }}
                            MenuProps={MenuProps}
                            fullWidth
                            disabled>
                            <MenuItem value="" disabled>
                                <Typography>Select City</Typography>
                            </MenuItem>

                            {cityArray &&
                                cityArray.map((item) => (
                                    <MenuItem
                                        sx={{ fontSize: '16px' }}
                                        key={item.id}
                                        value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Pin code
                        </Typography>
                        <TextField
                            value={updateProfileForm?.pinCode || ''}
                            required
                            type="text"
                            inputProps={{
                              type: 'text',
                              maxlength: 6,
                              minlength: 6
                            }}
                            disabled
                            id="pinCode"
                            name="pinCode"
                            fullWidth
                            onChange={(event) => {
                              if (
                                event.target.value === '' ||
                                    PATTERN?.NUMBERONLY?.test(event?.target?.value)
                              ) {
                                handleUpdateProfile(event)
                              }
                            }}
                        />
                    </Grid>
                </Grid>
                {profileRestrictionConfiguration?.profile?.isbankAccount && (
                    <Grid container spacing={5} mb={3}>
                        <Grid item lg={4}>
                            <Typography
                                variant="darkValue"
                                component="div"
                                sx={{ marginBottom: '8px' }}>
                                Bank account
                            </Typography>
                            {updateProfileForm?.bankAccountNumber ? (
                                <TextField
                                    value={updateProfileForm?.bankAccountNumber || ''}
                                    type="text"
                                    id="bankAccountNumber"
                                    name="bankAccountNumber"
                                    fullWidth
                                    // onChange={handleUpdateProfile}
                                    InputProps={{
                                      endAdornment: (
                                            <InputAdornment position="end">
                                                {updateProfileForm?.bankAccountNumber && (
                                                    <img
                                                        src={edit}
                                                        alt="edit"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => setOpenBankModal(true)}
                                                    />
                                                )}
                                            </InputAdornment>
                                      ),
                                      readOnly: true
                                    }}
                                />
                            ) : (
                                <Typography variant="darkValue" sx={{ fontSize: '16px' }}>
                                    No bank account added
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                )}
                <Grid>
                    <Button variant="primary" type="submit">
                        Update info
                    </Button>
                </Grid>
            </form>

            {openBankModal && (
                <BankAccountModal
                    bankAccountDetails={addbankAccountForm}
                    setBankAccountDetails={setAddbankAccountForm}
                    bankAccountModalState={bankAccountModalState}
                    openBankModal={openBankModal}
                    handleBankModalClose={() => setOpenBankModal(false)}
                    handleSubmitBankModal={handleSubmitBankModal}
                />
            )}

            {openOtpModal && (
                <OtpModal
                    code={code}
                    setCode={setCode}
                    counter={counter}
                    setCounter={setCounter}
                    phoneNumberUpdate={updateProfileForm?.phone}
                    handleVerifyOtp={handleVerifyOtp}
                    openOtpModal={openOtpModal}
                    handleOtpModalClose={() => setOpenOtpModal(false)}
                />
            )}
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default EditProfileBusiness
