/* eslint-disable */
import {
    Box,
    Button,
    Grid,
    Paper,
    Step,
    StepConnector,
    stepConnectorClasses,
    StepContent,
    StepLabel,
    Stepper,
    styled,
    Typography
} from '@mui/material';
import React from 'react';
import documentstep from '../../../../assets/homePageDesignIcons/documentstep.svg';
import tabletstep from '../../../../assets/homePageDesignIcons/tabletstep.svg';
import partystep3 from '../../../../assets/homePageDesignIcons/partystep3.svg';
import documentcheckstep4 from '../../../../assets/homePageDesignIcons/documentcheckstep4.svg';
import documentvalidstep5 from '../../../../assets/homePageDesignIcons/documentvalidstep5.svg';

import step6 from '../../../../assets/homePageDesignIcons/step6.svg';
import step7 from '../../../../assets/homePageDesignIcons/step7.svg';
import step8 from '../../../../assets/homePageDesignIcons/step8.svg';
import mainsteps from '../../../../assets/homePageDesignIcons/mainsteps.svg';
import mainstepfull from '../../../../assets/homePageDesignIcons/mainstepfull.svg';
import { Link } from 'react-router-dom';

const HomePageDesign = () => {
    const steps = [
        {
            label: 'Digidoc platform facilitates the execution of documents in digital mode along with online payment of Stamp Duty.'
        },
        {
            label: "Users also have the option to download and use 'StockHolding Digidoc' mobile app."
        },
        {
            label: 'Parties to the documents must be competent to enter into contract as specified under The Indian Contract Act 1872 and should be above 18 years of age.'
        },
        {
            label: (
                <>
                    <Typography
                        variant="modalLabel"
                        sx={{
                            color: '#000000',
                            fontWeight: '500',
                            fontSize: '16px',
                            fontStyle: 'italic',
                            textAlign: 'justify'
                        }}>
                        The platform is to be used for legitimate purposes and users are required to
                        adhere to the agreed &nbsp;
                        <Link
                            className="text-link"
                            to={'/termsAndConditions/termsAndConditions'}
                            target="_blank"
                            rel="noopener noreferrer">
                            Terms & Conditions.
                        </Link>
                    </Typography>
                </>
            )
        },
        {
            label: 'Users are required to keep their user ids and passwords confidential and not to share OTP.'
        },
        {
            label: 'Users are advised to complete the transaction within 60 days of initiation after which the incomplete document / transaction will get deleted.'
        },
        {
            label: "Executed document are available under 'My Document' section for limited time period as mentioned in T&C, as best practice users can down load and safe-keep a copy of the same. Users can also avail facility to transfer documents to their Digilocker account."
        },
        {
            label: 'Executed documents can be viewed by opening the document in adobe acrobat reader and validating the e-Sign through the signature panel.'
        }
    ];

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: documentstep,
            2: tabletstep,
            3: partystep3,
            4: documentcheckstep4,
            5: documentvalidstep5,
            6: step6,
            7: step7,
            8: step8
        };

        return <>{<img src={icons[String(props.icon)]} />}</>;
    }

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`& .${stepConnectorClasses.line}`]: {
            minHeight: '16px',
            marginLeft: '6px',
            borderLeftStyle: 'solid',
            borderLeftWidth: '2px'

            // backgroundColor: '#eaeaf0',
            // borderRadius: 1,
            // ...theme.applyStyles('dark', {
            //   backgroundColor: theme.palette.grey[800],
            // }),
        }
    }));

    return (
        <Box
            sx={{
                background:
                    'linear-gradient(180deg, rgba(234, 234, 234, 0.1) -9.86%, rgba(0, 127, 97, 0.06) 100%)',
                marginTop: '20px'
            }}>
            <Button
                variant="primary"
                fullWidth
                sx={{ fontWeight: '700', fontSize: '30px', marginBottom: 4 }}>
                Welcome to StockHolding DigiDoc
            </Button>
            <Grid container spacing={4} pl={2}>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography
                        sx={{
                            fontWeight: '700',
                            fontSize: '22px',
                            color: '#003A51',
                            lineHeight: '27px',
                            fontStyle: 'italic'
                        }}>
                        General Guidelines for using StockHolding DigiDoc:
                    </Typography>
                    <Stepper orientation="vertical" connector={<ColorlibConnector />}>
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    StepIconComponent={ColorlibStepIcon}
                                    sx={{
                                        color: '#000000', // Custom color
                                        '& .MuiStepLabel-label': {
                                            color: '#000000',
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            fontStyle: 'italic',
                                            textAlign: 'justify'
                                        }
                                    }}>
                                    {step.label}
                                </StepLabel>
                                {/* <StepContent>
                                    <Typography>{step.description}</Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                    </Box>
                                </StepContent> */}
                            </Step>
                        ))}
                    </Stepper>
                </Grid>

                <Grid item xs={12} md={5} lg={5}>
                    <img src={mainstepfull} alt="HomeImage" style={{ maxWidth: '100%' }} />
                </Grid>
            </Grid>
        </Box>
        // <div>HomePageDesign</div>
    );
};

export default HomePageDesign;
