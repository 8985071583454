import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import BankAccountModal from '../../../components/BankAccountModal/BankAccountModal'
import PropTypes from 'prop-types'
import notVerifiedIcon from '../../../assets/images/notverifiedIcon.svg'
import emailIcon from '../../../assets/actions/actionEmail.svg'
import { useLazyResendEmailQuery } from '../../../store/services/userServices'
import Root from '../../../StyledComponents/DividerStyles/DividerStyles'
import { toast } from 'react-toastify'
import {
  useAddbankAccountDetailsMutation,
  useGetProfileQuery
} from '../../../store/services/profleServices'
import palette from '../../../theme/palette'
import { CORPORATECONFIGURATION } from '../../../constants/configurationConstant'
import InfoIcon from '@mui/icons-material/Info'
import { red } from '@mui/material/colors'
import NotesModal from '../../../components/NotesModal/NotesModal'
import { CORPORATE_FILES, PANVERIFICATIONSTATUS } from '../../../constants/enum'
import { FILE_STATUS } from '../../../constants/documentConstans'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import moment from 'moment'

function RejectionIcon ({ fileName, handleOpenRejectionModal }) {
  return (
        <Tooltip title="Rejection Notes" aria-label="Rejection Notes">
            <IconButton
                size="small"
                aria-label="Rejection Notes"
                sx={{ color: red[500] }}
                onClick={() => {
                  handleOpenRejectionModal(fileName)
                }}>
                <InfoIcon />
            </IconButton>
        </Tooltip>
  )
}

RejectionIcon.propTypes = {
  fileName: PropTypes.string,
  handleOpenRejectionModal: PropTypes.func
}

const MyProfileCorporate = () => {
  const navigate = useNavigate()

  // BankModal open close state
  const [openBankModal, setOpenBankModal] = useState(false)

  // Backdrop loader state
  const [backdropState, setbackdropState] = useState(false)

  // Rejection notes modal open close state
  const [rejectionNotesModal, setRejectionNotesModal] = useState({
    open: false,
    message: ''
  })

  // Bankmodal state
  const bankAccountModalState = {
    label: 'Add Bank Account',
    button: 'Add'
  }

  const { data: profileData = {} } = useGetProfileQuery({})

  // Profile restrictions for corporate admin and staff
  const profileRestrictionConfiguration = CORPORATECONFIGURATION[`${profileData?.subRole}`]

  // Resend email lazy query
  const [resendEmail] = useLazyResendEmailQuery()

  // Add account details mutation
  const [AddbankAccountDetails] = useAddbankAccountDetailsMutation({})

  // Add bank account form state
  const [bankAccountDetails, setBankAccountDetails] = useState({
    accountType: '',
    form: {},
    errors: {
      ifscCode: 'error',
      accountNumber: 'error',
      confirmAccountNumber: 'error'
    }
  })

  // Handle submit bank account modal
  const handleSubmitBankModal = () => {
    console.log('bankAccountDetails', bankAccountDetails)
    const dataToSend = {
      bankName: bankAccountDetails?.bankName,
      accountNumber: bankAccountDetails?.accountNumber,
      accountHolder: bankAccountDetails?.accountHolderName,
      ifscCode: bankAccountDetails?.ifscCode,
      accountType: bankAccountDetails?.accountType
    }
    if (bankAccountDetails?.errors?.ifscCode === 'noerror') {
      AddbankAccountDetails({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          toast.success(response?.message)
          setOpenBankModal(false)
          setBankAccountDetails({
            accountType: '',
            form: {},
            errors: {
              ifscCode: 'error',
              accountNumber: 'error',
              confirmAccountNumber: 'error'
            }
          })
        })
        .catch((execption) => {
          console.log('exception:ACCOUNT ADD:FAIL', execption)
          // toast.error(execption.message)
        })
    } else {
      toast.error('Please fix all errors')
    }
    // api integration for saving bank account details
  }

  // Handle edit button
  const handleEdit = () => {
    navigate('/myProfileCorporate/editProfile', {
      state: {
        HeaderTitle: 'Edit Profile'
      }
    })
  }

  // Stock broker options
  const optionsArray = [
    {
      label: 'Yes',
      value: true
    },
    {
      label: 'No',
      value: false
    }
  ]

  // Handle resend verification email
  const handleResendEmail = () => {
    setbackdropState(true)
    resendEmail(profileData?.email)
      .unwrap()
      .then((response) => {
        setbackdropState(false)
        toast.success(response?.message)
      })
      .catch((exception) => {
        setbackdropState(false)

        console.log('EXCEPTION:RESEND EMAIl', exception)
      })
  }

  const handleOpenRejectionModal = (fileName) => {
    // add message
    console.log(fileName, 'fileName')

    if (CORPORATE_FILES?.GST === fileName) {
      setRejectionNotesModal({
        title: 'Rejection Notes:',
        open: true,
        message: profileData?.companyDetails[`${fileName}`]?.comment
      })
    } else if (CORPORATE_FILES?.PAN === fileName) {
      setRejectionNotesModal({
        title: 'Rejection Notes:',
        open: true,
        message: profileData?.panCardDetails?.comment
      })
    } else {
      setRejectionNotesModal({
        title: 'Rejection Notes:',
        open: true,
        message: profileData?.nationalDetails[`${fileName}`]?.comment
      })
    }
  }
  // handle bank account modal close
  const handleBankAccountModalClose = () => {
    setBankAccountDetails({
      accountType: '',
      errors: {
        ifscCode: 'error',
        accountNumber: 'error',
        confirmAccountNumber: 'error'
      }
    })
    setOpenBankModal(false)
  }
  return (
        <Box p={3}>
            <Grid container mb={3}>
                <Grid item>
                    <Typography variant="modalLabelBold" sx={{ fontWeight: 600, fontSize: '18px' }}>
                        User details
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={3}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Name
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.name}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Grid container alignItems="center">
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px', marginRight: '10px' }}>
                            Email address
                        </Typography>
                        {!profileData?.isEmailVerified && (
                            <>
                                <img
                                    src={notVerifiedIcon}
                                    alt="notVerified"
                                    height="20px"
                                    title="Email not verified"
                                    style={{ marginRight: '10px', marginTop: '-10px' }}
                                />
                                <img
                                    src={emailIcon}
                                    alt="resendEmail"
                                    height="24px"
                                    title="Resend email"
                                    style={{ marginTop: '-10px', cursor: 'pointer' }}
                                    onClick={handleResendEmail}
                                />
                            </>
                        )}
                    </Grid>
                    <Typography variant="modalLabel">{profileData?.email}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Phone Number
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.phone ? '+91-' : ''}
                        {profileData?.phone}
                    </Typography>
                </Grid>
            </Grid>
            <Root>
                <Divider sx={{ marginBottom: '30px' }} />
            </Root>
            <Grid container mb={3}>
                <Grid item>
                    <Typography variant="modalLabelBold" sx={{ fontWeight: 600, fontSize: '18px' }}>
                        National level details of company
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} marginBottom={4}>
                <Grid item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Company name
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.nationalDetails?.companyName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={3}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        MOA
                        {profileData?.nationalDetails?.moa?.currentStatus?.id ===
                            FILE_STATUS?.REJECTED?.id && (
                            <RejectionIcon
                                fileName={CORPORATE_FILES?.MOA}
                                handleOpenRejectionModal={handleOpenRejectionModal}
                            />
                        )}
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.nationalDetails?.moa?.fileName}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        AOA
                        {profileData?.nationalDetails?.aoa?.currentStatus?.id ===
                            FILE_STATUS?.REJECTED?.id && (
                            <RejectionIcon
                                fileName={CORPORATE_FILES?.AOA}
                                handleOpenRejectionModal={handleOpenRejectionModal}
                            />
                        )}
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.nationalDetails?.aoa?.fileName}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        POA
                        {profileData?.nationalDetails?.poa?.currentStatus?.id ===
                            FILE_STATUS?.REJECTED?.id && (
                            <RejectionIcon
                                fileName={CORPORATE_FILES?.POA}
                                handleOpenRejectionModal={handleOpenRejectionModal}
                            />
                        )}
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.nationalDetails?.poa?.fileName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={3}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Board resolution
                        {profileData?.nationalDetails?.br?.currentStatus?.id ===
                            FILE_STATUS?.REJECTED?.id && (
                            <RejectionIcon
                                fileName={CORPORATE_FILES?.BOARD_RESOLUTION}
                                handleOpenRejectionModal={handleOpenRejectionModal}
                            />
                        )}
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.nationalDetails?.br?.fileName}
                    </Typography>
                </Grid>
                {/* <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Pan number
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.nationalDetails?.panNumber}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        PAN
                        {profileData?.nationalDetails?.pan?.currentStatus?.id ===
                            FILE_STATUS?.REJECTED?.id && (
                            <RejectionIcon
                                fileName={CORPORATE_FILES?.PAN}
                                handleOpenRejectionModal={handleOpenRejectionModal}
                            />
                        )}
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.nationalDetails?.pan?.fileName}
                    </Typography>
                </Grid> */}
            </Grid>
            <Grid container spacing={5} mb={4}>
                <Grid item container direction={'column'}>
                    <Typography variant="modalLabelBold" sx={{ marginBottom: '10px' }}>
                        Are you a stock broker as well?
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            disabled
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            // name={name}
                            value={profileData?.nationalDetails?.isBroker ? 'Yes' : 'No'}
                            // onChange={handleRadioButton}
                        >
                            {optionsArray &&
                                optionsArray.map((user) => (
                                    <FormControlLabel
                                        className="radiobutton_textfield"
                                        key={user?.label}
                                        value={user?.label}
                                        control={
                                            <Radio
                                                sx={{
                                                  color: palette.text.main,
                                                  '&.Mui-checked': {
                                                    color: palette.success.dark
                                                  }
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="labelSmall"
                                                sx={{ color: palette.text.dark, fontSize: '14px' }}>
                                                {user?.label}
                                            </Typography>
                                        }
                                    />
                                ))}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            {profileData?.nationalDetails?.isBroker && (
                <Grid container spacing={5} mb={3}>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            SEBI
                            {profileData?.nationalDetails?.sebi?.currentStatus?.id ===
                                FILE_STATUS?.REJECTED?.id && (
                                <RejectionIcon
                                    fileName={CORPORATE_FILES?.SEBI}
                                    handleOpenRejectionModal={handleOpenRejectionModal}
                                />
                            )}
                        </Typography>
                        <Typography variant="modalLabel">
                            {profileData?.nationalDetails?.sebi?.fileName}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Root>
                <Divider sx={{ marginBottom: '30px' }} />
            </Root>
            <Grid container mb={3}>
                <Grid item>
                    <Typography variant="modalLabelBold" sx={{ fontWeight: 600, fontSize: '18px' }}>
                        PAN details of company
                        {profileData?.panCardDetails?.status ===
                            PANVERIFICATIONSTATUS?.REJECTED?.id && (
                            <RejectionIcon
                                fileName={CORPORATE_FILES?.PAN}
                                handleOpenRejectionModal={handleOpenRejectionModal}
                            />
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        PAN
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.panCardDetails?.panCardNumber}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Date Of Incorporation
                    </Typography>
                    <Typography variant="modalLabel">
                        {moment(
                          profileData?.panCardDetails?.dateOfIncorporation,
                          'DD-MM-YYYY'
                        ).format('DD/MM/YYYY')}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Status
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.panCardDetails?.status}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        PAN Document
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.panCardDetails?.pan?.fileName}
                    </Typography>
                </Grid>
            </Grid>
            <Root>
                <Divider sx={{ marginBottom: '30px' }} />
            </Root>
            <Grid container mb={3}>
                <Grid item>
                    <Typography variant="modalLabelBold" sx={{ fontWeight: 600, fontSize: '18px' }}>
                        State specific details of company
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        State
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.state?.name}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        City
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.city?.name}</Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Pin code
                    </Typography>
                    <Typography variant="modalLabel">{profileData?.pinCode}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Landline Number
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.companyDetails?.companyLandline}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        GST
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.companyDetails?.gstNumber}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        GST registration
                        {profileData?.companyDetails?.gst?.currentStatus?.id ===
                            FILE_STATUS?.REJECTED?.id && (
                            <RejectionIcon
                                fileName={CORPORATE_FILES?.GST}
                                handleOpenRejectionModal={handleOpenRejectionModal}
                            />
                        )}
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.companyDetails?.gst?.fileName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={4}>
                <Grid item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Company address
                    </Typography>
                    <Typography variant="modalLabel" sx={{ wordBreak: 'break-all' }}>
                        {profileData?.companyDetails?.companyAddress}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={3}>
                <Grid item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Bank account
                    </Typography>
                    {profileData?.bankAccountNumber ? (
                        <Typography variant="modalLabel">
                            {profileData?.bankAccountNumber || 'NA'}
                        </Typography>
                    ) : (
                      profileRestrictionConfiguration?.profile?.isbankAccount && (
                            <Link className="text-link" onClick={() => setOpenBankModal(true)}>
                                Add account
                            </Link>
                      )
                    )}
                </Grid>
            </Grid>
            <Root>
                <Divider sx={{ marginBottom: '30px' }} />
            </Root>
            <Grid container mb={3}>
                <Grid item>
                    <Typography variant="modalLabelBold" sx={{ fontWeight: 600, fontSize: '18px' }}>
                        Bulk Document details
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Preffered party
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.bulkDetailsResponse?.partyType?.name}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Name
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.bulkDetailsResponse?.name}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Email address
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.bulkDetailsResponse?.email}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Phone
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.bulkDetailsResponse?.phone}
                    </Typography>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant="darkValue" component="div" sx={{ marginBottom: '8px' }}>
                        Address
                    </Typography>
                    <Typography variant="modalLabel">
                        {profileData?.bulkDetailsResponse?.address}
                    </Typography>
                </Grid>
            </Grid>
            {profileRestrictionConfiguration?.profile?.isEditable && (
                <Grid>
                    <Button variant="primary" onClick={handleEdit}>
                        Edit
                    </Button>
                </Grid>
            )}
            {openBankModal && (
                <BankAccountModal
                    bankAccountDetails={bankAccountDetails}
                    setBankAccountDetails={setBankAccountDetails}
                    bankAccountModalState={bankAccountModalState}
                    openBankModal={openBankModal}
                    handleBankModalClose={() => handleBankAccountModalClose()}
                    handleSubmitBankModal={handleSubmitBankModal}
                />
            )}

            {rejectionNotesModal && (
                <NotesModal
                    notesModalState={rejectionNotesModal}
                    handleNotesModalClose={() => {
                      setRejectionNotesModal({
                        open: false,
                        message: ''
                      })
                    }}
                />
            )}

            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default MyProfileCorporate
